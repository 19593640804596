import { Box, Card, CardContent, CircularProgress, Grid, Modal, Typography } from '@mui/joy';
import { easeQuadInOut } from 'd3-ease';
import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CountUp from 'react-countup';
import { getAllMemberTaskTimeData, getAllTeamwiseData, getOverallAttendance, getOverallTaskCount, getPendingRatingTaskList, logout } from '../../service/service-call';
import AnimatedProgressProvider from '../Charts/AnimatedProgressProvider';
import BarChartStacked from '../Charts/BarChartStacked';
import PieChartForAttendance from '../Charts/PieChart';
import { PendingRatingTasks } from './pendingRatingTasks';
import { Warning } from '@mui/icons-material';

export default function Home() {
  const [pendingRatingTasks, setPendingRatingTasks] = React.useState([]);
  const [isPendingRatingTasksOpen, setIsPendingRatingTasksOpen] = React.useState(false);
  const [overAllCount, setOverallCount] = React.useState("");
  const [overallAttendance, setoverallAttendance] = React.useState([]);
  const [memberwiseData, setMemberwiseData] = React.useState([]);
  const [teamwiseData, setTeamwiseData] = React.useState([]);

  function togglePendingRatingTasksModal() {
    setIsPendingRatingTasksOpen(!isPendingRatingTasksOpen);
  }

  React.useEffect(() => {
    getPendingRatingTasks();
    getAllOveralltaskCount();
    getAllMemberwise();
    if (localStorage.getItem("role") === "ROLE_SUPER_ADMIN" || localStorage.getItem("role") === "ROLE_ADMIN") {
      getOverallAttendanceData();
    }

  }, []);

  function getPendingRatingTasks() {
    getPendingRatingTaskList().then(resp => {
      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        setPendingRatingTasks(data);
        if(data && data.length > 0 ){
          togglePendingRatingTasksModal();
        }
      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  function getOverallAttendanceData() {
    getOverallAttendance().then(resp => {
      if (resp.status === 401) {
        logout();
      }

      resp.json().then(data => {
        let respdata = [];
        if (data && data.length > 0) {
          data.map(e => {
            let attendance = {
              title: '',
              count: '',
              list: '',
            }
            let abs = "";
            if (e.absentMembers && e.absentMembers.length > 0) {
              e.absentMembers.map(c => abs = abs + c + "<br>")
            }

            attendance.title = "Absent members";
            attendance.count = e.absentMembersCount;
            attendance.all = e.overallMemberCount;
            attendance.list = abs;


            attendance.date = e.date;
            respdata.push(attendance);

            let attendancePresent = {
              title: '',
              count: '',
              list: '',
            }

            let pres = "";
            if (e.presentMembers && e.presentMembers.length > 0) {
              e.presentMembers.map(c => pres = pres + c + "<br>")
            }

            attendancePresent.title = "Present members";
            attendancePresent.count = e.presentMembersCount;
            attendancePresent.list = pres;
            attendancePresent.date = e.date;
            attendancePresent.all = e.overallMemberCount;
            respdata.push(attendancePresent);
          })
        }
        setoverallAttendance(respdata);
      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  function getAllOveralltaskCount() {
    getOverallTaskCount().then(resp => {
      if (resp.status === 401) {
        logout();
      }

      resp.json().then(data => {

        setOverallCount(data);
      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  function getAllMemberwise() {
    getAllMemberTaskTimeData().then(resp => {
      if (resp.status === 401) {
        logout();
      }

      resp.json().then(data => {

        let respdata = [];
        if (data && data.length > 0) {
          data.map(e => {
            let memberwiseBarChartData = {
              member: '',
              inprogressEstimatedTime: '',
              actualInprogressTime: '',
              qcEstimatedTime: '',
              actualQcTime: '',
              inprogressEfficiencyPercentage: '',
              qcEfficiencyPercentage: '',
              inprogressReworkCount: ''
            }

            memberwiseBarChartData.member = e.name + " (" + e.memberId + ")";
            memberwiseBarChartData.inprogressEstimatedTime = convertSecondsToHoursAndMinutes(e.inprogressEstimatedTime);
            memberwiseBarChartData.actualInprogressTime = convertSecondsToHoursAndMinutes(e.actualInprogressTime);
            memberwiseBarChartData.qcEstimatedTime = convertSecondsToHoursAndMinutes(e.qcEstimatedTime);
            memberwiseBarChartData.actualQcTime = convertSecondsToHoursAndMinutes(e.actualQcTime);
            memberwiseBarChartData.inprogressEfficiencyPercentage = e.inprogressEfficiencyPercentage;
            memberwiseBarChartData.qcEfficiencyPercentage = e.qcEfficiencyPercentage;
            memberwiseBarChartData.inprogressReworkCount = e.inprogressReworkCount;
            respdata.push(memberwiseBarChartData);

          })
        }
        setMemberwiseData(respdata);
      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }
  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return Number(`${hours}.${remainingMinutes}`);
  }
  function convertSecondsToHoursAndMinutes(seconds) {
    const totalMinutes = Math.floor(seconds / 60);
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    return Number(`${hours}.${remainingMinutes}`);
  }
  function getAllTeamwise() {
    getAllTeamwiseData().then(resp => {
      if (resp.status === 401) {
        logout();
      }

      resp.json().then(data => {

        let eachTeam = [];
        if (data && data.length > 0) {
          data.map(t => {
            let teamName = "";

            teamName = t.teamName;
            let memberArr = [];
            t.members.map(e => {
              let memberwiseBarChartData = {
                member: '',
                completionPercentage: '',
                todoPercentage: '',
                inprogressPercentage: '',
                qaPercentage: '',
                donePercentage: ''
              }
              memberwiseBarChartData.member = e.member.firstName + " " + e.member.lastName
              memberwiseBarChartData.completionPercentage = e.taskCounts.completionPercentage;
              memberwiseBarChartData.todoPercentage = e.taskCounts.todoPercentage;
              memberwiseBarChartData.inprogressPercentage = e.taskCounts.inprogressPercentage;
              memberwiseBarChartData.qaPercentage = e.taskCounts.qaPercentage;
              memberwiseBarChartData.donePercentage = e.taskCounts.donePercentage;
              memberArr.push(memberwiseBarChartData);
            });
            eachTeam.push({
              teamName: teamName,
              members: memberArr
            })

          })
        }
        setTeamwiseData(eachTeam);
      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }
  return (
    <>
    <div >
      {
        overAllCount ? <Typography level='body-sx' fontWeight={"600"} sx={{marginBottom:'-15px', opacity:'0.5', fontSize:'0.8rem'}}>For Last 10 days</Typography>: ""
      }
      <Grid container spacing={1} direction={"row"} marginTop={"20px"}>
      
        {overAllCount ? (
          <>
        
          <Grid item md={3}>
            <Card data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" sx={{ padding: '20px', backgroundColor:'white',"&:hover": { color: '#262673', boxShadow:'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px' } }} variant='soft'>
              <CardContent orientation="horizontal">


                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={parseFloat(overAllCount.tasksInTodoPercentage)}
                  duration={1}
                  easingFunction={easeQuadInOut}
                >
                  {value => {
                    const roundedValue = Math.round(value);
                    return (
                      <div style={{ width: 95 }}>
                        <CircularProgressbar
                          value={value}
                          text={`${roundedValue}%`}
                          strokeWidth={"12"}
                          styles={{
                            root: {
                              height: '80px',
                            },
                            path: {
                              // Path color
                              stroke: `#0B6BCB`,
                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                              strokeLinecap: 'round',
                              // Customize transition animation
                              transition: 'stroke-dashoffset 0.5s ease 0s',
                              // Rotate the path
                              //transform: 'rotate(0.25turn)',
                              transformOrigin: 'center center',
                            },
                            trail: {
                              // Trail color
                              stroke: '#F0F4F8',
                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                              // strokeLinecap: 'butt',
                              // // Rotate the trail
                              // transform: 'rotate(0.25turn)',
                              transformOrigin: 'center center',
                            },
                          }}
                        />
                      </div>
                    );
                  }}
                </AnimatedProgressProvider>




                <div>
                  <CardContent orientation="vertical" sx={{ textAlign: 'center' }} >
                    <Typography level='title-sm' color='primary' fontWeight={"700"}>{"TODO TASKS"}</Typography>
                    <Typography level='h1' fontWeight={"700"}>
                      <CountUp
                        start={1}
                        end={(overAllCount.tasksInTodo)}
                        duration={1}
                        separator=" "
                      ></CountUp>
                    </Typography>
                  </CardContent>
                </div>
              </CardContent>


            </Card>
          </Grid>
          <Grid item md={3}>
            <Card data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" sx={{ padding: '20px', backgroundColor:'white',"&:hover": { color: '#262673', boxShadow:'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px' } }} variant='soft'>
              <CardContent orientation="horizontal">


                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={parseFloat(overAllCount.tasksInProgressPercentage).toFixed(1)}
                  duration={1}
                  easingFunction={easeQuadInOut}
                >
                  {value => {
                    const roundedValue = Math.round(value);
                    return (
                      <div style={{ width: 95 }}>
                        <CircularProgressbar
                          value={value}
                          text={`${roundedValue}%`}
                          strokeWidth={"12"}

                          styles={{
                            root: {
                              height: '80px',
                            },
                            path: {
                              stroke: `#0B6BCB`,
                              strokeLinecap: 'round',
                              //transition: 'stroke-dashoffset 0.5s ease 0s',
                            },
                            trail: {
                              stroke: '#F0F4F8',
                            },
                          }}
                        />
                      </div>
                    );
                  }}
                </AnimatedProgressProvider>
                <div>
                  <CardContent orientation="vertical" sx={{ textAlign: 'center' }}>
                    <Typography level='title-sm' color='primary' fontWeight={"700"}>{"INPROGRESS"}</Typography>
                    <Typography level='h1' fontWeight={"700"}>
                      <CountUp
                        start={1}
                        end={(overAllCount.tasksInProgress)}
                        duration={1.5}
                      ></CountUp>
                    </Typography>
                  </CardContent>
                </div>
              </CardContent>


            </Card>
          </Grid>
          <Grid item md={3}>

            <Card data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" sx={{ padding: '20px', backgroundColor:'white',"&:hover": { color: '#262673', boxShadow:'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px' } }} variant='soft'>
              <CardContent orientation="horizontal">


                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={parseFloat(overAllCount.tasksInQAPercentage).toFixed(1)}
                  duration={1}
                  easingFunction={easeQuadInOut}
                >
                  {value => {
                    const roundedValue = Math.round(value);
                    return (
                      <div style={{ width: 95 }}>
                        <CircularProgressbar
                          value={value}
                          text={`${roundedValue}%`}
                          strokeWidth={"12"}
                          styles={{
                            root: {
                              height: '80px',
                            },
                            path: {
                              stroke: `#0B6BCB`,
                              strokeLinecap: 'round',
                              transition: 'stroke-dashoffset 0.5s ease 0s',
                              transformOrigin: 'center center',
                            },
                            trail: {
                              stroke: '#F0F4F8',
                              transformOrigin: 'center center',
                            },
                          }}
                        />
                      </div>
                    );
                  }}
                </AnimatedProgressProvider>

                <div>
                  <CardContent orientation="vertical" sx={{ textAlign: 'center' }}>
                    <Typography level='title-sm' color='primary' fontWeight={"700"}>{"QA TASKS"}</Typography>
                    <Typography level='h1' fontWeight={"700"}><CountUp
                      start={1}
                      end={(overAllCount.tasksInQA)}
                      duration={1.5}
                    ></CountUp></Typography>
                  </CardContent>
                </div>
              </CardContent>


            </Card>

          </Grid>
          <Grid item md={3}>
            <Card data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" sx={{ padding: '20px', backgroundColor:'white', "&:hover": { color: '#262673', boxShadow:'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px' } }} size='lg' variant='soft'>
              <CardContent orientation="horizontal">

                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={parseFloat(overAllCount.tasksInDonePercentage).toFixed(1)}
                  duration={1}
                  easingFunction={easeQuadInOut}
                >
                  {value => {
                    const roundedValue = Math.round(value);
                    return (
                      <div style={{ width: 95 }}>
                        <CircularProgressbar
                          value={value}
                          text={`${roundedValue}%`}
                          strokeWidth={"12"}
                          styles={{
                            root: {
                              height: '80px',
                            },
                            path: {
                              stroke: `#0B6BCB`,
                              strokeLinecap: 'round',
                              transition: 'stroke-dashoffset 0.5s ease 0s',
                              transformOrigin: 'center center',
                            },
                            trail: {
                              stroke: '#F0F4F8',
                              transformOrigin: 'center center',
                            },
                          }}
                        />
                      </div>
                    );
                  }}
                </AnimatedProgressProvider>

                <div>
                  <CardContent orientation="vertical" sx={{ textAlign: 'center' }}>
                    <Typography level='title-sm' color='primary' fontWeight={"700"}>{"DONE TASKS"}</Typography>
                    <Typography level='h1' fontWeight={"700"}>
                      <CountUp
                        start={1}
                        end={(overAllCount.tasksInDone)}
                        duration={1.5}
                      ></CountUp>
                    </Typography>
                  </CardContent>
                </div>

              </CardContent>


            </Card>
          </Grid>
        </>
        ) :
        <Grid item md={12}>
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
          <CircularProgress />
          </div>
          </Grid>
        }
        
      </Grid>
        <Grid container >
        <Grid item md={8}>
          {
            memberwiseData && memberwiseData.length > 0 ?
              (
                <BarChartStacked dbData={memberwiseData} title={"Employeewise Task Report"} subTitle={"For Last 10 days"} height={1000} />
              ) : ""
          }

          {/* {
              teamwiseData && teamwiseData.length > 0 ? 
              (
                teamwiseData.map( e => (
                  <BarChartStacked dbData={e.members} title={e.teamName}  subTitle={"For Last 10 days"} height={500}/>
                ))
                  
              ):""
            } */}

        </Grid>
        <Grid item md={4}>
          {
            overallAttendance && overallAttendance.length > 0 ?
              (
                <PieChartForAttendance dbData={overallAttendance} />
              ) : ""
          }

        </Grid>
        </Grid>

        
    </div>

    <Modal open={isPendingRatingTasksOpen} onClose={togglePendingRatingTasksModal}>
        <Box style={{ width: "1100px", margin: 'auto', outline: '1px solid rgba(0, 0, 0, 0.1)', marginTop: '50px', padding: '20px', backgroundColor: 'white', overflowY:'auto', height:'500px', borderRadius:'4px', border: 'none !important'}}>
          <Typography fontWeight={"700"} color='danger' startDecorator={<Warning/>}>PENDING RATING TASKS</Typography>
        <PendingRatingTasks togglePendingRatingTasksModal={togglePendingRatingTasksModal} data={pendingRatingTasks} />
      </Box>
      </Modal>
    </>
  )
}
