import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DialogActions, DialogContent } from '@mui/material';
import moment from 'moment';
import { Button } from '@mui/joy';


export default function AttendanceDetails({data, toggleViewTaskModal}) {
    
  return (
    <>
    <DialogContent>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{backgroundColor:'#262673', color:'white'}}>
            <TableCell style={{color:'white'}} align="center">LOGIN TIME</TableCell>
            <TableCell style={{color:'white'}} align="center">LOGOUT TIME</TableCell>
            <TableCell style={{color:'white'}} align="center">IP ADDRESS</TableCell>
            <TableCell style={{color:'white'}} align="center">OS</TableCell>
            <TableCell style={{color:'white'}} align="center">BROWSER</TableCell>
            <TableCell style={{color:'white'}} align="center">DEVICE ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.length > 0 && data.map((row, ind) => (
           <TableRow
              key={row.logBookDetailsId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" component="th" scope="row">
                {row.logInTime ? moment(row.logInTime).format("DD-MM-YYYY hh:mm a"):""}
              </TableCell>
              <TableCell align="center">
                {row.logOutTime ? moment(row.logOutTime).format("DD-MM-YYYY hh:mm a"):""}
                </TableCell>
              <TableCell align="center">{row.ipAddress}</TableCell>
              <TableCell align="center">{row.osName}</TableCell>
              <TableCell align="center">{row.browserDetails}</TableCell>
              <TableCell align="center">{row.deviceId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </DialogContent>
    <DialogActions>
    <Button onClick={toggleViewTaskModal}>&nbsp;CLOSE</Button>
</DialogActions>
</>
  );
}