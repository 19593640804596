import React from "react";
import { convertSecondsToHours, getAllTasksForTableFull, getAllTasksForTableView, logout, stringAvatar } from "../../service/service-call";
import MUIDataTable from "mui-datatables";
import { Avatar, Badge, Box, Button, Chip, ListItemDecorator, Typography } from "@mui/joy";
import { keyframes } from '@mui/system';
import moment from "moment";
import styled from "@emotion/styled";
import { Checkbox, Dialog, ListItemText, Rating, Select, TextField, ThemeProvider } from "@mui/material";
import Popup from "../styles/Popup";
import { createTheme } from '@mui/system';
import { TbLayoutKanban } from "react-icons/tb";
import { InputLabel } from "@mui/material";
import { MenuItem } from "@mui/material";
import { FormControl } from "@mui/material";
import { IconButton } from "@mui/material";
import { BsKanban } from "react-icons/bs";
import SearchIcon from '@mui/icons-material/YoutubeSearchedFor';
import PrintIcon from '@mui/icons-material/LocalPrintshop';
import DownloadIcon from '@mui/icons-material/DownloadForOffline';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FilterIcon from '@mui/icons-material/Tune';
function TableView({showTableButton}) {
  const [allTasks, setAllTasks] = React.useState([]);
  const [selectedId, setSelectedId] =  React.useState("");
  const [open, setOpen] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchText, setSearchText] = React.useState('');

  React.useEffect(() => {
    getAllTasksForUser(page, rowsPerPage, searchText);
    getAllTasks();
  }, []);
    
  function getAllTasksForUser(currentPage, currentRowsPerPage, currentSearchText){
    getAllTasksForTableView(currentPage, currentRowsPerPage, currentSearchText).then(resp => {
      if(resp.status === 401){
        logout();
    }
      resp.json().then(data => {
        setAllTasks(data.tasks);
        setTotalCount(data.totalCount);
      });
    }).catch(error => {
      console.log("Your session expired, Please login again", error);
    });
  }

  function getAllTasks(){
    getAllTasksForTableFull().then(resp => {
      if(resp.status === 401){
        logout();
    }
      resp.json().then(data => {
        setAllTasks(data);
      });
    }).catch(error => {
      console.log("Your session expired, Please login again", error);
    });
  }
  const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
function toggleViewTaskModal() {
    setOpen(!open);
    getAllTasks();
  }
function toHoursAndMinutes(totalMinutes) {
    var sign = totalMinutes < 0 ? "-" : "";
    totalMinutes = Math.abs(totalMinutes); // Make totalMinutes positive for calculation
    var hours = Math.floor(totalMinutes / 60);
    var minutes = totalMinutes % 60;
    return  hours + ":" + (minutes < 10 ? "0" : "") + minutes + " hrs";
    //return `${hours+":"}${remainingMinutes+" hrs" }`;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      minWidth: '1100px !important',
      height: 'auto'
    },
  }));

  const theme = createTheme({
    typography: {
      fontSize: 50,
      body1: {
        fontWeight: 500
      }
    }
  });
  const columns = [
        {
            name: "id",
            label: "Task Id",
            options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta, updateDirection) => (
                <th style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672', maxHeight:'40px', minHeight:'40px'}}>
                    Task Id
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'center'
                    }
                }
            },
            customBodyRender: (value, tableMeta, updateValue) => (
                <Chip  
                size="sm"
                variant="solid"
                sx={{fontSize:'0.6rem', borderRadius:'5px', backgroundColor:'#262673', color:'white'}}
                
                >{value}</Chip>
              )
            }
        },
        {
            name: "title",
            label: "Title",
            options: {
                customHeadRender: (columnMeta, updateDirection) => (
                    <th  style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672'}}>
                        Title
                    </th>
                ),
                customBodyRender: (value, tableMeta, updateValue) => (
                
                    value ?
                    
                    <Typography fontWeight="md" class="font-bold" level="body-xs" sx={{fontSize:'0.7rem'}}>
                      &nbsp;{value}
                    </Typography> : ""
                    
                
               
              ),
            filter: true,
            sort: true,
            }
        },
        {
            name: "shortDescription",
            label: "Description",
            options: {
                display: false,
            filter: true,
            sort: true,
            customHeadRender: (columnMeta, updateDirection) => (
                <th  style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672'}}>
                    Description
                </th>
            ),
            customBodyRender: (value, tableMeta, updateValue) => (
                
                value ?
                
                <Typography fontWeight="md" class="font-bold" level="body-xs" sx={{fontSize:'0.7rem'}}>
                  &nbsp;{value}
                </Typography> : ""
                
            
           
          ),
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta, updateDirection) => (
                <th  style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672'}}>
                    Status
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'center'
                    }
                }
            },
            customBodyRender: (value, tableMeta, updateValue) => (
                <Chip  
                size="sm"
                sx={{fontSize:'0.6rem', borderRadius:'5px', 
                backgroundColor:(value==="DONE") ? 'green' : value ==="QA" ? "orange" : value ==="INPROGRESS" ? "chocolate" : value ==="TODO" ? "lightcoral" : "grey", 
                color:'white'}}
                
                >{value}</Chip>
              )
            }
        },
        {
            name: "complexity",
            label: "Complexity",
            options: {
                display: false,
                customHeadRender: (columnMeta, updateDirection) => (
                    <th  style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672'}}>
                        Complexity
                    </th>
                ),
            filter: true,
            sort: true,
            }
        },
        {
            name: "priority",
            label: "Priority",
            options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta, updateDirection) => (
                <th  style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672'}}>
                    Priority
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'center'
                    }
                }
            },
            customBodyRender: (value, tableMeta, updateValue) => (
                value ? 
                <Chip  
                size="sm"
                              variant={(value==="LOW")? "soft":(value==="MEDIUM")?"soft":"solid"}
                              sx={{fontSize:'0.6rem', borderRadius:'2px', animation: (value==="HIGH")?`${blink} 0.5s linear infinite`:""}}
                              color={(value==="LOW")? "neutral":(value==="MEDIUM")?"primary":"warning"}
                            >{value}</Chip>
                            :"-"
              )
            }
        },
        {
            name: "assignedBy",
            label: "Assigned By",
            options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta, updateDirection) => (
                <th  style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672'}}>
                    Assigned By
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'left'
                    }
                }
            },
            filterType: 'custom',
                customFilterListOptions: {
                    render: v => v.map(l =>  `Assigned By: `+l.toUpperCase()),
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    }
                },
                filterOptions: {
                    logic: (name, filters, row) => {
                        if (filters.length) {
                            // Iterate over each filter
                            for (let i = 0; i < filters.length; i++) {
                                const filterLower = filters[i].toLowerCase();
                                
                                if(name==="All"){
                                    return false;
                                }
                                if (name && name !== "-") {
                                    const firstName = name.firstName.toLowerCase();
                                    const lastName = name.lastName.toLowerCase();
                                    
                                    // Check if either first name or last name matches the filter
                                    if ((filterLower.includes(firstName) && filterLower.includes(lastName))) {
                                        // If any filter doesn't match, return false to hide the row
                                        return false;
                                    } 
                                } else {
                                    // If assignedTo is null, return true to hide the row
                                    return true;
                                }
                            }
                            // If all filters match, return true to display the row
                            return true;
                        }
                        // If no filter is applied, do not filter the row
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const uniqueNames = [...new Set(allTasks.filter(e => e.assignedBy ).map(row => `${row.assignedBy.firstName} ${row.assignedBy.lastName}`))];
                   
                        
                        return (
                            <FormControl>
            <InputLabel htmlFor='select-multiple-chip'>
                Assigned By
            </InputLabel>
            <Select
                multiple
                value={filterList[index]}
                variant="standard"
                renderValue={selected => selected.join(', ')}
                label={"Assigned By"}
                onChange={event => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                }}
            >
                <MenuItem value="All">All</MenuItem>
                {uniqueNames.map(name => (
                    <MenuItem key={name} value={name}>{name}</MenuItem>
                ))}
            </Select>
        </FormControl>
                        );
                    }
                },
                customBodyRender: (value, tableMeta, updateValue) => (
                
                    value && value.id ?
                    <ListItemDecorator>
                    <Avatar size="sm" {...stringAvatar(value.firstName+" "+value.lastName)}></Avatar>
                   
                    <Typography fontWeight="md" class="font-bold" level="body-xs" sx={{fontSize:'0.7rem'}}>
                      &nbsp;{value.firstName+" "+value.lastName}
                    </Typography>
                    </ListItemDecorator>
                    :"-"
                
               
              )
            }
        },
        {
            name: "assignedTo",
            label: "Assigned To",
            options: {
                filter: true,
                sort: true,
                filterType: 'custom',
                customHeadRender: (columnMeta, updateDirection) => (
                    <th  style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'40px', color:'#262672'}}>
                        Assigned To
                    </th>
                ),
                setCellProps: value => {
                    return {
                        title: value,
                        style: {
                            textAlign:'left'
                        }
                    }
                },
                customFilterListOptions: {
                    render: v => v.map(l =>  `PROD: `+l.toUpperCase()),
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    }
                },
                filterOptions: {
                    logic: (name, filters, row) => {
                        if (filters.length) {
                            // Iterate over each filter
                            for (let i = 0; i < filters.length; i++) {
                                const filterLower = filters[i].toLowerCase();
                                
                                if(name==="All"){
                                    return false;
                                }
                                if (name && name !== "-") {
                                    const firstName = name.firstName.toLowerCase();
                                    const lastName = name.lastName.toLowerCase();
                                    
                                    // Check if either first name or last name matches the filter
                                    if ((filterLower.includes(firstName) && filterLower.includes(lastName))) {
                                        // If any filter doesn't match, return false to hide the row
                                        return false;
                                    } 
                                } else {
                                    // If assignedTo is null, return true to hide the row
                                    return true;
                                }
                            }
                            // If all filters match, return true to display the row
                            return true;
                        }
                        // If no filter is applied, do not filter the row
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const uniqueNames = [...new Set(allTasks.filter(e => e.assignedTo ).map(row => `${row.assignedTo.firstName} ${row.assignedTo.lastName}`))];
                   
                        
                        return (
                            <FormControl>
            <InputLabel htmlFor='select-multiple-chip'>
                Assigned To
            </InputLabel>
            <Select
                multiple
                value={filterList[index]}
                variant="standard"
                renderValue={selected => selected.join(', ')}
                label={"Assigned To"}
                onChange={event => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                }}
            >
                <MenuItem value="All">All</MenuItem>
                {uniqueNames.map(name => (
                    <MenuItem key={name} value={name}>{name}</MenuItem>
                ))}
            </Select>
        </FormControl>
                        );
                    }
                },
                customBodyRender: (value, tableMeta, updateValue) => (
                
                    value && value.id ?
                    <ListItemDecorator>
                    <Avatar size="sm" {...stringAvatar(value.firstName+" "+value.lastName)}></Avatar>
                   
                    <Typography fontWeight="md" class="font-bold" level="body-xs" sx={{fontSize:'0.7rem'}}>
                      &nbsp;{value.firstName+" "+value.lastName}
                    </Typography>
                    </ListItemDecorator>
                    :"-"
                
               
              )
            }
        },
        {
            name: "qcAssignedTo",
            label: "QC Assigned To",
            options: {
                filter: true,
                sort: true,
                filterType: 'custom',
                customHeadRender: (columnMeta, updateDirection) => (
                    <th  style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672'}}>
                        QC Assigned To
                    </th>
                ),
                setCellProps: value => {
                    return {
                        title: value,
                        style: {
                            textAlign:'left'
                        }
                    }
                },
                customFilterListOptions: {
                    render: v => v.map(l => `QC: `+l.toUpperCase()),
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    }
                },
                filterOptions: {
                    logic: (name, filters, row) => {
                        if (filters.length) {
                            // Iterate over each filter
                            for (let i = 0; i < filters.length; i++) {
                                const filterLower = filters[i].toLowerCase();
                                if(name==="All"){
                                    return true;
                                }
                                if (name && name !== "-") {
                                    const firstName = name.firstName.toLowerCase();
                                    const lastName = name.lastName.toLowerCase();
                                    // Check if either first name or last name matches the filter
                                    if ((filterLower.includes(firstName) && filterLower.includes(lastName))) {
                                        // If any filter doesn't match, return false to hide the row
                                        return false;
                                    }
                                } else {
                                    // If qcAssignedTo is null, return true to hide the row
                                    return true;
                                }
                            }
                            // If all filters match, return true to display the row
                            return true;
                        }
                        // If no filter is applied, do not filter the row
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const uniqueNames = [...new Set(allTasks.filter(e => e.qcAssignedTo ).map(row => `${row.qcAssignedTo.firstName} ${row.qcAssignedTo.lastName}`))];
                   
                        
                        return (
                            <FormControl>
            <InputLabel htmlFor='select-multiple-chip'>
                QC Assigned To
            </InputLabel>
            <Select
                multiple
                value={filterList[index]}
                variant="standard"
                renderValue={selected => selected.join(', ')}
                label={"QC Assigned To"}
                
                onChange={event => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                }}
            >
                <MenuItem value="All">All</MenuItem>
                {uniqueNames.map(name => (
                    <MenuItem key={name} value={name}>{name}</MenuItem>
                ))}
            </Select>
        </FormControl>
                        );
                    }
                },
                customBodyRender: (value, tableMeta, updateValue) => (
                
                    value && value.id ?
                    <ListItemDecorator>
                    <Avatar size="sm" {...stringAvatar(value.firstName+" "+value.lastName)}></Avatar>
                   
                    <Typography fontWeight="md" class="font-bold" level="body-xs" sx={{fontSize:'0.7rem'}}>
                      &nbsp;{value.firstName+" "+value.lastName}
                    </Typography>
                    </ListItemDecorator>
                    :"-"
                
               
              ),
            }
        },
        {
            name: "startDate",
            label: "Time Status",
            options: {
            filter: false,
            sort: true,
            customHeadRender: (columnMeta, updateDirection) => (
                <th style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672'}}>
                    {"Time Status"}
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'center'
                    }
                }
            },
            customBodyRenderLite: (ind) => (
                allTasks[ind].endDate && allTasks[ind].status !=="QA" && allTasks[ind].status !=="DONE" ? 
                <Chip
                size="sm"
                variant="outlined"
                sx={{fontSize:'0.6rem', borderRadius:'5px'}}
                color={"success"}   
              >{toHoursAndMinutes(moment(allTasks[ind].endDate).diff(moment(), 'minutes'))} {moment(allTasks[ind].endDate).diff(moment(), 'minutes') < 0 ? "elapsed":"remaining"}</Chip>
                : allTasks[ind].qcEndDate && allTasks[ind].status ==="QA" ? 
                <Chip
                size="sm"
                variant="outlined"
                sx={{fontSize:'0.6rem', borderRadius:'5px'}}
                color={"success"}  
              > {toHoursAndMinutes(moment(allTasks[ind].qcEndDate).diff(moment(), 'minutes'))} {moment(allTasks[ind].qcEndDate).diff(moment(), 'minutes') < 0 ? "elapsed":"remaining"}</Chip>
                :allTasks[ind].modifiedDate && allTasks[ind].status ==="DONE" ? 
                <Chip
                size="sm"
                variant="outlined"
                sx={{fontSize:'0.6rem', borderRadius:'5px'}}
                color={"success"}   
              > {moment(allTasks[ind].modifiedDate).format("DD-MM-YYYY hh:mm A")}</Chip>
                :""
            )
            }
        },
        
        // {
        //     name: "endDate",
        //     label: "EndDate",
        //     options: {
        //     filter: true,
        //     sort: true,
        //     }
        // },
       
        // {
        //     name: "qcActualStartDate",
        //     label: "QC Actual start Date",
        //     options: {
        //     filter: true,
        //     sort: true,
        //     }
        // },
        // {
        //     name: "qcActualCompletedDate",
        //     label: "QC Actual completed Date",
        //     options: {
        //     filter: true,
        //     sort: true,
        //     }
        // },

        {
            name: "inProgressEstimatedTime",
            label: "Prod Est. Time",
            options: {
            filter: false,
            display: true,
            sort: true,
            customHeadRender: (columnMeta, updateDirection) => (
                <th style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672', maxHeight:'40px', minHeight:'40px'}}>
                    Prod Est. Time
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'center'
                    }
                }
            },
            customBodyRender: (value, tableMeta, updateValue) => (
                <Chip  
                size="sm"
                variant="outlined"
                sx={{fontSize:'0.6rem', borderRadius:'5px', color:'#262673'}}
                
                >{convertSecondsToHours(value)}</Chip>
              )
            }
        },

        {
            name: "actualInProgressTime",
            label: "Prod Act. Time",
            options: {
            filter: false,
            display: true,
            sort: true,
            customHeadRender: (columnMeta, updateDirection) => (
                <th style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672', maxHeight:'40px', minHeight:'40px'}}>
                    Prod Act. Time
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'center'
                    }
                }
            },
            customBodyRender: (value, tableMeta, updateValue) => (
                <Chip  
                size="sm"
                variant="outlined"
                sx={{fontSize:'0.6rem', borderRadius:'5px', color:'#262673'}}
                
                >{convertSecondsToHours(value)}</Chip>
              )
            }
        },

        {
            name: "qcEstimatedTime",
            label: "QC Est. Time",
            options: {
            filter: false,
            display: true,
            sort: true,
            customHeadRender: (columnMeta, updateDirection) => (
                <th style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672', maxHeight:'40px', minHeight:'40px'}}>
                    QC Est. Time
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'center'
                    }
                }
            },
            customBodyRender: (value, tableMeta, updateValue) => (
                <Chip  
                size="sm"
                variant="outlined"
                sx={{fontSize:'0.6rem', borderRadius:'5px', color:'#262673'}}
                
                >{convertSecondsToHours(value)}</Chip>
              )
            }
        },

        {
            name: "actualQcTime",
            label: "QC Act. Time",
            options: {
            filter: false,
            sort: true,
            display: true,
            customHeadRender: (columnMeta, updateDirection) => (
                <th style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672', maxHeight:'40px', minHeight:'40px'}}>
                    QC Act. Time
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'center'
                    }
                }
            },
            customBodyRender: (value, tableMeta, updateValue) => (
                <Chip  
                size="sm"
                variant="outlined"
                sx={{fontSize:'0.6rem', borderRadius:'5px', color:'#262673'}}
                
                >{convertSecondsToHours(value)}</Chip>
              )
            }
        },

        {
            name: "reworkCount",
            label: "Rework Count",
            options: {
            filter: false,
            sort: true,
            display: true,
            customHeadRender: (columnMeta, updateDirection) => (
                <th style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672', maxHeight:'40px', minHeight:'40px'}}>
                    Rework Count
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'center'
                    }
                }
            },
            customBodyRender: (value, tableMeta, updateValue) => (
                <Chip size="sm" variant="outlined"
                sx={{fontSize:'0.6rem', borderRadius:'5px', backgroundColor: value > 0 ? 'red':'', color: value > 0 ? 'white':'#262673'}}
                >{value}</Chip>
              )
            }
        },

        {
            name: "assignedToRating",
            label: "Prod Rating",
            options: {
            filter: false,
            sort: true,
            display: true,
            customHeadRender: (columnMeta, updateDirection) => (
                <th style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672', maxHeight:'40px', minHeight:'40px'}}>
                    Prod Rating
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'center'
                    }
                }
            },
            customBodyRender: (value, tableMeta, updateValue) => (
                <Rating size="small" value={value}></Rating>
              )
            }
        },

        {
            name: "qcAssignedToRating",
            label: "QC Rating",
            options: {
            filter: false,
            sort: true,
            display: true,
            customHeadRender: (columnMeta, updateDirection) => (
                <th style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672', maxHeight:'40px', minHeight:'40px'}}>
                    QC Rating
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'center'
                    }
                }
            },
            customBodyRender: (value, tableMeta, updateValue) => (
                <Rating size="small" value={value}></Rating>
              )
            }
        },
        {
            name: "qcPersonFeedBack",
            label: "Prod feedback from QC",
            options: {
            filter: false,
            display: false,
            sort: true,
            customHeadRender: (columnMeta, updateDirection) => (
                <th style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672', maxHeight:'40px', minHeight:'40px'}}>
                    Prod feedback from QC
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'center'
                    }
                }
            },
            customBodyRender: (value, tableMeta, updateValue) => (
                <Typography level="body-xs">{value}</Typography>
              )
            }
        },
        {
            name: "assignedToRatingFeedBack",
            label: "Prod Feedback",
            options: {
            filter: false,
            sort: true,
            display: false,
            customHeadRender: (columnMeta, updateDirection) => (
                <th style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672', maxHeight:'40px', minHeight:'40px'}}>
                    Prod Feedback
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'center'
                    }
                }
            },
            customBodyRender: (value, tableMeta, updateValue) => (
                <Typography level="body-xs">{value}</Typography>
              )
            }
        },

        {
            name: "qcAssignedToRatingFeedBack",
            label: "QC Feedback",
            options: {
            filter: false,
            sort: true,
            display: false,
            customHeadRender: (columnMeta, updateDirection) => (
                <th style={{cursor: 'pointer', backgroundColor:'aliceblue',fontSize:'16px',fontWeight:'300px', height:'70px', color:'#262672', maxHeight:'40px', minHeight:'40px'}}>
                    QC Feedback
                </th>
            ),
            setCellProps: value => {
                return {
                    title: value,
                    style: {
                        textAlign:'center'
                    }
                }
            },
            customBodyRender: (value, tableMeta, updateValue) => (
                <Typography level="body-xs">{value}</Typography>
              )
            }
        },
    ];

    const components = {
        icons: {
          SearchIcon,
          PrintIcon,
          DownloadIcon,
          ViewColumnIcon,
          FilterIcon,
        }
      };
      React.useEffect(() => {
        setTotalCount(allTasks.length);
      }, [allTasks]);
  const options = {
    filterType: "dropdown",
    filterArrayFullMatch: false,
    selectableRows: "none",
    selectableRowsOnClick: false,
    count: totalCount,
    rowsPerPageOptions: [10, 50, 100, { label: 'All', value: totalCount }],
    textLabels: {
        body: {
          noMatch: "Loading...",
        }
    },
    downloadOptions: {
        filename: 'task_details.csv',
        filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
        }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
        const alteredData = data?.map((row, index) => ({
          index,
          data: row?.data?.map((field, index) => {
                    if (columns[index].customBodyRender) {
                        //return columns[index].customBodyRender(field)
                    }
                   

                    if(columns[index].label==="QC Feedback" || 
                        columns[index].label==="Prod Feedback" ||
                        columns[index].label==="Prod feedback from QC"
                    ) {
                        return  field;
                    }



                    if(columns[index].label==="QC Est. Time" || 
                        columns[index].label==="QC Act. Time" ||
                        columns[index].label==="Prod Act. Time" ||
                        columns[index].label==="Prod Est. Time"
                    ) {
                        return convertSecondsToHours(field);
                    }

                    if(columns[index].label==="QC Assigned To" || 
                        columns[index].label==="Assigned To" ||
                        columns[index].label==="Assigned By"
                    ) {
                        return field && field.firstName ? field.firstName+" "+field.lastName : "Not assigned";
                    }

                   
                    return  field;
                }),
        }))
        return `${buildHead(columns)}${buildBody(alteredData)}`.trim()
      },
    onRowClick: (rowData, rowMeta) => {
        setSelectedId(rowData[0].props.children);
        toggleViewTaskModal();
    },
    customToolbar: () => <IconButton onClick={() => showTableButton("true")}style={{float:'right'}}><BsKanban  color="grey"/></IconButton>,
    //onRowSelectionChange: (data ,allRowsSelected, rowsSelected) => { console.log(data,allRowsSelected, rowsSelected);}
  };

  return (
    <>
   <MUIDataTable
   size="small"
  title={""}
  data={allTasks}
  columns={columns}
  options={options}
  components={components}
/>

<BootstrapDialog
        onClose={toggleViewTaskModal}
        aria-labelledby="customized-dialog-title"
        onBackdropClick={"false"}
        open={open}
      >
        {/*<BootstrapDialogTitle id="customized-dialog-title" className="toolHeader" style={{ textAlign: 'center', backgroundImage:'url(https://t4.ftcdn.net/jpg/02/76/08/07/360_F_276080724_hltnCyDjcqAyRtLzDYo3T2jXbBtCD7fl.jpg)', color: 'white' }}>
        ADD MEMBER
    </BootstrapDialogTitle>*/}

        <Popup viewTaskModal={toggleViewTaskModal} taskId={selectedId}/>

      </BootstrapDialog>
      </>
  );
}

export default TableView;