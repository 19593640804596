
import moment from "moment";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import momentPlugin from "@fullcalendar/moment";
import multiMonthPlugin from '@fullcalendar/multimonth';
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { HiChatAlt } from "react-icons/hi";


import styled from "@emotion/styled";
import { Button, Card, Chip, Radio, Sheet, Stack, Typography } from "@mui/joy";
import { Dialog, Popover, RadioGroup, SpeedDial, Tooltip } from "@mui/material";
import React, { useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { HiMiniInformationCircle } from "react-icons/hi2";
import { MdDateRange } from "react-icons/md";
import { deleteHolidayByAdmin, getAllHoliday, getHoliday, logout } from "../../../service/service-call";
import AddHoliday from "./AddHoliday";

import { toast } from "react-toastify";

export default function Holiday() {
  const [isAddHolidayOpen, setIsAddHolidayOpen] = React.useState(false);
  const [allHoliday, setAllHoliday] = React.useState([]);
  const [selectedHoliday, setSelectedHoliday] = React.useState("");
  const [holiday, setHoliday] = React.useState();


  const [anchorHolidayEl, setAnchorHolidayEl] = React.useState(null);
  const openHoliday = Boolean(anchorHolidayEl);
  const idHoliday = openHoliday ? 'simple-popover' : undefined;
  const handleHolidayClick = (event) => {
    getHolidayInfo(event.id, event._def.extendedProps.responseType);
    setAnchorHolidayEl(true);
  };

  const handleHolidayClose = () => {
    setAnchorHolidayEl(null);
  };

  const calendarRef = useRef();

  React.useEffect(() => {
    getAllHolidays();
  }, []);

  function deleteHoliday() {
    deleteHolidayByAdmin(selectedHoliday).then(resp => {

      toast.success('Holiday deleted successfully');
      getAllHolidays();
      handleHolidayClose();
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        toast.error(error.response.data.errors[0]);
      } else {
        toast.error("Internal server error, contact support team");
      }

    })
  }

  function getAllHolidays() {
    getAllHoliday(selectedHoliday).then(resp => {

      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        //data.map(e => e.display='background')
        data.map(e => e.extendedProps={
          fontWeight:'700', 
        })
        setAllHoliday(data);

      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  function getHolidayInfo(id, type) {
    getHoliday(id, type).then(resp => {

      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        setHoliday(data);

      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  function getDateInHolidayObj(data, holiday) { 
    return data.map(e => (
      <>
      {
      (holiday && holiday.leaveType && holiday.leaveType.name.toLowerCase()==="permission" && holiday.startTime && holiday.endTime) ? (
        <Chip size="sm" startDecorator={<MdDateRange />} color="success" variant="outlined">{e.date +" " +moment(holiday.startTime, "hh:mm:ss").format("hh:mm A")+"-"+ moment(holiday.endTime, "hh:mm:ss").format("hh:mm A")}</Chip>
      ) : (
        <Chip size="sm" startDecorator={<MdDateRange />} color="success" variant="outlined">{e.date}</Chip>
      )
    }
      {(holiday.leaveDayType) ? (
        <Chip size="sm" color="success" variant="outlined">{holiday.leaveDayType}</Chip>
      ):""}
      {
      (holiday.leaveHalfDayType) ? (
        <Chip size="sm" color="success" variant="outlined">{holiday.leaveHalfDayType}</Chip>
      ):""}

      <br></br>
      </>
    ))
  }

  function toggleAddHolidayModal() {
    setIsAddHolidayOpen(!isAddHolidayOpen);
    if (isAddHolidayOpen === true) {
      getAllHolidays();
    }
  }

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      minWidth: '400px !important',
      height: 'auto'
    },
  }));

  function getColor(name){
    if(name==="permission"){
      return "orange";
    }
    if(name==="monthly_leave"){
      return "purple";
    }
    if(name==="compensatory_leave"){
      return "cornflowerblue";
    }
    if(name==="loss_of_pay"){
      return "red";
    }
    if(name==="government_leave"){
      return "green";
    }
  }

  return (
    <div>
      <Card>
      <Stack direction={"row"} justifyContent={"space-evenly"}>
      <Chip sx={{backgroundColor:'red'}} size="sm" variant="solid">Loss Of Pay</Chip>
      <Chip sx={{backgroundColor:'green'}} size="sm" variant="solid">Government leave</Chip>
      <Chip sx={{backgroundColor:'purple'}} size="sm" variant="solid">Monthly Leave</Chip>
      <Chip sx={{backgroundColor:'cornflowerblue'}} size="sm" variant="solid">Compensatory Holiday</Chip>
      <Chip sx={{backgroundColor:'orange'}} size="sm" variant="solid">Permission</Chip>
      </Stack>
      </Card>


        {
          localStorage.getItem("role") === "ROLE_SUPER_ADMIN" ? (
            <Tooltip title={"ADD HOLIDAY"} arrow>
      <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        sx={{ position: 'absolute', bottom: 20, right: 20}}
        icon={<FaPlus size={"25px"}/>}
        color='success'
        onClick={toggleAddHolidayModal}
        className='hover:-translate-y-1 hover:scale-110 duration-300'
      >
      </SpeedDial></Tooltip>
) : ""}
      <br></br>
      <Stack direction={"row"} width={"auto"}>
        <Typography level='title-sm' color='primary' fontWeight={"700"}>HOLIDAY CALENDAR</Typography>

      </Stack>

      <FullCalendar
        header={{
          left: "prev,next today",
          center: "title",
          right: "year,month,basicWeek,basicDay",
        }}
        
        timezone="local"
        height="auto"
        eventMouseEnter={function (mouseEnterInfo) {
          setSelectedHoliday(mouseEnterInfo.event.id);

          handleHolidayClick(mouseEnterInfo.event);
        }
        }

        // eventMouseLeave={function (mouseEnterInfo) {
        //   setAnchorHolidayEl(null);
        // }
        // }

        ref={calendarRef}
        //locale={ptBrLocale}
        //hiddenDays= {[0] }
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        allDayDefault={false}
        defaultView="listYear"
        initialView='multiMonthYear'
        displayEventTime={false}
        buttonText={{
          today: 'TODAY'
        }}
        plugins={[
          multiMonthPlugin,
          momentPlugin,
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          listPlugin
        ]}
        views={{
          timeGridDay: {
            nowIndicator: true,
            columnHeaderText: date => {
              return moment(date)
                .format("dddd")
                .split("-")[0];
            },
          },
          timeGridWeek: {
            columnHeaderHtml: date => {
              return `
                    <div>
                      <div>${moment(date).format("ddd")}</div>
                      <strong>${moment(date).format("DD")}</strong>
                    </div>
                  `;
            }
          }
        }}
        events={allHoliday}
      />
      <BootstrapDialog
        onClose={toggleAddHolidayModal}
        aria-labelledby="customized-dialog-title"
        open={isAddHolidayOpen}
      >

        <AddHoliday addHolidayModal={toggleAddHolidayModal} />

      </BootstrapDialog>

      <Popover
        id={idHoliday}
        open={openHoliday}
        anchorEl={anchorHolidayEl}
        onClose={handleHolidayClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        style={{}}
      >
        <Stack direction={"column"} sx={{ padding: '30px' }}>
          <>
            <Card sx={{
              marginBottom: '5px', backgroundColor: "#FFDEE9",
              backgroundImage: `linear-gradient(0deg, #FFDEE9 0%, #B5FFFC 100%)`
            }}>

              <Card>
              <Typography fontWeight={"600"} sx={{fontStyle:'italic'}}>"{holiday && holiday.reason}"</Typography>
              </Card>
              <Chip size="sm" variant="solid" sx={{ backgroundColor: holiday && holiday.leaveType ? getColor(holiday.leaveType.name.toLowerCase()) : "coral"}}>{holiday && holiday.leaveType.name.toLowerCase()}</Chip>
              
              {
                holiday && holiday.holidayCalendarDetails ?
<Typography>{holiday && holiday.holidayCalendarDetails && getDateInHolidayObj(holiday.holidayCalendarDetails, holiday)}</Typography>
: holiday && holiday.leaveRequestDetails ? 
<Typography>{holiday && holiday.leaveRequestDetails && getDateInHolidayObj(holiday.leaveRequestDetails, holiday)}</Typography>
:""
              }
              
            </Card>
            {
              localStorage.getItem("role") === "ROLE_SUPER_ADMIN" ?
                (
                  <Stack direction={"row"} justifyContent={"center"}>

                    <Button size="sm" variant="solid" color="danger" onClick={deleteHoliday}>Delete</Button>
                    &nbsp;<Button size="sm" variant="soft" onClick={handleHolidayClose}>CLOSE</Button>
                  </Stack>
                ) : <Button size="sm" variant="soft" onClick={handleHolidayClose}>CLOSE</Button>
            }
          </>
        </Stack>


      </Popover>
    </div>
  )
}

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}