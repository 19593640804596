import SearchPIcon from '@mui/icons-material/Search';
import { Avatar, Button, Card, Stack, Typography } from '@mui/joy';
import { Chip, Paper, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import { getAllActiveMembers, getAllTeamMembers, getAllTeams, getAllTeamsForAdmin, getIndividualPerformance, getTeamById, logout, stringAvatar } from "../../service/service-call";
import ReportCard from './reportDetailCard';

export default function Reports() {
    const [allTeams, setAllTeams] = React.useState([]);
    const [selectedTeams, setSelectedTeams] = React.useState('');
    const [selectedTeamIds, setSelectedTeamIds] = React.useState('');

    const [searchLoading, setSearchLoading] = React.useState(false);
    const [allMembers, setAllMembers] = React.useState([]);
    const [selectedTeamMembers, setSelectedTeamMembers] = React.useState("");
    const [performanceData, setPerformanceData] = React.useState("");
    const [sdate, setSDate] = React.useState(moment().subtract(1, "days").format("YYYY-MM-DD"));
    const [edate, setEDate] = React.useState(moment().format("YYYY-MM-DD"));

    const handleEDateChange = (e) => {
        setEDate(moment(e).format("YYYY-MM-DD"))
    }

    const handleSDateChange = (e) => {
        setSDate(moment(e).format("YYYY-MM-DD"))
    }
    React.useEffect(() => {

        if (localStorage.getItem("role") === "ROLE_SUPER_ADMIN") {
            getAllMemberForTeam();
            getAllTeam();
        } else if (localStorage.getItem("role") === "ROLE_ADMIN") {
            getAllTeamMemberForAdmin();
            getAllTeamForAdmin();
        } else {

        }
        getIndividualPerformanceReport();

    }, []);

    function getAllTeam() {
        getAllTeams("").then(resp => {

            if (resp.status === 401) {
                logout();
            }
            resp.json().then(data => {

                setAllTeams(data);

            });
        }).catch(error => {
            console.log("login user err " + error);
        });
    }

    function getAllTeamForAdmin() {
        getAllTeamsForAdmin("").then(resp => {

            if (resp.status === 401) {
                logout();
            }
            resp.json().then(data => {

                setAllTeams(data);

            });
        }).catch(error => {
            console.log("login user err " + error);
        });
    }

    function getAllMemberForTeam() {
        getAllActiveMembers().then(resp => {

            if (resp.status === 401) {
                logout();
            }
            resp.json().then(data => {

                setAllMembers(data);

            });
        }).catch(error => {
            console.log("login user err " + error);
        });
    }

    function getAllTeamMemberForAdmin() {
        getAllTeamMembers().then(resp => {

            if (resp.status === 401) {
                logout();
            }
            resp.json().then(data => {

                setAllMembers(data);

            });
        }).catch(error => {
            console.log("login user err " + error);
        });
    }

    function getIndividualPerformanceReport() {
        let userId = null;
        let selectedTeam = null;

        if ((localStorage.getItem("role") === "ROLE_SUPER_ADMIN" || localStorage.getItem("role") === "ROLE_ADMIN")) {

            if (selectedTeamMembers) {
                let idCode = selectedTeamMembers.split("(");
                let val = idCode[1].split(")");
                userId = val[0];
            }
            if (selectedTeams) {
                let teamObj = allTeams.find(e => e.teamName === selectedTeams);
                selectedTeam = teamObj.id;
            }

        }

        if (sdate === "Invalid date" || edate === "Invalid date") {
            toast.error("Invalid search date range");
            return false;
        }
        setSearchLoading(true);
        getIndividualPerformance(selectedTeam ? selectedTeam : null, userId ? userId : null, sdate, edate).then(resp => {
            setSearchLoading(false);
            // if(resp.status === 401){
            //     logout();
            // }
            resp.json().then(data => {
                setPerformanceData(data);



            });
        }).catch(error => {
            console.log("Your session expired, Please login again", error);
            toast.error(error);
        });
    }


    const CustomPaper = (props) => {
        return <Paper elevation={8} sx={{ fontSize: '0.8rem !important' }} {...props} />;
    };

    return (
        <>
            <Stack direction={"row"}>

                {
                    localStorage.getItem("role") !== "ROLE_USER" ?
                        (
                            <>
                                <Autocomplete
                                    id="tags-filled"
                                    style={{ width: '300px' }}
                                    options={allTeams.map((option) => option.teamName)}
                                    value={selectedTeams}
                                    PaperComponent={CustomPaper}
                                    onChange={(event, newValue) => {
                                        setSelectedTeamMembers(null);
                                        setSelectedTeams(
                                            newValue);
                                        let teamObj = allTeams.find(e => e.teamName === newValue);

                                        let selectedTeam = teamObj.id;
                                        getTeamById(selectedTeam).then(resp => {

                                            if (resp.status === 401) {
                                                logout();
                                            }
                                            resp.json().then(data => {

                                                let arr = [];
                                                data.members.map((option) => arr.push(option));
                                                setAllMembers(arr);
                                            });
                                        }).catch(error => {
                                            console.log("login user err " + error);
                                        });
                                    }}
                                    // renderTags={(value, getTagProps) =>
                                    //     value.map((option, index) => (
                                    //         <Chip avatar={<Avatar size="lg" color='danger' {...stringAvatar(option)} />} variant="outlined" label={option} {...getTagProps({ index })} />
                                    //     ))
                                    // }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            size='small'
                                            InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                            label="Select Team"
                                            InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                            placeholder="Select Team"
                                        />
                                    )}
                                />&nbsp;&nbsp;&nbsp;
                                <Autocomplete

                                    id="tags-filled"
                                    size='small'
                                    style={{ width: '300px' }}
                                    PaperComponent={CustomPaper}
                                    options={allMembers.map((option) => option.firstName + " " + option.lastName + " (" + option.id + ")")}
                                    value={selectedTeamMembers}
                                    onChange={(event, newValue) => {
                                        setSelectedTeamMembers(
                                            newValue);
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip avatar={<Avatar size="lg" color='danger' {...stringAvatar(option)} />} variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size='small'
                                            variant="outlined"
                                            InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                            InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                            label="Select Member"
                                            placeholder="Select Member"
                                        />
                                    )}
                                />
                                &nbsp;&nbsp;&nbsp;
                            </>
                        ) : ""
                }


                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
                    <DesktopDatePicker
                        label="Start date"
                        fullWidth
                        value={moment(sdate).format("YYYY-MM-DD")}
                        disableFuture="true"
                        onChange={handleSDateChange}
                        minutesStep={30}
                        slotProps={{ field: { clearable: true } }}
                        renderInput={(params) => <TextField {...params}
                            InputProps={{ ...params.InputProps, size: "small", style: { fontSize: "0.8rem" } }}
                            InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}

                            size='small'
                        //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                        />}
                    /></LocalizationProvider>&nbsp;&nbsp;&nbsp;

                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
                    <DesktopDatePicker

                        label="End date"
                        fullWidth
                        minDateTime={sdate}
                        value={moment(edate).format("YYYY-MM-DD")}
                        disableFuture="true"
                        onChange={handleEDateChange}
                        minutesStep={30}
                        slotProps={{ field: { clearable: true } }}
                        renderInput={(params) => <TextField {...params}
                            InputProps={{ ...params.InputProps, size: "small", style: { fontSize: "0.8rem" } }}
                            InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                            size='small'
                        //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                        />}
                    /></LocalizationProvider>&nbsp;

                <Button size="sm" sx={{ fontSize: '0.8rem' }} onClick={() => getIndividualPerformanceReport()} loading={searchLoading}><SearchPIcon /> SEARCH</Button>
            </Stack>

            <br></br>
            <div style={{ maxHeight: '445px', minHeight: '445px', overflowY: 'auto' }}>
                {
                    performanceData && performanceData.length > 0 && performanceData[0].teamName && performanceData.map(e =>
                    (
                        <Card sx={{ padding: '10px', marginBottom: '20px', backgroundColor: 'aliceblue' }}>
                            <Typography className='animate-[slide-in-right_1s_ease-in-out]' variant='plain' level="title-md" sx={{ fontSize: '1rem', color: '#262673', textDecoration: 'underline', textAlign: 'center' }}>{e.teamName.toUpperCase()}</Typography>
                            {
                                e.taskTimeTaken.length > 0 && e.taskTimeTaken.map(e =>
                                (
                                    <ReportCard performanceData={e} />
                                ))
                            }
                        </Card>


                    ))
                }

                {
                    performanceData && performanceData.length > 0 && !performanceData[0].teamName && !performanceData[0].taskTimeTaken && performanceData.map(e =>
                    (
                        <ReportCard performanceData={e} />
                    ))
                }











            </div>
        </>
    )
}