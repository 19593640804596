import { Avatar, Box, Button, Chip, ListItem, Modal, Stack, Typography } from '@mui/joy';
import {
  Paper, TextField, Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Autocomplete,
  SpeedDial,
  Tooltip
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import SearchPIcon from '@mui/icons-material/Search';
import React from 'react';
import { deleteCompOff, getAllActiveMembers, getAllCompOffLeave, getAllTeamMembers, logout, stringAvatar } from '../../../service/service-call';
import { CompOffAdd } from './CompOffAdd';
import { Delete } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { FaPlus } from 'react-icons/fa';

export const CompOff = () => {
  const [allCompOffs, setAllCompOffs] = React.useState([]);
  const [sdate, setSDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [edate, setEDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [isAddCompOffOpen, setIsAddCompOffOpen] = React.useState(false);
  const [allMembers, setAllMembers] = React.useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = React.useState("");
  React.useEffect(() => {
    getAllCompOffs();
  }, []);

  React.useEffect(() => {

    if (localStorage.getItem("role") === "ROLE_SUPER_ADMIN") {
        getAllMemberForTeam();
    } else if (localStorage.getItem("role") === "ROLE_ADMIN") {
        getAllTeamMemberForAdmin();
    } else {

    }

}, []);

function getAllMemberForTeam() {
  getAllActiveMembers().then(resp => {

      if (resp.status === 401) {
          logout();
      }
      resp.json().then(data => {

          setAllMembers(data);

      });
  }).catch(error => {
      console.log("login user err " + error);
  });
}

function getAllTeamMemberForAdmin() {
  getAllTeamMembers().then(resp => {

      if (resp.status === 401) {
          logout();
      }
      resp.json().then(data => {

          setAllMembers(data);

      });
  }).catch(error => {
      console.log("login user err " + error);
  });
}

  function toggleAddCompOffModal() {
    setIsAddCompOffOpen(!isAddCompOffOpen);
    if (isAddCompOffOpen === true) {
      getAllCompOffs();
    }
  }

  function getAllCompOffs() {
    if (sdate === "Invalid date" || edate === "Invalid date") {
      toast.error("Invalid search date range");
      return false;
  }
    setSearchLoading(true);
    let userId = null;
    if (selectedTeamMembers) {
      let idCode = selectedTeamMembers.split("(");
      let val = idCode[1].split(")");
      userId = val[0];
  }
    getAllCompOffLeave(userId ? userId : null, sdate, edate).then(resp => {
      setSearchLoading(false);
      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        setAllCompOffs(data);
      });
    }).catch(error => {
      console.log("login user err " + error);
      setSearchLoading(false);
    });
  }
  const handleSDateChange = (e) => {
    setSDate(moment(e).format("YYYY-MM-DD"))
  }

  const handleEDateChange = (e) => {
    setEDate(moment(e).format("YYYY-MM-DD"))
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allCompOffs.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      minWidth: '400px !important',
      height: 'auto'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  const CustomPaper = (props) => {
    return <Paper elevation={8} sx={{ fontSize: '0.8rem !important' }} {...props} />;
};

  function onDelete(id) {
    deleteCompOff(id).then((resp) => {
      if (resp.status === 200) {
        toast.success('Comp off record deleted successfully!');
        getAllCompOffs();
      }
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        toast.error(error.response.data.errors[0]);
      } else {
        toast.error("Internal server error, contact support team");
      }
    })
  }

  return (
    <>
    {
      localStorage.getItem("role") === "ROLE_SUPER_ADMIN" ? (
<Tooltip title={"ADD COMP OFF LEAVES"} arrow>
    <SpeedDial
      ariaLabel="SpeedDial controlled open example"
      sx={{ position: 'fixed', bottom: 20, right: 20}}
      icon={<FaPlus size={"25px"}/>}
      color='success'
      onClick={toggleAddCompOffModal}
      className='hover:-translate-y-1 hover:scale-110 duration-300'
    >
    </SpeedDial></Tooltip>  
    
    
    ):""
    }
      

      <Stack direction={"row"}>


{
  localStorage.getItem("role")!=="ROLE_USER" ? (
    <>
    <Autocomplete

id="tags-filled"
size='small'
style={{ width: '300px' }}
PaperComponent={CustomPaper}
options={allMembers.map((option) => option.firstName + " " + option.lastName + " (" + option.id + ")")}
value={selectedTeamMembers}
onChange={(event, newValue) => {
    setSelectedTeamMembers(
        newValue);
}}
renderTags={(value, getTagProps) =>
    value.map((option, index) => (
        <Chip avatar={<Avatar size="lg" color='danger' {...stringAvatar(option)} />} variant="outlined" label={option} {...getTagProps({ index })} />
    ))
}
renderInput={(params) => (
    <TextField
        {...params}
        size='small'
        variant="outlined"
        InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
        InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
        label="Select Member"
        placeholder="Select Member"
    />
)}
/>
&nbsp;&nbsp;&nbsp;
    </>
  ):""
}
      
        <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
          
          <DesktopDatePicker

            label="Start date"
            fullWidth
            value={moment(sdate).format("YYYY-MM-DD")}
            disableFuture="true"
            onChange={handleSDateChange}
            minutesStep={30}
            slotProps={{ field: { clearable: true } }}
            renderInput={(params) => <TextField {...params}
              InputProps={{ ...params.InputProps, size: "small", style: { fontSize: "0.8rem" } }}
              InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
              size='small'
            //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
            />}
          /></LocalizationProvider>&nbsp;&nbsp;&nbsp;

        <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
          <DesktopDatePicker

            label="End date"
            fullWidth
            minDate={sdate}
            value={moment(edate).format("YYYY-MM-DD")}
            disableFuture="true"
            onChange={handleEDateChange}
            minutesStep={30}
            slotProps={{ field: { clearable: true } }}
            renderInput={(params) => <TextField {...params}
              InputProps={{ ...params.InputProps, size: "small", style: { fontSize: "0.8rem" } }}
              InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
              size='small'
            //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
            />}
          /></LocalizationProvider>&nbsp;

        <Button size="sm" onClick={() => getAllCompOffs()} loading={searchLoading}><SearchPIcon /></Button>&nbsp;
      </Stack>
      <br>
      </br>

      <>
        <TableContainer component={Paper} sx={{ marginTop: '10px', maxHeight: '380px', minHeight: '380px'  }}>

          <Table stickyHeader sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead  >
              <TableRow style={{ backgroundColor: 'aliceblue', color: '#262673' }}>
                <TableCell style={{ backgroundColor: 'aliceblue',color: '#262673', fontWeight:'700' }} align="start">FULL NAME</TableCell>
                <TableCell style={{ backgroundColor: 'aliceblue',color: '#262673', fontWeight:'700' }} align="center">DATE</TableCell>
                <TableCell style={{ backgroundColor: 'aliceblue',color: '#262673', fontWeight:'700' }} align="center">DAY</TableCell>
                <TableCell style={{ backgroundColor: 'aliceblue',color: '#262673', fontWeight:'700' }} align="center">REASON</TableCell>
                {
  localStorage.getItem("role")==="ROLE_SUPER_ADMIN" ? (
                <TableCell style={{ backgroundColor: 'aliceblue',color: '#262673', fontWeight:'700' }} align="center">ACTION</TableCell>
  ):""}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? allCompOffs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : allCompOffs
              ).map((profile) => (
                <TableRow style={{ cursor: 'pointer' }}>
                  <TableCell component="th" scope="row">
                    <Typography variant="body1" sx={{ float: 'left' }}>
                      <Stack direction={"row"} spacing={1}>

                        <ListItem>
                          <Avatar variant='solid' size='sm' src={profile.member ? profile.member.firstName : ""} {...stringAvatar(profile.member.firstName ? profile.member.firstName + " " + profile.member.lastName : "U U")}></Avatar>
                          &nbsp;
                          <Typography level="body-md" fontWeight={"500"}>{profile.member.firstName ? (profile.member.firstName + " " + profile.member.lastName) : ""}
                          </Typography>
                        </ListItem>
                      </Stack>
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: 160, textAlign: "center" }} align="right">
                    <Typography level="body-sm">{profile.date}</Typography>
                  </TableCell>
                  <TableCell style={{ width: 160, textAlign: "center" }} align="right">
                    <Typography level="body-sm">{profile.reduceTime == 1 ? <Chip variant='solid' size='sm' color='warning' sx={{borderRadius:'5px'}}>FULL DAY</Chip> : <Chip variant='solid' color='success' size='sm' sx={{borderRadius:'5px'}}>HALF DAY</Chip>}</Typography>
                  </TableCell>
                  <TableCell style={{ width: 260, textAlign: "center" }} align="right">
                    <Typography level="body-sm">{profile.reason}</Typography>
                  </TableCell>
                  
                  {
  localStorage.getItem("role")==="ROLE_SUPER_ADMIN" ? (
                  <TableCell style={{ width: 90, textAlign: "center" }}>
                    <Delete onClick={() => onDelete(profile.id)} color='warning' />
                  </TableCell>
  ):""}
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={8}
                  count={allCompOffs.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </>



      {/* <BootstrapDialog
        onClose={toggleAddCompOffModal}
        aria-labelledby="customized-dialog-title"
        open={isAddCompOffOpen}
      > */}
<Modal open={isAddCompOffOpen} onClose={toggleAddCompOffModal}>
        <Box style={{ width: "800px", margin: 'auto', marginTop: '50px', padding: '20px', backgroundColor: 'white', overflowY:'auto', height:'500px', borderRadius:'4px' }}>
          <Typography fontWeight={"700"}>ADD COMPENSATORY LEAVE</Typography>
        <CompOffAdd toggleCompOffModal={toggleAddCompOffModal} />

      {/* </BootstrapDialog> */}
      </Box>
      </Modal>
    </>
  )
}
