import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Explore from "./Pages/Manage";
import Home from "./Pages/Home";
import Settings from "./Pages/Settings";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import Manage from "./Pages/Manage";
import Trainings from "./Components/Trainings/trainings";
import Reports from "./Components/Reports/report";
import MainLayout from './MainLayout';

function Root() {
  const isLoggedIn = localStorage.getItem("userId");

  return (
    <>
      {isLoggedIn ? (
        <MainLayout>
          <Routes>
            <Route path="/" element={<Home />} />
             <Route path="/board" element={<Home />} />
            <Route path="/manage" element={<Manage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/attendance" element={<Settings />} />
            <Route path="/trainings" element={<Trainings />} />
            <Route path="/reports" element={<Reports />} />
        </Routes>
        </MainLayout>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </>
  );
}

export default Root;
