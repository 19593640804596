import { Avatar, Button, Chip, Stack, Typography } from '@mui/joy';
import { DialogActions, DialogContent, Paper, TextField } from '@mui/material';
import { DesktopDatePicker, DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import * as React from 'react';
import { getAllLeaveType, getLeaveRequest, logout, stringAvatar, updateLeaveRequest } from '../../../service/service-call';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { toast } from 'react-toastify';


export default function LeaveRequestEdit({ toggleEditLeaveRequest, id }) {
    const [data, setData] = React.useState("");
    const [allLeaveTypes, setAllLeaveTypes] = React.useState([]);
    const [selectedLeaveType, setSelectedLeaveType] = React.useState("");
    const [leaveDayType, setLeaveDayType] = React.useState("");
    const [leaveHalfDayType, setLeaveHalfDayType] = React.useState("");
    const [reason, setReason] = React.useState('');
    const [sdate, setSDate] = React.useState(null);
    const [edate, setEDate] = React.useState(null);
    const [maxTime, setMaxTime] = React.useState(null);
    const [sTime, setSTime] = React.useState(null);
    const [eTime, setETime] = React.useState(null);
    const [eTimeError, setETimeError] = React.useState(null);
    const [pdate, setPDate] = React.useState(null);
    const [verifyDate, setVerifyDate] = React.useState('');

    React.useEffect(() => {
        getLeaveRequest(id).then(resp => {
            if (resp.status === 401) {
                logout();
            }
            resp.json().then(data => {
                setData(data);
                setSelectedLeaveType(data.leaveType.name);
                setPDate(data.startDate);
                setSDate(data.startDate);
                setEDate(data.endDate);
                if (data.leaveType.name === "PERMISSION") {
                    setSTime(moment(data.leaveRequestDetails[0].startTime, "HH:mm:ss"));
                    setETime(moment(data.leaveRequestDetails[0].endTime, "HH:mm:ss"));
                } else {
                    setLeaveDayType(data.leaveDayType);
                    setLeaveHalfDayType(data.leaveHalfDayType);
                }

                setReason(data.reason);
            });
        }).catch(error => {
            console.log("login user err " + error);
        });
    }, []);


    React.useEffect(() => {
        getAllLeaveTypes();
    }, []);

    function getAllLeaveTypes() {
        getAllLeaveType("").then(resp => {
            if (resp.status === 401) {
                logout();
            }
            resp.json().then(data => {
                let arr = data.filter(function (item) {
                    return item.name !== "GOVERNMENT_LEAVE"
                })
                setAllLeaveTypes(arr);

            });
        }).catch(error => {
            console.log("login user err " + error);
        });
    }


    const handleEDateChange = (e) => {
        setEDate(moment(e).format("YYYY-MM-DD hh:mm A"))
    }

    const handleSDateChange = (e) => {
        setVerifyDate(e);
        setSDate(moment(e).format("YYYY-MM-DD hh:mm A"))
    }

    const handleSTimeChange = (e) => {
        const maxTime = moment(sTime, "HH:mm");
        let mTime = maxTime.add(2, 'hours');
        mTime = mTime.add(1, 'minutes');
        setMaxTime(mTime);
        setSTime(e)
    }

    const handleETimeChange = (e) => {
        //setETime(moment(e).format("hh:mm a"))
        let error = '';
        const maxTime = moment(sTime, "HH:mm");
        if (maxTime.add(2, 'hours').add(1, 'minutes').isBefore(e)) {
            error = "Please choose time duration should be 2 or less than 2 hours";
        } else {
            error = "";
        }
        setETimeError(error);
        setETime(e)
    }

    const handlePDateChange = (e) => {
        setPDate(moment(e).format("YYYY-MM-DD hh:mm A"))
    }

    Date.prototype.addHours = function (h) {
        this.setHours(this.getHours() + h);
        return this;
    }
    const handleReasonChange = (e) => {
        setReason(e.target.value);
    };

    function getDates(startDate, endDate) {
        const dates = [];
        let currentDate = moment(startDate);
        let diff = moment(endDate).diff(currentDate, 'days');
        while (diff > -1) {
            dates.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'days');
            diff--;
        }

        return dates;
    }

    function editLeaveReaquest() {
        let arr = [];
        let selectedLeaveTypeId = allLeaveTypes.find(e => e.name === selectedLeaveType).id;
        if (selectedLeaveType !== "PERMISSION") {
            setSTime(null);
            setETime(null);
        } else {
            setLeaveDayType(null);
            setLeaveHalfDayType(null);
            setSDate(null);
            setEDate(null);
        }

        // let arr = [selectedTeamIds];
        if (selectedLeaveType !== "PERMISSION" && !leaveDayType) {
            toast.error('Select leave type');
            return false;
        }

        if (selectedLeaveType !== "PERMISSION" && leaveDayType === "HALF_DAY" && !leaveHalfDayType) {
            toast.error('Select leave half day type');
            return false;
        }

        if (selectedLeaveType !== "PERMISSION" && leaveDayType === "FULL_DAY" && (!sdate || !edate)) {
            toast.error('Select date');
            return false;
        }

        if (selectedLeaveType === "PERMISSION" && (!pdate || !sTime || !eTime)) {
            toast.error('Select date & times');
            return false;
        }

        if (selectedLeaveType === "PERMISSION" &&
            (moment(eTime, "HH:mm").isBefore(moment(sTime, "HH:mm")))) {
            toast.error('Time is not valid');
            return false;
        }
        if (selectedLeaveType === "PERMISSION" &&
            (moment(eTime, "HH:mm").diff(moment(sTime, "HH:mm"), "minutes") > 120)) {
            toast.error('Permission time is not more than 2 hrs');
            return false;
        }

        if (reason === "" || reason === undefined || selectedLeaveTypeId === "" || selectedLeaveTypeId === undefined) {
            toast.error('Fields cannot be blank');
            return false;
        } else {
            let dateRange = [];
            if (selectedLeaveType === "PERMISSION") {
                dateRange.push(moment(pdate).format('YYYY-MM-DD'))
            } else {
                dateRange = getDates(sdate, edate)
            }
            if(dateRange.length===0){
                toast.error('Date cannot be blank');
                return false;
            }
            updateLeaveRequest(id, selectedLeaveTypeId, leaveDayType, leaveHalfDayType, reason, dateRange, sTime ? moment(sTime).format('hh:mm A') : null, eTime ? moment(eTime).format('hh:mm A') : null).then(resp => {
                setSelectedLeaveType("");
                setLeaveDayType("");
                setLeaveHalfDayType("");
                setReason("");
                setSDate(null);
                setEDate(null);
                setMaxTime(null);
                setSTime(null);
                setETime(null);
                setPDate(null);
                toast.success('Leave request updated successfully');
                toggleEditLeaveRequest();
            }).catch(error => {
                if (error && error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                    toast.error(error.response.data.errors[0]);
                } else {
                    toast.error("Internal server error, contact support team");
                }

            })
        }
    }

    const CustomPaper = (props) => {
        return <Paper elevation={8} sx={{ fontSize: '0.8rem !important' }} {...props} />;
    };
    return (
        <>

            <DialogContent>
                <Typography level='title-lg'>UPDATE LEAVE REQUEST</Typography>
                <br></br><br></br>
                <Autocomplete
                    id="tags-filled"
                    sx={{ width: '64%' }}
                    options={allLeaveTypes.map(option => option.name)}
                    value={selectedLeaveType}
                    PaperComponent={CustomPaper}    
                    onChange={(event, newValue) => {
                        setSelectedLeaveType(
                            newValue);
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip avatar={<Avatar size="lg" color='danger' {...stringAvatar(option)} />} variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            size='small'
                            label="Select Leave Type"
                            style={{ width: '100%' }}
                            InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                    InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                   
                        />
                    )}
                />&nbsp;&nbsp;&nbsp;

                {
                    selectedLeaveType === "MONTHLY_LEAVE" || selectedLeaveType === "COMPENSATORY_LEAVE"
                        || selectedLeaveType === "LOSS_OF_PAY" ?
                        (
                            <>
                                <br></br>
                                <FormControl fullWidth variant="standard">
                                    <FormLabel id="demo-radio-buttons-group-label" sx={{fontSize:'0.8rem'}}>Leave Day Type:</FormLabel>
                                    <Stack direction={"row"}>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                            sx={{fontSize:'0.8rem'}}
                                            value={leaveDayType}
                                            onChange={(e) => setLeaveDayType(e.target.value)}
                                        >
                                            <FormControlLabel value="FULL_DAY" control={<Radio />} label="&nbsp;&nbsp;Full Day" />
                                            <FormControlLabel value="HALF_DAY" control={<Radio />} label="&nbsp;&nbsp;Half Day" />

                                        </RadioGroup>
                                    </Stack>
                                </FormControl>



                                {
                                    leaveDayType === "HALF_DAY" ? (

                                        <FormControl fullWidth variant="standard">
                                            <FormLabel id="demo-radio-buttons-group-label">Half Day Type:</FormLabel>
                                            <Stack direction={"row"}>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="female"
                                                    name="radio-buttons-group"
                                                    value={leaveHalfDayType}
                                                    sx={{fontSize:'0.8rem'}}
                                                    onChange={(e) => setLeaveHalfDayType(e.target.value)}
                                                >
                                                    <FormControlLabel value="FIRST_HALF" control={<Radio />} label="&nbsp;&nbsp;First Half" />
                                                    <FormControlLabel value="SECOND_HALF" control={<Radio />} label="&nbsp;&nbsp;Second Half" />

                                                </RadioGroup>
                                            </Stack>
                                        </FormControl>
                                    ) : ""
                                }
 <br></br>
                                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
                                    <DesktopDatePicker
                                        minDate={moment().subtract(15, "days").format("YYYY-MM-DD")}
                                        label="Start date"
                                        fullWidth
                                        value={moment(sdate).format("YYYY-MM-DD")}
                                        //disablePast="true"
                                        onChange={handleSDateChange}
                                        minutesStep={30}
                                        slotProps={{ field: { clearable: true } }}
                                        renderInput={(params) => <TextField {...params}
                                        InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                        InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                       
                                            size='small'
                                        //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                                        />}
                                    /></LocalizationProvider>&nbsp;&nbsp;&nbsp;
                                    <br></br> <br></br>
                                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
                                    <DesktopDatePicker

                                        label="End date"
                                        fullWidth
                                        minDate={sdate}
                                        value={moment(edate).format("YYYY-MM-DD")}
                                        //disablePast="true"
                                        onChange={handleEDateChange}
                                        minutesStep={30}
                                        slotProps={{ field: { clearable: true } }}
                                        renderInput={(params) => <TextField {...params}
                                        InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                        InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                       
                                            size='small'
                                        //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                                        />}
                                    /></LocalizationProvider>
                            </>
                        ) : (selectedLeaveType === "PERMISSION" ? (
                            <>
                                <br></br>
                                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
                                    <DesktopDatePicker
                                        minDate={moment().subtract(15, "days").format("YYYY-MM-DD")}
                                        label="Date"
                                        fullWidth
                                        value={moment(pdate).format("YYYY-MM-DD")}
                                        //disablePast="true"
                                        onChange={handlePDateChange}
                                        minutesStep={30}
                                        slotProps={{ field: { clearable: true } }}
                                        renderInput={(params) => <TextField {...params}
                                        InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                        InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                       
                                            size='small'
                                        //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                                        />}
                                    /></LocalizationProvider>&nbsp;&nbsp;&nbsp;
<br></br><br></br>
                                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '70% !important' }}>
                                    <DesktopTimePicker

                                        label="Start Time"
                                        fullWidth
                                        value={sTime}
                                        //disablePast="true"
                                        onChange={handleSTimeChange}
                                        minutesStep={30}
                                        slotProps={{ field: { clearable: true } }}
                                        renderInput={(params) => <TextField {...params}
                                            value={sTime}
                                            size='small'
                                            InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                    InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                   
                                        //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                                        />}
                                    /></LocalizationProvider>
                                &nbsp;&nbsp;&nbsp;
                                <br></br><br></br>
                                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
                                    <DesktopTimePicker

                                        label="End Time"
                                        fullWidth
                                        value={eTime}
                                        //disablePast="true"
                                        onChange={handleETimeChange}
                                        maxTime={maxTime}
                                        minutesStep={1}
                                        renderInput={(params) => <TextField {...params}
                                            helperText={eTimeError}
                                            size='small'
                                            InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                    InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                   
                                        //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                                        />}
                                    /></LocalizationProvider>
                            </>
                        ) : "")
                }


                {
                    selectedLeaveType ? (
                        <>
                            <br></br>    <br></br>
                            <FormControl required={true} size="small" variant="standard" style={{ textAlign: 'center', width: '64%' }}>
                                <TextField
                                    id="standard-adornment-reason"
                                    label="Reason"
                                    size="small"
                                    multiline
                                    minRows={3}
                                    maxRows={5}
                                    inputProps={{
                                        maxlength: 200,
                                        style:{
                                            fontSize:'0.8rem'
                                        }
                                    }}
                                    helperText={`${reason.length}/${200}`}
                                    type={'text'}
                                    value={reason}
                                    onChange={handleReasonChange}
                                />
                            </FormControl>



                            <br></br>


                        </>
                    ) : ""
                }

            </DialogContent>
            <DialogActions>
                <Button variant={"solid"} color='success' size='sm' onClick={editLeaveReaquest}>UPDATE</Button>
                <Button variant={"soft"} size='sm' onClick={toggleEditLeaveRequest}>CLOSE</Button>
            </DialogActions>
        </>


    )
}
