import React from 'react';
import styledx from '@xstyled/styled-components';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { borderRadius, grid } from './constants';
import { IoChatbubbleEllipsesOutline, IoSearchOutline, IoStarOutline } from "react-icons/io5";
import { GrAttachment } from "react-icons/gr";
import { keyframes } from '@mui/system';

import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, styled } from '@mui/material';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import Popup from './Popup';
import { Avatar, AvatarGroup, Badge, Button, Card, CardContent, CardOverflow, Chip, Stack, Tooltip, Typography, badgeClasses } from '@mui/joy';
import { copyTask, stringAvatar } from '../../service/service-call';
import moment from 'moment';
import { StarBorderOutlined } from '@mui/icons-material';
import { FaRegStar, FaStar, FaStarHalf, FaStarHalfAlt } from 'react-icons/fa';
import { LuCopyPlus } from 'react-icons/lu';
import { toast } from 'react-toastify';
const getBackgroundColor = (isDragging, isGroupedOver, authorColors) => {
  if (isDragging) {
    return authorColors.soft;
  }

  if (isGroupedOver) {
    return '#EBECF0';
  }

  return '#FFFFFF';
};

const getBorderColor = (isDragging, authorColors) =>
  isDragging ? authorColors.hard : 'transparent';

const imageSize = 40;

const CloneBadge = styledx.div`
  background: #79f2c0;
  bottom: ${grid / 2}px;
  border: 2px solid #57d9a3;
  border-radius: 50%;
  box-sizing: border-box;
  font-size: 10px;
  position: absolute;
  right: -${imageSize / 3}px;
  top: -${imageSize / 3}px;
  transform: rotate(40deg);
  height: ${imageSize}px;
  width: ${imageSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styledx.a`
text-decoration: none;
`;

// const Avatar = styledx.img`
//   width: ${imageSize}px;
//   height: ${imageSize}px;
//   border-radius: 50%;
//   margin-right: ${grid}px;
//   flex-shrink: 0;
//   flex-grow: 0;
// `;

const Content = styledx.div`
  /* flex child */
  flex-grow: 1;
  
  flex-basis: 100%;
  
  display: flex;
  flex-direction: column;
`;

const BlockQuote = styledx.div`
  &::before {
    content: open-quote;
  }
  &::after {
    content: close-quote;
  }
`;

const Footer = styledx.div`
  display: flex;
  margin-top: ${grid}px;
  align-items: center;
`;

const Author = styledx.small`
  color: ${(props) => props.colors.hard};
  flex-grow: 0;
  margin: 0;
  background-color: ${(props) => props.colors.soft};
  border-radius: ${borderRadius}px;
  font-weight: normal;
  padding: ${grid / 2}px;
`;

const QuoteId = styledx.small`
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  font-weight: normal;
  text-overflow: ellipsis;
  text-align: right;
`;

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent



function QuoteItem(props) {
  const { quote, isDragging , isGroupedOver, provided, style, isClone, index} = props;
  const [selectedTaskId, setselectedTaskId] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [copyShow, setCopyShow] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(quote.id);  
  const handleClickOpen = () => {
    localStorage.setItem("isPopUpOpen", "true");
    toggleViewTaskModal();
  };

  // function padToTwoDigits(num) {
  //   return num.toString().padStart(2, "0");
  // }
  // function toHoursAndMinutes(totalMinutes) {
  //   const hours = Math.floor(totalMinutes / 60);
  //   const minutes = totalMinutes % 60;
  //   return `${padToTwoDigits(hours) +"Hrs, "}${padToTwoDigits(minutes)+"mins"}`;
  // }
  function toHoursAndMinutes(totalMinutes) {
    var sign = totalMinutes < 0 ? "-" : "";
    totalMinutes = Math.abs(totalMinutes); // Make totalMinutes positive for calculation
    var hours = Math.floor(totalMinutes / 60);
    var minutes = totalMinutes % 60;
    return  hours + ":" + (minutes < 10 ? "0" : "") + minutes + " hrs";
    //return `${hours+":"}${remainingMinutes+" hrs" }`;
}

  function addCopyOfTask(id){
    setselectedTaskId(id);
    toggleCopyTaskModal();
  }

  function createCopy(){
    copyTask(selectedTaskId).then(resp => {
      let data = resp.data;
      toast.success('Task copied successfully - '+data.id);
      toggleCopyTaskModal();
      props.getAllTasksForUser();
  }).catch(error => {
      if(error && error.response && error.response.data && error.response.data.message){
          toast.error(error.response.data.message);
        } else if(error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0){
          toast.error(error.response.data.errors[0]);
        } else {
          toast.error("Internal server error, contact support team");
        }
     
    })
  }

  function toggleCopyTaskModal() {
    setCopyShow(!copyShow);
    // if (open === false) {
      
    //   props.getAllTasksForUser();
    // } else {
    //   props.getAllTasksForUser();
    // }
  }


  function toggleViewTaskModal() {
    localStorage.setItem("isPopUpOpen", !open);
    setOpen(!open);
    
    if (open === true) {
      props.getAllTasksForUser();
    } 
  }


  const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: '1100px !important',
    height: 'auto'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapCopyDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: '400px !important',
    height: 'auto'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
  return (
    <Container
     
      isDragging={isDragging}
      isGroupedOver={isGroupedOver}
      isClone={isClone}
      colors={"red"}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      data-testid={quote.id}
      data-index={index}
      aria-label={`${quote.title} quote ${quote.title}`}
    >
      {/* <Avatar src={quote.title} alt={quote.title} /> */}
      <Card orientation="vertical" variant="outlined" 
      
        sx={{ fontSize:'0.5rem', 
              gap:'0.1rem', 
              marginTop:'5px', 
              backgroundColor: !(quote.status ==="DONE" || quote.status ==="HOLD" 
                                || quote.status ==="DROP" || quote.status ==="QA") 
                            && quote.endDate 
                            && moment(moment()).isAfter(quote.endDate)? 'peachpuff':
                            (quote.status ==="QA" && quote.qcEndDate
                              && moment(moment()).isAfter(quote.qcEndDate))? 'peachpuff':"", 
              "&:hover": {color:'#262673', border:'#262673 2px SOLID'} 
      }} >
      <Stack direction={"row"} >
      <Chip
        size="sm"
        variant="solid"
        sx={{fontSize:'0.8rem', borderRadius:'5px', backgroundColor:'#262673', color:'white'}}
        
       
      >
        {quote.id}
      </Chip>&nbsp;

      {
        quote.projectId && quote.projectId.projectName ?
        (
          <Chip
                  size="sm"
                  variant="outlined"
                  sx={{fontSize:'0.8rem', borderRadius:'5px'}}
                  color={"success"}
                
                >
                  {quote.projectId.projectName}
                </Chip>
        ):""
      }
      
      </Stack>
            <CardContent sx={{color:'#262673', cursor:'pointer', fontSize:'0.5rem'}}  >
              <div  onClick={handleClickOpen}>
              <Typography fontWeight="md" class="font-bold" sx={{color:'#262673', fontSize:'0.7rem'}}>
                {quote.title}
              </Typography>

              <Typography level="body-xs" sx={{fontSize:'0.6rem'}}>
              {quote.shortDescription.length <= 90 ? quote.shortDescription: (quote.shortDescription.substr(0, 90) + "...")}
              
                </Typography>
              <Stack direction={"row"}  marginTop={"3px"}>

                {
                  quote.priority ? 
                  (
                      <Chip
                              size="sm"
                              variant={(quote.priority==="LOW")? "soft":(quote.priority==="MEDIUM")?"soft":"solid"}
                              sx={{fontSize:'0.6rem', borderRadius:'2px', animation: (quote.priority==="HIGH")?`${blink} 0.5s linear infinite`:""}}
                              color={(quote.priority==="LOW")? "neutral":(quote.priority==="MEDIUM")?"primary":"warning"}
                            >
                              {quote.priority}
                            </Chip> 
                  ):""
                }
                {
                  quote.taskStatusTrack ? 
                  (
                      <>&nbsp;<Chip
                              size="sm"
                              variant="solid"
                              sx={{fontSize:'0.6rem', borderRadius:'2px', color:'white', backgroundColor:'orange'}}
                             
                            >
                              {quote.taskStatusTrack}
                            </Chip></>
                  ):""
                }
              &nbsp;
              {
                quote.endDate && quote.status !=="QA" && quote.status !=="DONE" ? 
                (
                    <Tooltip  title={"Due date for this task"} >
                          <Chip
                            size="sm"
                            variant="outlined"
                            sx={{fontSize:'0.6rem', borderRadius:'5px'}}
                            color={"success"}
                            startDecorator={<AccessAlarmIcon style={{fontSize:'0.7rem'}}/>}   
                          >
                            {toHoursAndMinutes(moment(quote.endDate).diff(moment(), 'minutes'))} {moment(quote.endDate).diff(moment(), 'minutes') < 0 ? "elapsed":"remaining"}
                          </Chip>
                          </Tooltip>
                ): quote.qcEndDate && quote.status ==="QA" ? (
                  <Tooltip  title={"QC Due date for this task"} >
                          <Chip
                            size="sm"
                            variant="outlined"
                            sx={{fontSize:'0.6rem', borderRadius:'5px'}}
                            color={"success"}
                            startDecorator={<AccessAlarmIcon style={{fontSize:'0.7rem'}}/>}   
                          >
                            {toHoursAndMinutes(moment(quote.qcEndDate).diff(moment(), 'minutes'))} {moment(quote.qcEndDate).diff(moment(), 'minutes') < 0 ? "elapsed":"remaining"}
                          </Chip>
                          </Tooltip>
                ):quote.modifiedDate && quote.status ==="DONE" ? (
                  <Tooltip  title={"Completed on"} >
                          <Chip
                            size="sm"
                            variant="outlined"
                            sx={{fontSize:'0.6rem', borderRadius:'5px'}}
                            color={"success"}
                            startDecorator={<AccessAlarmIcon style={{fontSize:'0.7rem'}}/>}   
                          >
                            {moment(quote.modifiedDate).format("DD-MM-YYYY hh:mm A")}
                          </Chip>
                          </Tooltip>
                ):""
              }
              
      </Stack>
      </div>
              <Stack direction={"row"}  fontSize={"0.7rem"} spacing={1} alignItems="center" >
              <Tooltip  title={"Created by "+quote.createdBy.firstName+" "+quote.createdBy.lastName} >
              <Avatar size="sm" {...stringAvatar(quote.createdBy.firstName.toUpperCase()+" "+quote.createdBy.lastName.toUpperCase())}></Avatar>
              </Tooltip>
              <IoChatbubbleEllipsesOutline fontSize={"1rem"}/>{quote.commentsCount}
              
              <GrAttachment fontSize={"1rem"}/>{quote.attachmentsCount}
              
              
            {
        quote.assignedTo && quote.assignedTo.length > 2 ? (
          <AvatarGroup>
            <>
          <Tooltip  title={"Prod assigned to "+quote.assignedTo[0].firstName+" "+quote.assignedTo[0].lastName} >
            <Avatar size="sm" {...stringAvatar(quote.assignedTo[0].firstName+" "+quote.assignedTo[0].lastName)}></Avatar>
                   </Tooltip> 
                   <Tooltip  title={"Assigned to "+quote.assignedTo[1].firstName+" "+quote.assignedTo[1].lastName} >
                    <Avatar size="sm" {...stringAvatar(quote.assignedTo[1].firstName+" "+quote.assignedTo[1].lastName)}></Avatar>
                   </Tooltip> 
                  
                   
                   </>
                   <Avatar size="sm">+{quote.assignedTo.length-2}</Avatar>
       </AvatarGroup>
        ): (
          <>
          {
             quote.assignedTo ?(
               <>
                   <Tooltip  title={"Prod assigned to "+quote.assignedTo.firstName+" "+quote.assignedTo.lastName} ><Avatar size="sm" {...stringAvatar(quote.assignedTo.firstName+" "+quote.assignedTo.lastName)}></Avatar>
                   </Tooltip>
                     
                     </>
             ) :""
          }        
         </>
        )
      }

          {
             quote.qcAssignedTo ?(
               <div style={{marginLeft:'-5px'}}  >
                   <Tooltip title={"QC Assigned to "+quote.qcAssignedTo.firstName+" "+quote.qcAssignedTo.lastName} >
                   <Badge size='sm'
                   badgeContent={<IoSearchOutline size={"10px"}/>}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        badgeInset="5%"
        variant={"outlined"}
        color={"success"}
        sx={{
          padding:'1px'
        }}
      >
                    <Avatar size="sm" {...stringAvatar(quote.qcAssignedTo.firstName+" "+quote.qcAssignedTo.lastName)}></Avatar>
                  </Badge>
                   </Tooltip>
                     
                     </div>
             ) :""
          }  

{
                quote.status==="TODO" && localStorage.getItem("role") !=="ROLE_USER"?(
      <Tooltip title={"Create a copy of "+ quote.id}>
        <IconButton>
<LuCopyPlus size={"17px"} onClick={() => addCopyOfTask(quote.id)}/>
</IconButton>
</Tooltip>
                ):""
}
{
  (quote.status==="DONE" && quote.ratingsCount === 1 && quote.assignedTo && quote.qcAssignedTo && quote.qcAssignedTo.id!==quote.assignedTo.id) ?
  (
    <>
<FaStarHalfAlt size={"17px"} color="orange"  />
</>
  ):((quote.status==="DONE" && quote.ratingsCount === 2 && quote.assignedTo && quote.qcAssignedTo && quote.qcAssignedTo.id!==quote.assignedTo.id) || 
  ((quote.status==="DONE" && quote.ratingsCount === 1 && quote.assignedTo && quote.qcAssignedTo && quote.qcAssignedTo.id===quote.assignedTo.id)))?
  (
    <>
<FaStar size={"17px"} color="orange" /> 
</>
  ):quote.status==="DONE" && quote.ratingsCount === 0 ?
  (
    <>
<FaRegStar size={"17px"} color="orange"/> 
</>
  ):""
}
  

</Stack>
            </CardContent>
            
          </Card>
      {/* {isClone ? <CloneBadge>Clone</CloneBadge> : null}
      <Content>
        <Grid container>
            <Grid item md={5} style={{textDecoration:'none'}}>
            {quote.title}
            <br></br>
            {quote.shortDescription}
            </Grid>
            <Grid item md={3}>
            <IconButton onClick={()=>setOpen(true)}>
              <MoreHorizIcon/>
            </IconButton>
            </Grid>
        </Grid>
       
        
        <Footer>
          <Author colors={"white"}>{quote.title}</Author>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="grey" class="w-5 h-5">
  <path fill-rule="evenodd" d="M10 3c-4.31 0-8 3.033-8 7 0 2.024.978 3.825 2.499 5.085a3.478 3.478 0 0 1-.522 1.756.75.75 0 0 0 .584 1.143 5.976 5.976 0 0 0 3.936-1.108c.487.082.99.124 1.503.124 4.31 0 8-3.033 8-7s-3.69-7-8-7Zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-2-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" clip-rule="evenodd" />
</svg>2
&nbsp;&nbsp;&nbsp;

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="grey" class="w-5 h-5">
  <path fill-rule="evenodd" d="M15.621 4.379a3 3 0 0 0-4.242 0l-7 7a3 3 0 0 0 4.241 4.243h.001l.497-.5a.75.75 0 0 1 1.064 1.057l-.498.501-.002.002a4.5 4.5 0 0 1-6.364-6.364l7-7a4.5 4.5 0 0 1 6.368 6.36l-3.455 3.553A2.625 2.625 0 1 1 9.52 9.52l3.45-3.451a.75.75 0 1 1 1.061 1.06l-3.45 3.451a1.125 1.125 0 0 0 1.587 1.595l3.454-3.553a3 3 0 0 0 0-4.242Z" clip-rule="evenodd" />
</svg>4


          <QuoteId>
            id:
            {quote.id}
          </QuoteId>
          
        </Footer>
      </Content>
       */}
       {/* <Popup
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      /> */}

<BootstrapDialog
        onClose={toggleViewTaskModal}
        aria-labelledby="customized-dialog-title"
        onBackdropClick={"false"}
        open={open}
      >

        <Popup viewTaskModal={toggleViewTaskModal} taskId={selectedValue}/>

      </BootstrapDialog>


      <BootstrapCopyDialog
        onClose={toggleCopyTaskModal}
        aria-labelledby="customized-dialog-title"
        open={copyShow}
      >
        {/*<BootstrapDialogTitle id="customized-dialog-title" className="toolHeader" style={{ textAlign: 'center', backgroundImage:'url(https://t4.ftcdn.net/jpg/02/76/08/07/360_F_276080724_hltnCyDjcqAyRtLzDYo3T2jXbBtCD7fl.jpg)', color: 'white' }}>
        ADD MEMBER
    </BootstrapDialogTitle>*/}
        <DialogContent>
        <Typography>Do you want to create a copy of task "{selectedTaskId}"</Typography>
        </DialogContent>
        <DialogActions>
        <Button variant='soft' onClick={createCopy}>Yes create it</Button>
        <Button variant='outlined' onClick={toggleCopyTaskModal}>Close</Button>
        </DialogActions>
      </BootstrapCopyDialog>
    </Container>
  );
}

export default React.memo(QuoteItem);
