import { ArrowRightAlt, ContentCopyRounded } from '@mui/icons-material';
import { Button, Chip } from '@mui/joy';
import {
    ListItemText, Paper, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';

export const PendingRatingTasks = ({ togglePendingRatingTasksModal, data }) => {
    const copyToClipboard = () => {
        let ids = data.map(e => e.taskId);
        const textToCopy = ids;
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                console.log('Text copied to clipboard');
                toast.success("Task IDs copied")
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };


    return (
        <>
            <TableContainer component={Paper} style={{ maxHeight: '380px', minHeight: '380px' }}>
                <Table stickyHeader>
                    <TableHead sx={{ backgroundColor: '#262673', color: 'white' }}> 
                        <TableRow sx={{ backgroundColor: '#262673 !important', color: 'white' }}>
                            <TableCell sx={{ backgroundColor: '#262673 !important', color: 'white' }}><Typography fontWeight="bold" color={"white"}>Task ID</Typography></TableCell>
                            <TableCell sx={{ backgroundColor: '#262673 !important', color: 'white' }}><Typography fontWeight="bold" color={"white"} style={{ width: '300px' }}>Title</Typography></TableCell>
                            <TableCell sx={{ backgroundColor: '#262673 !important', color: 'white' }}><Typography fontWeight="bold" color={"white"}>Assigned To</Typography></TableCell>
                            <TableCell sx={{ backgroundColor: '#262673 !important', color: 'white' }}><Typography fontWeight="bold" color={"white"}>QC Assigned To</Typography></TableCell>
                            <TableCell sx={{ backgroundColor: '#262673 !important', color: 'white' }}><Typography fontWeight="bold" color={"white"} sx={{ textAlign: 'center' }}>Completed On</Typography></TableCell>
                            <TableCell sx={{ backgroundColor: '#262673 !important', color: 'white' }}><Typography fontWeight="bold" color={"white"} sx={{ textAlign: 'center' }}>Elapsed Days</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ overflow: 'scroll' }}>
                        {data.map((task) => (
                            <TableRow key={task.taskId}>
                                <TableCell>{<Chip size='sm' sx={{ borderRadius: '2px', backgroundColor: '#262673', color: 'white' }}>{task.taskId}</Chip>}</TableCell>
                                <TableCell>{task.title}</TableCell>
                                <TableCell>
                                    <ListItemText primaryTypographyProps={{
                                        color: '#262673', fontSize: '0.8rem'
                                    }} secondaryTypographyProps={{
                                        fontSize: '0.7rem'
                                    }} primary={`${task.assignedTo.firstName} ${task.assignedTo.lastName}`}
                                        secondary={task.assignedTo.id}></ListItemText>
                                </TableCell>
                                <TableCell>
                                    <ListItemText primaryTypographyProps={{
                                        color: '#262673', fontSize: '0.8rem'
                                    }} secondaryTypographyProps={{
                                        fontSize: '0.7rem'
                                    }} primary={`${task.qcAssignedTo.firstName} ${task.qcAssignedTo.lastName}`}
                                        secondary={task.qcAssignedTo.id}></ListItemText>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{moment(task.completedOn).format("DD-MM-YYYY")}</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>{task.elapsedDays}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br></br>&nbsp;&nbsp;
            <Button variant={"solid"} onClick={togglePendingRatingTasksModal} style={{ float: 'right' }} color='danger' size='sm' endDecorator={<ArrowRightAlt />}>CONTINUE</Button>&nbsp;&nbsp;
            <Button variant={"solid"} style={{ float: 'right', marginRight: '10px' }} size='sm' startDecorator={<ContentCopyRounded />} onClick={copyToClipboard}>COPY TASK IDs</Button> &nbsp;&nbsp;
        </>
    )
}
