import { Avatar } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import { Chip, Divider, FormControl, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import * as React from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { addNewHolidayByAdmin, getAllLeaveType, logout, stringAvatar } from '../../../service/service-call';
//import { addNewProject } from '../util/apiCalls';

export default function AddHoliday({ addHolidayModal }) {
    const [allLeaveTypes, setAllLeaveTypes] = React.useState([]);
    const [selectedLeaveType, setSelectedLeaveType] = React.useState("");
    const [allMembers, setAllMembers] = React.useState([]);
    const [holidayName, setHolidayName] = React.useState('');
    const [sdate, setSDate] = React.useState(new Date());
    const [edate, setEDate] = React.useState(new Date());
    const [description, setDescription] = React.useState('');
    const [projectId, setProjectId] = React.useState('');
    const [selectedOption, setSelectedOption] = React.useState("");
    const [selectedTeams, setSelectedTeams] = React.useState([]);
    const [selectedTeamIds, setSelectedTeamIds] = React.useState([]);
    const [tags, setTags] = React.useState([]);
    const [tagError, setTagError] = React.useState("");

    React.useEffect(() => {
        getAllLeaveTypes();
    }, []);

    function getAllLeaveTypes() {
        getAllLeaveType("").then(resp => {
            if (resp.status === 401) {
                logout();
            }
            resp.json().then(data => {
                setAllLeaveTypes(data);

            });
        }).catch(error => {
            console.log("login user err " + error);
        });
    }


    const handleSDateChange = (e) => {

        setSDate(moment(e).format("YYYY-MM-DD"));
    }

    const handleEDateChange = (e) => {

        setEDate(moment(e).format("YYYY-MM-DD"));
    }

    const handleHolidayNameChange = (e) => {
        setHolidayName(e.target.value);
    };

    const handleProjectDescChange = (e) => {
        setDescription(e.target.value);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    function getDates(startDate, endDate) {
        const dates = [];
        let currentDate = moment(startDate);
        let diff = moment(endDate).diff(currentDate, 'days');
        while (diff > -1) {
            dates.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'days');
            diff--;
        }

        return dates;
    }

    function addNewHoliday() {

        if (!selectedLeaveType) {
            toast.error('Fields cannot be blank');
            return false;
        }
        let arr = [];
        let selectedLeaveTypeId = allLeaveTypes.find(e => e.name === selectedLeaveType).id;

        // let arr = [selectedTeamIds];

        if (holidayName === "" || holidayName === undefined || selectedLeaveTypeId === "" || selectedLeaveTypeId === undefined) {
            toast.error('Fields cannot be blank');
            return false;
        } else if (sdate === "" || sdate === undefined || !sdate || edate === "" || edate === undefined || !edate) {
            toast.error('Dates cannot be blank');
            return false;
        } else {
            addNewHolidayByAdmin(holidayName, getDates(sdate, edate), selectedLeaveTypeId).then(resp => {
                toast.success('Holiday added successfully');
                addHolidayModal();
            }).catch(error => {
                if (error && error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                    toast.error(error.response.data.errors[0]);
                } else {
                    toast.error("Internal server error, contact support team");
                }

            })
        }
    }

    const CustomPaper = (props) => {
        return <Paper elevation={8} sx={{ fontSize: '0.8rem !important' }} {...props} />;
    };
    return (
        <React.Fragment>
            <DialogContent>
                <Grid container >
                    <Box sx={{ flexGrow: 1, padding: '10px' }}>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                textAlign: 'center',
                                height: 50,
                                pl: 2,
                                bgcolor: 'background.default',
                            }}
                        >
                            <div style={{ textAlign: 'center', display: 'inline-flex' }} ><FaCalendarAlt size={"30px"} color='#0B6BCB' /></div>
                            <Typography style={{ textAlign: 'center',fontSize: 14, fontWeight: 'bold', color: '#0B6BCB' }} class="font-bold">{"ADD HOLIDAY"}</Typography>
                        </Paper><br></br>
                        <Divider /><br></br>



                        <Autocomplete
                            id="tags-filled"
                            fullWidth
                            options={allLeaveTypes.map(option => option.name)}
                            value={selectedLeaveType}
                            PaperComponent={CustomPaper}
                            onChange={(event, newValue) => {
                                setSelectedLeaveType(
                                    newValue);
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip avatar={<Avatar size="lg" color='danger' {...stringAvatar(option)} />} variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    size='small'
                                    InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                    InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                    label="Select Leave Type"
                                />
                            )}
                        />
                        <br></br>
                        <FormControl required={true} size="small" fullWidth variant="standard" style={{ textAlign: 'center' }}>
                            <TextField
                                id="standard-adornment-holidayName"
                                label="Holiday Name"
                                size="small"
                                InputProps={{ style: { fontSize: "0.8rem" } }}
                                    InputLabelProps={{style: { fontSize: "0.8rem" } }}
                                    
                                type={'text'}
                                value={holidayName}
                                onChange={handleHolidayNameChange}
                            />
                        </FormControl>
                        <br></br><br></br>
                        <LocalizationProvider size="small" fullWidth dateAdapter={AdapterDateFns} >
                            <DesktopDatePicker
                                label="Start Date"
                                fullWidth
                                size="small"
                                value={sdate}
                                defaultValue={null}
                                //disableFuture="true"
                                onChange={handleSDateChange}
                                renderInput={(params) => <TextField size="small" fullWidth
                                     {...params} 
                                     InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                    InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                    
                                     />}
                            /></LocalizationProvider>

                        <br></br><br></br>
                        <LocalizationProvider size="small" fullWidth dateAdapter={AdapterDateFns} >
                            <DesktopDatePicker
                                label="End Date"
                                fullWidth
                                size="small"
                                value={edate}
                                defaultValue={null}
                                //disableFuture="true"
                                onChange={handleEDateChange}
                                renderInput={(params) => <TextField size="small" 
                                    fullWidth {...params} 
                                    InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                    InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                    
                                    />}
                            /></LocalizationProvider>
                    </Box>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={addNewHoliday}>&nbsp;SUBMIT</Button>
            </DialogActions>
        </React.Fragment>
    );
}