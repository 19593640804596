import { Button } from '@mui/joy';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { ClientJS } from 'clientjs';
import { toast } from 'react-toastify';
import { forcelogoutUser } from '../service/service-call';
//import { addNewMember } from '../util/apiCalls';

export default function ForceLogout({ toggleForceLogoutModal, errorMsg }) {

    const [message, setMessage] = React.useState('');

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const submitForceLogout = () =>{
        if(message===null || message === undefined || message === ""){
            toast.error('Please give a reason for Force logout');
            return false;
        }
        navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            const client = new ClientJS();
           // console.log(client.getOS(), client.getBrowserData().browser.name, client.getFingerprint(), latitude, longitude);
            forcelogoutUser(client.getOS(), client.getBrowserData().browser.name, client.getFingerprint(), latitude, longitude, message).then(resp => {
          
              if (resp.status === 401) {
                toast.error('Unauthozied entry!');
              } else {
                toggleForceLogoutModal();
                localStorage.removeItem("firstname");
                localStorage.removeItem("lastname");
                localStorage.removeItem("email");
                localStorage.removeItem("userId");
                localStorage.removeItem("token");
                localStorage.removeItem("role");
                localStorage.removeItem("isTL");
                toast.success("Logout successfully!");
                window.location.replace("/")
              }
            }).catch(error => {
              console.log("login user err ",error.response);
              if(error.response.status===400){
                toast.error(error.response.data.message);
              }
                   
            })
        });
    }


    return (
        <React.Fragment>
            <DialogContent>
                <Grid container >


                    <Box sx={{ flexGrow: 1}}>
                    
                    <div className="blinking-text">
                    WARNING: {errorMsg}
                    </div>
                    <br></br>
                        <Typography>Still want to logout, Please give a reason:</Typography>
                        <TextField
                            multiline
                            rows={3}
                            type="text"
                            fullWidth
                            inputRef={input => input && input.focus()}
                            variant="outlined"
                            size='small'
                            value={message}
                            
                            inputProps={{
                                maxlength: 200
                            }}
                            helperText={`${message.length}/${200}`}
                            onChange={handleMessageChange}
                        /><br></br><br></br>
                        <Button variant='solid' color={"danger"} onClick={submitForceLogout}> Force Logout</Button>
                        &nbsp;<Button variant='outlined' onClick={() => toggleForceLogoutModal()}> Close to exit</Button>
                    </Box>





                </Grid>

            </DialogContent>
        </React.Fragment>
    );
}