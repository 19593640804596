import React from "react";
import styledx from "@xstyled/styled-components";
import { grid, borderRadius } from "../styles/constants";
import { Draggable } from "react-beautiful-dnd";
import QuoteList from "../styles/list";
import Title from "../styles/title";
import EditIcon from '@mui/icons-material/Edit';

import { styled, withStyles } from '@mui/material/styles';
import { IconButton, SpeedDial, Tooltip } from "@mui/material";
import { HiDocumentPlus } from "react-icons/hi2";
import { BsFillPlusSquareFill } from "react-icons/bs";
import TaskAdd from "../../Components/Task/taskAdd";
import { Dialog, FormControl, TextField } from "@mui/material";
import { getAllTasks } from "../../service/service-call";
import { MdOutlinePostAdd } from "react-icons/md";
const Container = styledx.div`
  margin: ${grid}px;
  display: flex;
  flex-direction: column;
  background-color: aliceblue;
  border : 1px SOLID lightgrey;
  box-shadow:10px;
  border-radius: 10px;
  min-height: 450px;
  max-height: 450px;
  
  &:hover {
    // transition: transform .5s;
    // transform: scale(1.05);
    //background-color: #DFE1E6;
    
    //color: #262673;
  }
`;

const Header = styledx.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;
  
  transition: background-color 0.2s ease;
`;

const Column = (props) => {
  const title = props.title;
  const quotes = props.quotes;
  const count = props.count;
  const index = props.index;
  const getAllTasksForUser  = props.getAllTasksForUser;
  const [isAddTaskOpen, setIsAddTaskOpen] = React.useState(false);

  const toggleAddTaskModal = () => {
    localStorage.setItem("isPopUpOpen", "true");
    setIsAddTaskOpen(!isAddTaskOpen);
    if (isAddTaskOpen === true) {
      console.log("clossi");
      getAllTasksForUser();
      localStorage.setItem("isPopUpOpen", "false");
    }
  }


  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      maxWidth: '800px',
      height: 'auto'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  return (
    <>
    <Draggable draggableId={title} index={index}>
      {(provided, snapshot) => (
        <Container ref={provided.innerRef} {...provided.draggableProps}>
          <Header isDragging={snapshot.isDragging}>
            <Title
              isDragging={snapshot.isDragging}
              {...provided.dragHandleProps}
              aria-label={`${title} quote list`}
            >
              {title}{" ("+count+")"}
            </Title>
            {
                title==="TODO" && localStorage.getItem("role") !=="ROLE_USER"?(
            <Tooltip title={"Add new task"} arrow> 
            <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        sx={{ position: 'fixed', bottom: 20, right: 20}}
        icon={<MdOutlinePostAdd size={"25px"}/>}
        color='success'
        onClick={toggleAddTaskModal}
        className='hover:-translate-y-1 hover:scale-110 duration-300'
      >
      </SpeedDial>
            </Tooltip>
              ):""
            }
          </Header>
          <QuoteList
            listId={title}
            listType="QUOTE"
            style={{ 
              height:"420px"
            }}
            getAllTasksForUser={getAllTasksForUser}
            quotes={quotes}
            title={title}
            count={count}
            internalScroll={props.isScrollable}
            isCombineEnabled={Boolean(props.isCombineEnabled)}
            useClone={Boolean(props.useClone)}
          />
        </Container>
      )}
    </Draggable>

<BootstrapDialog
onClose={toggleAddTaskModal}
aria-labelledby="customized-dialog-title"
open={isAddTaskOpen}
>
{/*<BootstrapDialogTitle id="customized-dialog-title" className="toolHeader" style={{ textAlign: 'center', backgroundImage:'url(https://t4.ftcdn.net/jpg/02/76/08/07/360_F_276080724_hltnCyDjcqAyRtLzDYo3T2jXbBtCD7fl.jpg)', color: 'white' }}>
ADD MEMBER
</BootstrapDialogTitle>*/}

<TaskAdd addTasksModal={toggleAddTaskModal} />

</BootstrapDialog>
</>
  );
};

export default Column;
