import React from "react";
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { getAllLeaves, getAvailableLeaves, getDaysInMonth, logout } from "../../../service/service-call";
import { Chip, Grid, Tooltip } from "@mui/joy";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ListItemText, TextField } from "@mui/material";
import moment from "moment";

export const MonthLeave = () => {
  const [data, setData] = React.useState([]);
  const [availableLeaves, setAvailableLeaves] = React.useState([]);
  const [daysCount, setDays] = React.useState('');
  const [month, setMonth] = React.useState('');
  const [showAvailableLeave, setShowAvailableLeave] = React.useState(false);
  const [showMonthlyReport, setShowMonthlyReport] = React.useState(true);
  const [btnName, setBtnName] = React.useState('AVAILABLE LEAVES');
  React.useEffect(() => {
    getAllHolidays();
    getAllAvailableLeaves();
  }, []);

  React.useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); 
    const formattedMonth = `${year}-${month}`;
    setMonth(formattedMonth);
  }, []);

  React.useEffect(() => {
    setDays(getDaysInMonth(month));
  }, [month]);

  function getAllHolidays(monthValue) {
    if(monthValue === "Invalid date"){
      return false;
    }
    getAllLeaves(monthValue)
      .then((resp) => {
        if (resp.status === 401) {
          logout();
        }
        resp.json().then((data) => {
          setData(data);
        });
      })
      .catch((error) => {
        console.log("Error fetching leaves: " + error);
      });
  }

  function getAllAvailableLeaves() {
    getAvailableLeaves()
      .then((resp) => {
        if (resp.status === 401) {
          logout();
        }
        resp.json().then((data) => {

          const result = [];

          Object.entries(data).forEach(([teamName, members]) => {
            members.forEach((member) => {
              result.push({
                teamName,
                memberId: member.member.id,
                memberName: `${member.member.firstName} ${member.member.lastName}`,
                availableLeaves: member.availableLeaves,
                availablePermissionsThisMonth: member.availablePermissionsThisMonth,
                availableCompensationLeaves: member.availableCompensationLeaves,
              });
            });
          });


          setAvailableLeaves(result);
        });
      })
      .catch((error) => {
        console.log("Error fetching leaves: " + error);
      });
  }

  const clickAvailableLeaves = (name) => {

    if(name==="MONTHLY REPORT") {
      setShowMonthlyReport(true);
      setShowAvailableLeave(false);
      setBtnName("AVAILABLE LEAVES");
    } else {
      setShowAvailableLeave(true);
      setShowMonthlyReport(false);
      setBtnName("MONTHLY REPORT");
    }
    
  }

  const onMonthChange = (e) => {
    console.log(moment(e).format("YYYY-MM"))
    if(moment(e).format("YYYY-MM")!=="Invalid date") {
      setMonth(moment(e).format("YYYY-MM"));
      getAllHolidays(moment(e).format("YYYY-MM"));
    }
    
  }

  const days = Array.from({ length: daysCount }, (_, index) => index + 1);

  // Helper function to find leave type for a given day and return a readable string
  const getLeaveInfo = (leaveDays, day) => {
    for (const [leaveCategory, leaves] of Object.entries(leaveDays)) {
      const leave = leaves.find((leave) => leave.day === day);
      if (leave) {
        let typeDescription = "";
        switch (leaveCategory) {
          case "permission":
            typeDescription = "Permission";
            break;
          case "compOff":
            typeDescription = "Compensatory Off";
            break;
          case "monthLeave":
            typeDescription = "Monthly Leave"
            break;
          case "lop":
            typeDescription = "Loss of Pay";
            break;
          default:
            typeDescription = leave.type;
        }
        return { type: leave.type, description: typeDescription };
      }
    }
    return null;
  };

  return (
      <Grid container>
        <Grid item md={12}>
        <Button size="sm" variant="soft" style={{float:'right', marginTop:'-5px'}} onClick={() => clickAvailableLeaves(btnName)}>{btnName}</Button>
{
  showMonthlyReport ? 
  <>
   <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important', marginTop:'2px' }}>
                                
    <DesktopDatePicker 
    views={['month', 'year']}
    label="Choose Month"
    fullWidth
    value={month} 
    onChange={onMonthChange} 
    disableFuture="true"
    minutesStep={30}
    slotProps={{ field: { clearable: true } }}
    renderInput={(params) => <TextField {...params}
        InputProps={{ ...params.InputProps, size: "small", style: { fontSize: "0.8rem"} }}
        InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
        size='small'
    //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
    />}
/>
    </LocalizationProvider>
      <Sheet
        variant="outlined"
        sx={{
          borderRadius:'10px',
          '--TableCell-height': '40px',
          '--TableHeader-height': 'calc(1 * var(--TableCell-height))',
          '--Table-firstColumnWidth': '150px',
          '--Table-lastColumnWidth': '84px',
          '--TableRow-stripeBackground': 'rgba(0, 0, 0, 0.04)',
          '--TableRow-hoverBackground': 'rgba(0, 0, 0, 0.08)',
          overflowX: 'auto',  // Horizontal scrolling
          overflowY: 'auto',  // Vertical scrolling
          height: '360px',  // Fixed height
          width: '900px',
          scrollbarWidth: "thin",
          '&::-webkit-scrollbar': {
          display: 'none',
        },
         
        }}
      >
        <div style={{ maxHeight: '350px', overflowY: 'auto', scrollbarWidth:'thin', textAlign:'center' }}>
          
          <Table
            borderAxis="bothBetween"
            stripe="odd"
            hoverRow
            sx={{
              '& thead th': {
                position: 'sticky',
                top: 0,
                background: 'white',
                backgroundColor:'aliceblue',
                color:'#262673',
                fontWeight:'700',
                zIndex: 1,
              },
              '& tbody tr > *:nth-of-type(2)': {
                position: 'sticky',
                left: 0,
                boxShadow: '1px 0 var(--TableCell-borderColor)',
                bgcolor: 'background.surface',
                backgroundColor:'aliceblue',
              },
              '& tbody tr > *:nth-of-type(-n+1)': {
                position: 'sticky',
                left: 0,
                boxShadow: '1px 0 var(--TableCell-borderColor)',
                bgcolor: 'background.surface',
                backgroundColor:'aliceblue',
                zIndex: 1,
              },
              '& tbody tr > *:nth-last-of-type(-n+5)': {
                position: 'sticky',
                right: 0,
                bgcolor: 'var(--TableCell-headBackground)',
                backgroundColor:'aliceblue',
                zIndex: 1,
              },
            }}
          >
            <thead style={{width:'700px', overflow:'auto'}}>
              <tr >
                <th style={{ width: 'var(--Table-firstColumnWidth)', textAlign:'start' }}>Employee</th>
                <th style={{ width: "100px", textAlign:'start' }}>Team</th>
                {days.map((day) => (
                  <th key={day} style={{ width: "45px", textAlign:'center' }}>
                    {day}
                  </th>
                ))}
                <th style={{ width: "100px", textAlign:'center' }}>LOP</th>
                <th style={{ width: "100px", textAlign:'center' }}>Comp Off</th>
                <th style={{ width: "100px", textAlign:'center' }}>Permission</th>
                <th style={{ width: "140px", textAlign:'center' }}>Monthly Leave</th>
                <th style={{ width: "140px", textAlign:'center' }}>Total</th>
              </tr>
            </thead>
            <tbody>
              {data.map((employee) => (
                <tr key={employee.id}>
                  <td style={{color:'#262673', textAlign:'start'}}>

                  <ListItemText primaryTypographyProps={{
                   color: '#262673', fontSize: '0.8rem'
                  }} secondaryTypographyProps={{
                    fontSize: '0.7rem'
                   }} primary={employee.memberName}
                  secondary={employee.memberId}></ListItemText>


                    
                    </td>
                  <td style={{textAlign:'start',fontSize: '0.8rem'}}>{employee.teamName}</td>
                  {days.map((day) => {
                    const leaveInfo = getLeaveInfo(employee.leaveDays, day);
                    return (
                      <td key={day} align="center" style={{ minWidth: "50px"}}>
                        {leaveInfo ? (
                          <Tooltip title={`${leaveInfo.description} ${leaveInfo.type==="FD"?"(Full Day)" : leaveInfo.type==="HD"?"(Half Day)":""}`}>
                            
                            {
                              leaveInfo.description==="Permission" ? 
                              <Chip size="sm" variant="solid" sx={{backgroundColor:'orange'}}>{leaveInfo.type}</Chip>
                              : leaveInfo.description==="Compensatory Off" ? 
                              <Chip size="sm" variant="soft" color="neutral">{leaveInfo.type}</Chip>
                              : leaveInfo.description==="Loss of Pay" ? 
                              <Chip size="sm" variant="solid" color="danger">{leaveInfo.type}</Chip>
                              : leaveInfo.description==="Monthly Leave" ? 
                              <Chip size="sm" variant="soft" color="primary">{leaveInfo.type}</Chip>
                              : <Chip size="sm" variant="soft" color="primary">{leaveInfo.type}</Chip>
                            }
                            
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </td>
                    );
                  })}
                  <td style={{textAlign:'center'}}>{employee.lop}</td>
                  <td style={{textAlign:'center'}}>{employee.compOff}</td>
                  <td style={{textAlign:'center'}}>{employee.perm}</td>
                  <td style={{textAlign:'center'}}>{employee.monthlyLeave}</td>
                  <td style={{textAlign:'center',color:'#262673',
                fontWeight:'700'}}>{employee.total}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Sheet>
  </> : ""
}

{
  showAvailableLeave ? 
  <>
   
   <Sheet
      variant="outlined"
      sx={{
        borderRadius: "10px",
        "--TableCell-height": "40px",
        "--TableHeader-height": "calc(1 * var(--TableCell-height))",
        "--Table-firstColumnWidth": "150px",
        "--Table-lastColumnWidth": "84px",
        "--TableRow-stripeBackground": "rgba(0, 0, 0, 0.04)",
        "--TableRow-hoverBackground": "rgba(0, 0, 0, 0.08)",
        overflowX: "auto", // Horizontal scrolling
        overflowY: "auto", // Vertical scrolling
        height: "360px", // Fixed height
        width: "900px",
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <div
        style={{
          maxHeight: "350px",
          overflowY: "auto",
          scrollbarWidth: "thin",
          textAlign: "center",
        }}
      >
        <Table
          borderAxis="bothBetween"
          stripe="odd"
          hoverRow
          sx={{
            "& thead th": {
              position: "sticky",
              top: 0,
              background: "white",
              backgroundColor: "aliceblue",
              color: "#262673",
              fontWeight: "700",
              zIndex: 1,
            },
            "& tbody tr > *:nth-of-type(-n)": {
              position: "sticky",
              left: 0,
              boxShadow: "1px 0 var(--TableCell-borderColor)",
              bgcolor: "background.surface",
              backgroundColor: "aliceblue",
              zIndex: 1,
            },
          }}
        >
          <thead style={{ width: "700px", overflow: "auto" }}>
            <tr>
              <th
                style={{
                  width: "var(--Table-firstColumnWidth)",
                  textAlign: "start",
                }}
              >
                Employee
              </th>
              <th style={{ width: "100px", textAlign: "start" }}>Team</th>

              <th style={{ width: "100px", textAlign: "center" }}>
                Available Leaves
              </th>
              <th style={{ width: "100px", textAlign: "center" }}>
                Available Comp Off
              </th>
              <th style={{ width: "100px", textAlign: "center" }}>
                Available Permission
              </th>
            </tr>
          </thead>
          <tbody>
            {availableLeaves.map((employee) => (
              <tr key={employee.memberId}>
                <td style={{ color: "#262673", textAlign:'start' }}>
                <ListItemText primaryTypographyProps={{
                   color: '#262673', fontSize: '0.8rem'
                  }} secondaryTypographyProps={{
                    fontSize: '0.7rem'
                   }} primary={employee.memberName}
                  secondary={employee.memberId}></ListItemText>
                </td>
                <td style={{ textAlign:'start' }}>{employee.teamName}</td>

                <td style={{ textAlign: "center" }}>{employee.availableLeaves}</td>
                <td style={{ textAlign: "center" }}>{employee.availableCompensationLeaves}</td>
                <td style={{ textAlign: "center" }}>{employee.availablePermissionsThisMonth}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Sheet>
  </> : ""
}
        
      </Grid></Grid>
  );
};
