import { DialogContent } from '@mui/material'
import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { getAllActiveMembers, logout, stringAvatar, submitCompOff } from '../../../service/service-call';
import { Paper, TextField, Avatar, Chip , Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Stack} from '@mui/material';
import { DesktopDatePicker, DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { Button } from '@mui/joy';
import { toast } from 'react-toastify';


export const CompOffAdd = ({ toggleCompOffModal }) => {
    const [allMembers, setAllMembers] = React.useState([]);
    const [selectedTeamMembers, setSelectedTeamMembers] = React.useState([]);
    const [sdate, setSDate] = React.useState(null);
    const [edate, setEDate] = React.useState(null);
    const [leaveDayType, setLeaveDayType] = React.useState("");
    const [leaveHalfDayType, setLeaveHalfDayType] = React.useState("");
    const [reason, setReason] = React.useState('');
    React.useEffect(() => {
        getAllMemberForTeam();
    }, []);

    function getAllMemberForTeam() {
        getAllActiveMembers().then(resp => {

            if (resp.status === 401) {
                logout();
            }
            resp.json().then(data => {

                setAllMembers(data);

            });
        }).catch(error => {
            console.log("login user err " + error);
        });
    }

    const handleEDateChange = (e) => {
        setEDate(moment(e).format("YYYY-MM-DD hh:mm A"))
    }

    const handleSDateChange = (e) => {
        setSDate(moment(e).format("YYYY-MM-DD hh:mm A"))
    }

    const handleReasonChange = (e) => {
        setReason(e.target.value);
    };

    const CustomPaper = (props) => {
        return <Paper elevation={8} sx={{fontSize:'0.8rem !important'}} {...props} />;
      };

    
      function getDates(startDate, endDate) {
        const dates = [];
        let currentDate = moment(startDate);
        let diff = moment(endDate).diff(currentDate, 'days');
        while (diff > -1) {
            dates.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'days');
            diff--;
        }

        return dates;
    }
      function createCompOffReaquest() {
        let arr = [];

        if (leaveDayType === "FULL_DAY" && (!sdate || !edate)) {
            toast.error('Select date');
            return false;
        }

        if (reason === "" || reason === undefined) {
            toast.error('Fields cannot be blank');
            return false;
        } else {
            let dateRange = [];
            dateRange = getDates(sdate, edate)

            if(dateRange.length===0){
                toast.error('Date cannot be blank');
                return false;
            }

            let arr = [];
            selectedTeamMembers.map(e => {
                let idCode = e.split("(");
                let val = idCode[1].split(")");
                if (!arr.includes(val[0].trim()))
                    arr.push(val[0].trim());
            });

            submitCompOff(leaveDayType, reason, dateRange, arr).then(resp => {
                
                setLeaveDayType("");
                setReason("");
                setSDate(null);
                setEDate(null);
                toast.success('Comp Off added successfully');
                toggleCompOffModal();
            }).catch(error => {
                if (error && error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                    toast.error(error.response.data.errors[0]);
                } else {
                    toast.error("Internal server error, contact support team");
                }

            })
        }
    }

  return (
    <>

    <DialogContent>
        
<Autocomplete
                            multiple
                            id="tags-filled"
                            fullWidth
                            PaperComponent={CustomPaper}
                            size='small'
                            options={allMembers.map((option) => option.firstName + " " + option.lastName + " (" + option.id + ")")}
                            value={selectedTeamMembers}
                            onChange={(event, newValue) => {
                                setSelectedTeamMembers(
                                    newValue);
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip avatar={<Avatar size="lg" color='danger' {...stringAvatar(option)} />} variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size='small'
                                    InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                    InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                    variant="outlined"
                                    label="Add Team Members"
                                    placeholder="Add Team Members"
                                />
                            )}
                        />
<br></br>
                        
<FormControl required={true} size="small" 
                                fullWidth variant="standard" style={{ textAlign: 'center'}}>
                            <TextField
                                id="standard-adornment-reason"
                                label="Reason"
                                size="small"
                                multiline
                                fullWidth
                                minRows={3}
                                maxRows={5}
                                inputProps={{
                                    maxlength: 200,
                                    style:{
                                        fontSize:'0.8rem'
                                    }
                                }}
                                helperText={`${reason.length}/${200}`}
                                type={'text'}
                                value={reason}
                                onChange={handleReasonChange}
                            />
                        </FormControl>
<LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '40% !important' }}>
                                <DesktopDatePicker

                                    label="Start date"
                                    fullWidth
                                   // minDate={moment().subtract(15, "days").format("YYYY-MM-DD")}
                                    value={moment(sdate).format("YYYY-MM-DD")}
                                    disableFuture="true"
                                    onChange={handleSDateChange}
                                    minutesStep={30}
                                    slotProps={{ field: { clearable: true } }}
                                    renderInput={(params) => <TextField {...params}
                                    margin='normal'
                                    InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                    InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                   
                                        size='small'
                                    //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                                    />}
                                /></LocalizationProvider>
&nbsp;&nbsp;&nbsp;&nbsp;
                            <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '40% !important' }}>
                                <DesktopDatePicker

                                    label="End date"
                                    fullWidth
                                    minDate={sdate}
                                    value={moment(edate).format("YYYY-MM-DD")}
                                    disableFuture="true"
                                    onChange={handleEDateChange}
                                    minutesStep={30}
                                    slotProps={{ field: { clearable: true } }}
                                    renderInput={(params) => <TextField {...params}
                                    margin='normal'
                                    InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                                    InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                                   
                                        size='small'
                                    //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                                    />}
                                /></LocalizationProvider>
    <br></br><br></br>
    

<FormControl fullWidth variant="standard" sx={{fontSize:'0.8rem'}}>
                                <FormLabel id="demo-radio-buttons-group-label" sx={{fontSize:'0.8rem'}}>Leave Day Type:</FormLabel>
                                <Stack direction={"row"}>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        value={leaveDayType}
                                        sx={{fontSize:'0.8rem'}}
                                        onChange={(e) => setLeaveDayType(e.target.value)}
                                    >
                                        <FormControlLabel value="FULL_DAY" sx={{fontSize:'0.8rem'}} control={<Radio />} label="&nbsp;&nbsp;Full Day" />
                                        <FormControlLabel value="HALF_DAY" sx={{fontSize:'0.8rem'}} control={<Radio />} label="&nbsp;&nbsp;Half Day" />

                                    </RadioGroup>
                                </Stack>
                            </FormControl>

                            
                                <br></br> 
                        <Button variant={"soft"} size='sm' onClick={createCompOffReaquest}>SUBMIT</Button>
    
   
   </DialogContent>
    </>
  )
}
