import TimelineIcon from '@mui/icons-material/Timeline';
import { Box, Button, Card, Chip, Divider, Grid, IconButton, Link, ListItem, ListItemContent, ListItemDecorator, Radio, RadioGroup, Sheet, Slider, Tooltip, Typography } from '@mui/joy';
import { Autocomplete, Chip as Chipd, Dialog, DialogActions, DialogContent, MenuItem, Paper, Popover, Rating, Select, TextField, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MdDelete, MdOutlineViewTimeline } from "react-icons/md";

import { LuFileSearch, LuUser } from "react-icons/lu";


import { MdOutlineDoneOutline } from "react-icons/md";


import PanToolIcon from '@mui/icons-material/PanTool';



import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import Avatar from '@mui/joy/Avatar';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Stack from '@mui/joy/Stack';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { convertToHTML } from 'draft-convert';
import { useRef } from 'react';
import { AiOutlineFileSearch } from "react-icons/ai";
import { BsListTask } from "react-icons/bs";
import { CiCircleRemove } from "react-icons/ci";
import { FaPauseCircle, FaRegComments } from "react-icons/fa";
import { GrAttachment, GrInProgress } from "react-icons/gr";
import { ImParagraphLeft } from "react-icons/im";
import { IoCheckmarkDoneCircleOutline, IoPricetagsOutline } from "react-icons/io5";
import { LuAlarmClock, LuClock, LuDownload, LuListTodo, LuUsers } from "react-icons/lu";
import { MdAccessAlarm, MdDeleteOutline } from "react-icons/md";
import { PiCellSignalFullBold, PiClockBold, PiWifiHighFill } from "react-icons/pi";
import { RiUserSearchLine } from "react-icons/ri";

import { IoIosPricetags } from "react-icons/io";
import { PiWifiLowFill, PiWifiMediumFill } from "react-icons/pi";
import { RxActivityLog } from "react-icons/rx";

import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import link from './../../assets/link.png';
import pdf from './../../assets/pdf.svg';

import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import moment from 'moment';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { VscFeedback } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import { addAttachmnts, addComments, addRating, deleteAttachmentById, deleteCommentById, deleteTask, getAllMembersByProjectIdAndTeams, getAllTagByProjectId, getParticularTaskById, logout, patchUpdateTask, stringAvatar } from '../../service/service-call';
import { DeleteOutline } from '@mui/icons-material';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
}));

function Popup(props) {

  let timelineDataArray = {

    TODO: {
      icon: <LuListTodo size={"20px"} />,
    },
    INPROGRESS: {
      icon: <GrInProgress size={"20px"} />,
    },
    QA: {
      icon: <LuFileSearch size={"20px"} />,
    },
    DONE: {
      icon: <MdOutlineDoneOutline size={"20px"} />,
    },
    HOLD: {
      icon: <PanToolIcon size={"20px"} />,
    },
    DROP: {
      icon: <DoDisturbIcon size={"20px"} />,
    }
  };
  const [deleteTaskShow, setDeleteTaskShow] = useState(false);
  const { viewTaskModal, taskId } = props;
  const [description, setDescription] = useState(null);
  const [commentMsg, setCommentMsg] = useState(null);
  const [projectId, setProjectId] = useState("");
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const [members, setMembers] = useState("");
  const [qcmembers, setQcMembers] = useState("");
  const [taskMembers, setTaskMembers] = useState([]);
  const [qcFeedback, setQcFeedback] = useState("");
  const [sdate, setSDate] = React.useState("");
  const [edate, setEDate] = React.useState("");
  const [qcSdate, setQcSDate] = React.useState("");
  const [qcdate, setQcDate] = React.useState("");
  const [isSdescriptionEditing, setIsSdescriptionEditing] = useState(false);
  const [shortDescription, setShortDescription] = useState("");
  const [allMembers, setAllMembers] = React.useState([]);
  const [allTags, setAllTags] = React.useState([]);
  const [tags, settags] = React.useState([]);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = React.useState();
  const [selectedQCTeamMembers, setSelectedQCTeamMembers] = React.useState();
  const [rateTeamMember, setRateTeamMember] = React.useState("");
  const [loadingIcon, setLoadingIcon] = useState("save");
  const [attachments, setAttachments] = useState([]);
  const [comments, setComments] = useState([]);
  const [activity, setActivity] = useState([]);
  const [priority, setPriority] = useState("");
  const [complexity, setComplexity] = useState("");
  const [complexityReason, setComplexityReason] = useState("");
  const [dueDateReason, setDueDateReason] = useState("");
  const [qcReason, setQcReason] = useState("");
  const [priorityReason, setPriorityReason] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [timeline, setTimeline] = useState([]);
  const [rating, setRating] = useState("");
  const [feedback, setFeedback] = useState("");
  const [allRatings, setAllRatings] = useState([]);
  const [showRatings, setShowRatings] = useState(false);
  const [addRatings, setAddRatings] = useState(true);
  const [showFeedback, setShowFeedback] = useState(false);


  const [anchorMemberEl, setAnchorMemberEl] = React.useState(null);
  const [anchorQCMemberEl, setAnchorQCMemberEl] = React.useState(null);
  const [anchorComplexityEl, setAnchorComplexityEl] = React.useState(null);
  const [anchorDueDateEl, setAnchorDueDateEl] = React.useState(null);
  const [anchorPriorityEl, setAnchorPriorityEl] = React.useState(null);
  const [anchorAttachmentEl, setAnchorAttachmentEl] = React.useState(null);
  const [anchorRatingsEl, setAnchorRatingsEl] = React.useState(null);
  const [anchorTagsEl, setAnchorTagsEl] = React.useState(null);


  const activityRef = useRef(null);
  const commentRef = useRef(null);
  const timelineRef = useRef(null);
  const handleMemberClick = (event) => {
    getAllMemberForTeam(projectId);
    setAnchorMemberEl(event.currentTarget);
  };

  const handleQCMemberClick = (event) => {
    getAllMemberForTeam(projectId);
    setAnchorQCMemberEl(event.currentTarget);
  };

  const handleTagsClick = (event) => {
    getAllTagsForProject(projectId);
    setAnchorTagsEl(event.currentTarget);
  };

  const toggleDeleteTaskModal = () => {
    setDeleteTaskShow(!deleteTaskShow);
  }

  const deleteTaskById = () => {
    deleteTask(taskId).then(resp => {
      toast.success('Task deleted successfully');
      toggleDeleteTaskModal();
      viewTaskModal();
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        toast.error(error.response.data.errors[0]);
      } else {
        toast.error("Internal server error, contact support team");
      }
    })
  }

  const handleTagsClose = () => {
    setAnchorTagsEl(null);
  };

  const handleMemberClose = () => {
    setAnchorMemberEl(null);
  };
  const handleQCMemberClose = () => {
    setAnchorQCMemberEl(null);
  };

  const handleComplexityClose = () => {
    setAnchorComplexityEl(null);
  };

  const handleDueDateClick = (event) => {
    setAnchorDueDateEl(event.currentTarget);
  };

  const handleComplexityClick = (event) => {
    setAnchorComplexityEl(event.currentTarget);
  };

  const handleError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
  }

  const handleDueDateClose = () => {
    setAnchorDueDateEl(null);
  };

  const handlePriorityClick = (event) => {
    setAnchorPriorityEl(event.currentTarget);
  };

  const handlePriorityClose = () => {
    setAnchorPriorityEl(null);
  };

  const handleAttachmentClick = (event) => {
    setAnchorAttachmentEl(event.currentTarget);
  };

  const handleAttachmentClose = () => {
    setAnchorAttachmentEl(null);
  };

  const handleRatingsClick = (event) => {
    setAnchorRatingsEl(event.currentTarget);
  };

  const handleRatingsClose = () => {
    setAnchorRatingsEl(null);
  };

  const openMember = Boolean(anchorMemberEl);
  const idMember = openMember ? 'simple-popover' : undefined;

  const openQCMember = Boolean(anchorQCMemberEl);
  const idQCMember = openQCMember ? 'simple-popover' : undefined;

  const openDueDate = Boolean(anchorDueDateEl);
  const idDueDate = openDueDate ? 'simple-popover' : undefined;

  const openComplexity = Boolean(anchorComplexityEl);
  const idComplexity = openComplexity ? 'simple-popover' : undefined;

  const openPriority = Boolean(anchorPriorityEl);
  const idPriority = openPriority ? 'simple-popover' : undefined;

  const openAttachment = Boolean(anchorAttachmentEl);
  const idAttachment = openAttachment ? 'simple-popover' : undefined;

  const openRatings = Boolean(anchorRatingsEl);
  const idRatings = openRatings ? 'simple-popover' : undefined;

  const openTags = Boolean(anchorTagsEl);
  const idTags = openTags ? 'simple-popover' : undefined;

  useEffect(() => {
    getCurrenttask(taskId);
  }, [taskId]);

  //description
  const _contentState = ContentState.createFromBlockArray(convertFromHTML(description ? description : ""));
  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(_contentState),
  );


  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setDescription(html);
  }, [editorState]);

  //description end

  //description
  //const _commentState = ContentState.createFromText(convertFromHTML(""));
  const [editorCommentState, setEditorCommentState] = useState(
    () => EditorState.createEmpty(),
  );

  useEffect(() => {
    let html = convertToHTML(editorCommentState.getCurrentContent());
    setCommentMsg(html);
  }, [editorCommentState]);
  //description end

  function getAllMemberForTeam(projId) {
    getAllMembersByProjectIdAndTeams(projId.id).then(resp => {
      let data = resp.data;
      setAllMembers(data);

    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  function getAllTagsForProject(projId) {
    getAllTagByProjectId(projId.id).then(resp => {
      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        setAllTags(data);
      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }



  function getCurrenttask(taskId) {
    getParticularTaskById(taskId).then(resp => {
      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        setTitle(data.title);
        setDescription(data.description);
        const _contentState = ContentState.createFromBlockArray(convertFromHTML(data.description));
        setEditorState(EditorState.createWithContent(_contentState))

        setShortDescription(data.shortDescription);
        setProjectId(data.projectId);
        setPriority(data.priority);
        setComplexity(data.complexity);
        setStatus(data.status);
        setQcMembers(data.qcAssignedTo);
        setQcFeedback(data.qcFeedBack);
        setSDate(data.startDate ? moment(data.startDate).format("YYYY-MM-DD hh:mm A") : null);
        setEDate(data.endDate ? moment(data.endDate).format("YYYY-MM-DD hh:mm A") : null);
        setMembers(data.assignedTo);


        let arr = [];
        if (data.assignedTo) {
          arr.push(data.assignedTo);
        }
        if (data.qcAssignedTo && data.assignedTo && data.qcAssignedTo.id !== data.assignedTo.id) {
          arr.push(data.qcAssignedTo);
        }

        setTaskMembers(arr);
        settags(data.tags);
        let tagArr = [];
        data.tags && data.tags.length > 0 && data.tags.map((m, i) => {
          tagArr.push(m.name)
        })
        setSelectedTags(tagArr);
        setComments(data.comments);
        setActivity(data.activity);
        setTimeline(data.taskStatusTrack);
        setAttachments(data.attachments);
        setSelectedTeamMembers(data.assignedTo ? data.assignedTo.firstName + " " + data.assignedTo.lastName + " (" + data.assignedTo.id + ")" : "");
        setSelectedQCTeamMembers(data.qcAssignedTo ? data.qcAssignedTo.firstName + " " + data.qcAssignedTo.lastName + " (" + data.qcAssignedTo.id + ")" : "");
        setQcDate(data.qcEndDate ? moment(data.qcEndDate).format("YYYY-MM-DD hh:mm A") : null);
        setQcSDate(data.qcStartDate ? moment(data.qcStartDate).format("YYYY-MM-DD hh:mm A") : null);

        setAllRatings(data.ratings);
        // setEmail(data.emailId);
        // setDesignation(data.designation);
        // setDob(data.dob);
        // setDoj(data.dateOfJoining);
        // setExperience(data.yearsOfExperience);
        // setAddress(data.address);
        // setGender(data.gender);
        // setRole(data.role);
        // setPassword(data.firstName);
        // setMemberId(data.id);
      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  const handleEDateChange = (e) => {
    setEDate(moment(e).format("YYYY-MM-DD hh:mm A"));
  }

  const handleSDateChange = (e) => {
    setSDate(moment(e).format("YYYY-MM-DD hh:mm A"));
  }

  const handleQcDateChange = (e) => {
    setQcDate(moment(e).format("YYYY-MM-DD hh:mm A"));
  }
  const handleSDescriptionClick = () => {
    if (localStorage.getItem("role") !== "ROLE_USER"  && status !== "DONE"  && status !== "HOLD" && status !== "DROP" ) {
      setIsSdescriptionEditing(!isSdescriptionEditing);
      if (isSdescriptionEditing === true) {
        patchUpdateTask(taskId, {
          shortDescription: shortDescription,
          modifiedBy: localStorage.getItem("userId")
        }).then(resp => {

        }).catch(error => {
          if (error && error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
            toast.error(error.response.data.errors[0]);
          } else {
            toast.error("Internal server error, contact support team");
          }
        });
      }
    }
  };

  const priorityObj = [
    {
      label: "High",
      value: "HIGH"
    },
    {
      label: "Medium",
      value: "MEDIUM"
    },
    {
      label: "Low",
      value: "LOW"
    }
  ]

  const savePriority = () => {
    if (localStorage.getItem("role") !== "ROLE_USER") {
      if (!priorityReason || !priority) {
        toast.error("Fields cannot be blank");
        return false;
      }
      patchUpdateTask(taskId, {
        priority: priority,
        reason: priorityReason,
        modifiedBy: localStorage.getItem("userId")
      }).then(resp => {
        setPriorityReason("");
        toast.success("Priority updated successfully");
        handlePriorityClose();
        getCurrenttask(taskId);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
          toast.error(error.response.data.errors[0]);
        } else {
          toast.error("Internal server error, contact support team");
        }
      });
    } else {
      toast.error("You dont have permission to change");
    }
  }

  const handlePriorityChange = (e) => {
    if (localStorage.getItem("role") !== "ROLE_USER") {
      setPriority(e.target.value);
    } else {
      toast.error("You dont have permission to change");
    }
  };

  const saveComplexity = () => {
    if (localStorage.getItem("role") !== "ROLE_USER") {
      if (!complexityReason || !complexity) {
        toast.error("Fields cannot be blank");
        return false;
      }

      patchUpdateTask(taskId, {
        complexity: complexity,
        reason: complexityReason,
        modifiedBy: localStorage.getItem("userId")
      }).then(resp => {
        setComplexityReason("");
        toast.success("Complexity updated successfully");
        handleComplexityClose();
        getCurrenttask(taskId);
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
          toast.error(error.response.data.errors[0]);
        } else {
          toast.error("Internal server error, contact support team");
        }
      });
    } else {
      toast.error("You dont have permission to change");
    }
  }

  const handleComplexityChange = (e) => {
    if (localStorage.getItem("role") !== "ROLE_USER") {
      setComplexity(e.target.value);
    }
  };

  const saveDescription = () => {
    if (localStorage.getItem("role") !== "ROLE_USER") {
      setLoadingIcon("saving...");
      patchUpdateTask(taskId, {
        description: description,
        modifiedBy: localStorage.getItem("userId")
      }).then(resp => {

        if (resp.status) {
          setLoadingIcon("saved");
          toast.success("Description updated successfully");
        }
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
          toast.error(error.response.data.errors[0]);
        } else {
          toast.error("Internal server error, contact support team");
        }
      });
    } else {
      toast.error("You dont have permission to change");
    }
  }
  const saveMember = () => {
    //selectedOption
    //if(localStorage.getItem("role") !=="ROLE_USER"){

    let idCode = selectedTeamMembers.split("(");
    let val = idCode[1].split(")");

    patchUpdateTask(taskId, {
      assignedTo: val[0].trim(),
      assignedBy: localStorage.getItem("userId"),
      modifiedBy: localStorage.getItem("userId")
    }).then(resp => {

      getCurrenttask(taskId);
      if (resp.status) {
        toast.success("Assigned Members updated successfully");
        handleMemberClose();
      }
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        toast.error(error.response.data.errors[0]);
      } else {
        toast.error("Internal server error, contact support team");
      }
    });
    // } else {
    //   toast.error("You dont have permission to modify members");
    // }
  }

  const saveQCMember = () => {
    //selectedOption
    // if(localStorage.getItem("role") !=="ROLE_USER"){

    let idCode = selectedQCTeamMembers.split("(");
    let val = idCode[1].split(")");
    if (!val || !qcdate || !qcReason) {
      toast.error("Fields cannot be blank");
      return false;
    }
    patchUpdateTask(taskId, {
      qcAssignedTo: val[0].trim(),
      qcEndDate: qcdate,
      reason: qcReason,
    }).then(resp => {

      getCurrenttask(taskId);
      if (resp.status) {
        setQcReason("");
        toast.success("Assigned QC updated successfully");
        handleQCMemberClose();
      }
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        toast.error(error.response.data.errors[0]);
      } else {
        toast.error("Internal server error, contact support team");
      }
    });
    // } else {
    //   toast.error("You dont have permission to modify QC members");
    // }
  }

  const saveTags = () => {
    //selectedOption
    if (localStorage.getItem("role") !== "ROLE_USER") {
      patchUpdateTask(taskId, {
        tags: selectedTags,
        modifiedBy: localStorage.getItem("userId")
      }).then(resp => {

        getCurrenttask(taskId);
        if (resp.status) {
          toast.success("Tags updated successfully");
          handleTagsClose();
        }
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
          toast.error(error.response.data.errors[0]);
        } else {
          toast.error("Internal server error, contact support team");
        }
      });
    } else {
      toast.error("You dont have permission to modify tags");
    }
  }
  const saveDueDate = () => {
    if (localStorage.getItem("role") !== "ROLE_USER") {
      if (!sdate || !edate || !dueDateReason) {
        toast.error("Start date , end date, reason cannot be blank");
        return false;
      }
      patchUpdateTask(taskId, {
        startDate: sdate,
        endDate: edate,
        reason: dueDateReason,
        modifiedBy: localStorage.getItem("userId")
      }).then(resp => {

        if (resp.status) {
          setDueDateReason("");
          toast.success("Due dates updated successfully");
          handleDueDateClose();
          getCurrenttask(taskId);
        }
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
          toast.error(error.response.data.errors[0]);
        } else {
          toast.error("Internal server error, contact support team");
        }
      });
    } else {
      toast.error("You dont have permission to modify due dates");
    }
  }

  const handleSDescriptionChange = (event) => {
    setShortDescription(event.target.value);
  };

  const handleTitleClick = () => {
    if (localStorage.getItem("role") !== "ROLE_USER" && status !== "DONE" && status !== "HOLD" && status !== "DROP"   ) {
      setIsTitleEditing(!isTitleEditing);
      if (isTitleEditing === true) {
        patchUpdateTask(taskId, {
          title: title,
          modifiedBy: localStorage.getItem("userId")
        }).then(resp => {

        }).catch(error => {
          if (error && error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
            toast.error(error.response.data.errors[0]);
          } else {
            toast.error("Internal server error, contact support team");
          }
        });
      }
    }

  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const addCommentsToTask = () => {
    console.log(commentMsg);
    if (!commentMsg || (commentMsg != null && commentMsg==="<p></p>")) {
      toast.error("Message cannot be blank");
      return false;
    }
    addComments(taskId, commentMsg).then(resp => {

      if (resp.status) {
        // setLoadingIcon("saved");
        setEditorCommentState(EditorState.createEmpty());
        getCurrenttask(taskId);
      }
    });
  }

  const deleteAttachment = (id) => {
    deleteAttachmentById(id, taskId).then(resp => {

      if (resp.status) {
        // setLoadingIcon("saved");
        getCurrenttask(taskId);
        toast.success("Attachment deleted successfully!!");
      }
    });
  }
  const editComment = (id) => {

  }
  const deleteComment = (id) => {
    deleteCommentById(id, taskId).then(resp => {

      if (resp.status) {
        // setLoadingIcon("saved");
        getCurrenttask(taskId);
        toast.success("Comment deleted successfully!!");
      }
    });
  }

  function getExtension(filename) {
    return filename.split('.').pop()
  }

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const submitRatings = () => {
    if (rateTeamMember === null || rateTeamMember === undefined || rateTeamMember === ""
      || feedback === null || feedback === undefined || feedback === "" ||
      rating === null || rating === undefined || rating === 0) {
      toast.error("Fields cannot be blank");
      return false;
    }
    if (feedback.length < 20) {
      toast.error("Feedback should not less than 20 characters");
      return false;
    }
    addRating(taskId, rateTeamMember, rating, feedback).then(resp => {

      if (resp.status === 201) {
        // setLoadingIcon("saved");
        toast.success("Ratings & feedback submitted successfully");
        getCurrenttask(taskId);
        setFeedback("");
        setRating("");
        setRateTeamMember("");
        handleRatingsClose();
      }
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        toast.error(error.response.data.errors[0]);
      } else {
        toast.error("Internal server error, contact support team");
      }
    });
  }

  const onFileUpload = () => {
    if (!selectedFile) {
      toast.error("Please choose file to upload");
      return false;
    }
    const formData = new FormData();
    formData.append(
      "file",
      selectedFile
    );

    formData.append("requestAttachment", JSON.stringify({ uploadedBy: localStorage.getItem("userId"), taskId: taskId }))

    // Details of the uploaded file

    toast.info("Uploading...");
    addAttachmnts(formData).then(resp => {
      let data = resp.data;

      getCurrenttask(taskId);
      handleAttachmentClose();
      setSelectedFile(null);
      toast.success("File uploaded successfully!");
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
        toast.error(error.response.data.errors[0]);
      } else {
        toast.error("Internal server error, contact support team");
      }
    });

    // Request made to the backend api
    // Send formData object

  };

  const fileData = () => {
    if (selectedFile) {
      return (
        <div>
          <Typography variant='soft' level='title-md'>File Details:</Typography>
          <Typography variant='soft' level='body-sm'>
            File Name:{" "}
            {selectedFile.name}
          </Typography>

          <Typography variant='soft' level='body-sm'>
            File Type:{" "}
            {selectedFile.type}
          </Typography>

          <Typography variant='soft' level='body-sm'>
            Last Modified:{" "}
            {selectedFile.lastModifiedDate.toDateString()}
          </Typography>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <Typography variant='soft' level='title-sm'>
            Choose before Pressing the Upload button
          </Typography>
        </div>
      );
    }
  };

  // const [editorState, setEditorState] = React.useState(() => {
  //   const content = ContentState.createFromBlockArray(
  //     convertFromHTML(selectedValue.description)
  //   );
  //   return EditorState.createWithContent(content);
  // });

  // const handleEditorTextChange = (e) => {
  //   console.log(e)
  //   // const content = ContentState.createFromBlockArray(
  //   //   convertFromHTML(e.consigne)
  //   // );
  //   // setEditorState(EditorState.createWithContent(content));
  // }

  // const handleClose = () => {
  //   onClose(selectedValue);
  // };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };
  const CustomPaper = (props) => {
    return <Paper elevation={8} sx={{ fontSize: '0.8rem !important' }} {...props} />;
  };
  const BootstrapCopyDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      minWidth: '400px !important',
      height: 'auto'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  return (

    <>
      <DialogContent>
        <Stack direction={"row"} spacing={2} style={{ width: '100%' }}>
          <Typography level='h4' style={{ color: '#262672', fontSize: '1rem', fontWeight: 'bold' }}>{taskId}: </Typography>

          {isTitleEditing && status !== "DONE"  && status !== "HOLD" && status !== "DROP" ? (
            <div style={{ width: '70%' }}>
              <TextField
                type="text"
                inputRef={input => input && input.focus()}
                variant="outlined"
                fullWidth
                size='small'
                value={title}
                inputProps={{
                  maxlength: 70, style: {
                    fontSize: '0.8rem'
                  }

                }}
                helperText={`${title.length}/${70}`}
                onChange={handleTitleChange}
                onBlur={handleTitleClick}
              />
            </div>
          ) : (
            <Typography level='h4' onClick={handleTitleClick} style={{ color: '#262672', fontSize: '1rem', fontWeight: 'bold' }}>{title}</Typography>

          )}
          {
            localStorage.getItem("role") !== "ROLE_USER" && status === "TODO" ? (
              <div>
                <Tooltip title={"Delete task"}>
                  <Button variant='outlined' color='danger' startDecorator={<MdDelete size={"19px"} />} size='sm' onClick={toggleDeleteTaskModal}>DELETE</Button>
                </Tooltip>
              </div>
            ) : ""
          }


        </Stack>
        {
          projectId && projectId.projectName ?
            (
              <Tooltip title={"This task is under the project (" + projectId.projectName + ")"}>
                <Chip variant='soft' color='primary'>{projectId.projectName}</Chip>
              </Tooltip>
            ) : ""
        }
        {
          status ? (
            <Tooltip title={"Current status is (" + status + ")"}>
              <Chip variant='soft' color='primary' startDecorator={
                status === "TODO" ? <LuListTodo /> :
                  status === "INPROGRESS" ? <GrInProgress /> :
                    status === "DONE" ? <IoCheckmarkDoneCircleOutline /> :
                      status === "HOLD" ? <FaPauseCircle /> :
                        status === "DROP" ? <CiCircleRemove /> :
                          status === "QA" ? <AiOutlineFileSearch /> : <BsListTask />}>{status}</Chip>
            </Tooltip>
          ) : ""
        }

        {
          members ?
            (<Tooltip title={"Prod Assigned member for this task is (" + members.firstName.toUpperCase() + " " + members.lastName.toUpperCase() + ")"}><Chip variant='soft' color='primary' startDecorator={<LuUser />}>{members.firstName.toUpperCase() + " " + members.lastName.toUpperCase()}</Chip></Tooltip>
            ) : ""
        }
        {
          sdate && sdate !== null && sdate !== undefined ?
            (<Tooltip title={"Prod Start date is (" + sdate + ")"}><Chip variant='soft' color='primary' startDecorator={<LuClock />}>{sdate}</Chip></Tooltip>
            ) : ""
        }

        {
          edate ?
            (<Tooltip title={"Prod Deadline for this task is (" + edate + ")"}><Chip variant='soft' color='primary' startDecorator={<LuAlarmClock />}>{edate}</Chip></Tooltip>
            ) : ""
        }

        {
          qcmembers ?
              (<Tooltip title={"QC Assigned member for this task is (" + qcmembers.firstName.toUpperCase() + " " + qcmembers.lastName.toUpperCase() + ")"}><Chip variant='soft' color='primary' startDecorator={<RiUserSearchLine />}>{qcmembers.firstName.toUpperCase() + " " + qcmembers.lastName.toUpperCase()}</Chip></Tooltip>
              ) : ""
        }

        {
          qcSdate && qcSdate !== null && qcSdate !== undefined ?
          (<Tooltip title={"QC Start date is (" + qcSdate + ")"}><Chip variant='soft' color='primary' startDecorator={<LuClock />}>{qcSdate}</Chip></Tooltip>
          ) : ""
        }

        {
          qcdate ?
              (<Tooltip title={"QC Deadline for this task is (" + qcdate + ")"}><Chip variant='soft' color='primary' startDecorator={<LuAlarmClock />}>{qcdate}</Chip></Tooltip>
              ) : ""
        }

        

        

        

        


        {priority ? (
          <Tooltip title={"Priority is (" + priority + ")"}>
            <Chip variant='soft' color='primary' startDecorator={
              priority === "LOW" ? <PiWifiLowFill /> :
                priority === "MEDIUM" ? <PiWifiMediumFill /> :
                  priority === "HIGH" ? <PiWifiHighFill /> : <PiWifiHighFill />

            }>{priority}</Chip>
          </Tooltip>
        ) : ""}


        {
          selectedTags && selectedTags.length > 0 ?

            selectedTags.map((each, ind) => (
              <>
                <Tooltip title={"This task is assigned under the tags  (" + selectedTags + ")"}>
                  <Chip variant='soft' color='primary' startDecorator={<IoIosPricetags />}>{each}</Chip>
                </Tooltip>
              </>
            )) : ""
        }


        <div style={{ marginTop: '5px' }}>
          {isSdescriptionEditing && status !== "DONE"  && status !== "HOLD" && status !== "DROP" ? (
            <div style={{ width: '80%', height: 'auto' }}>
              <TextField
                multiline
                rows={2}
                type="text"
                fullWidth
                inputRef={input => input && input.focus()}
                variant="outlined"
                size='small'
                value={shortDescription}
                inputProps={{
                  maxlength: 70
                }}
                helperText={`${shortDescription.length}/${70}`}
                onChange={handleSDescriptionChange}
                onBlur={handleSDescriptionClick}
              />
            </div>
          ) : (
            <Typography level='body-sm' onClick={handleSDescriptionClick}>{shortDescription}</Typography>

          )}
        </div>
        <br></br>
        <Grid container spacing={3}>
          <Grid item md={9} style={{ maxHeight: '400px', overflowY: 'auto', scrollbarWidth: "thin" }}>
            <Typography level='title-md' style={{ color: '#262672' }} startDecorator={<ImParagraphLeft />}>Description</Typography>
            {/* <Typography level='body-sm' style={{color:'#262672'}}>{selectedValue.description}</Typography>
                 */}

            <Card style={{ minHeight: '300px', marginTop: '5px', width: '95%' }}>
              <Editor
                wrapperClassName=""
                editorClassName=""
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'link'],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
                editorState={editorState}
                editorStyle={{ border: "1px solid lightgrey", borderRadius: '5px', padding: '5px', minHeight: '150px' }}
                onEditorStateChange={setEditorState}
                // mention={{
                //   separator: ' ',
                //   trigger: '@',
                //   suggestions: [
                //     { text: 'APPLE', value: 'apple', url: 'apple' },
                //     { text: 'BANANA', value: 'banana', url: 'banana' },
                //     { text: 'CHERRY', value: 'cherry', url: 'cherry' },
                //     { text: 'DURIAN', value: 'durian', url: 'durian' },
                //     { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
                //     { text: 'FIG', value: 'fig', url: 'fig' },
                //     { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
                //     { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
                //   ],
                // }}
                hashtag={{}}
              />


                {
                  localStorage.getItem("role") !== "ROLE_USER" && status !=="DONE"  ? (
                    <Button variant='soft' size='sm' style={{ width: '20%' }} onClick={saveDescription}>{loadingIcon}</Button>
                  ):""
                }

             
            </Card>

            {
              attachments && attachments.length > 0 ? 
<div style={{ marginTop: '60px' }}>
              <Divider style={{ border: '1px grey solid' }} />
              <br></br>
              <Typography level='title-md' style={{ color: '#262672', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} startDecorator={<GrAttachment />}>Attachments:</Typography>
              <Grid container >
                {
                  attachments && attachments.length > 0 && attachments.map((e, i) => (

                    <Card sx={{ width: '95%', marginTop: '5px' }}>
                      <Stack direction={"row"}>
                        <Grid item md={2}>
                          {
                            (getExtension(e.url) === "png" || getExtension(e.url) === "jpeg" || getExtension(e.url) === "jpg") ? (
                              <img src={e.url} style={{ borderRadius: '20px' }}></img>
                            ) : (getExtension(e.url) === "pdf") ? (
                              <img src={pdf}></img>
                            ) : (
                              <img src={link} style={{ width: '30%', marginLeft: '25%', marginTop: '10%' }}></img>
                            )
                          }

                        </Grid>
                        <Grid item md={7} sx={{ marginLeft: '10px' }}>
                          <>

                          <ListItem>
          <ListItemDecorator>
          <Avatar size="md" variant='solid' {...stringAvatar(e.uploadedBy.firstName + " " + e.uploadedBy.lastName)}></Avatar>
          </ListItemDecorator>
          <ListItemContent>
            <Typography level="title-sm">&nbsp;{e.uploadedBy.firstName + " " + e.uploadedBy.lastName}</Typography>
            <Typography level="body-sm" noWrap>
            &nbsp;uploaded on&nbsp; {moment(e.uploadedDate).format("YYYY-MM-DD hh:mm a")}
            </Typography>
          </ListItemContent>
        </ListItem>

                            <Stack direction={"row"}>
                            <IconButton>
                            <Link target="_blank" href={e.url} >
                            <LuDownload />
                               </Link>
                                  </IconButton>
                              

                              {
                                localStorage.getItem("role") !== "ROLE_USER" ? (
                                  <IconButton onClick={() => deleteAttachment(e.id)}>
                                    <DeleteOutline style={{fontSize:'18px', color:'#0B6BCB'}}/>
                                  </IconButton>
                                 
                                ) : (localStorage.getItem("role") === "ROLE_USER" && localStorage.getItem("userId") === e.uploadedBy.id) ? (
                                   <IconButton onClick={() => deleteAttachment(e.id)}>
                                    <MdDeleteOutline />
                                  </IconButton>
                                ) : ""}
                            </Stack>
                          </>
                        </Grid>
                      </Stack>
                    </Card>

                  ))
                }
              </Grid>

            </div>:""
            }
            

            <div style={{ marginTop: '50px' }}>
              <Divider style={{ border: '1px grey solid' }} />
              <br></br>
              <Typography ref={commentRef} level='title-md' style={{ color: '#262672', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} startDecorator={<FaRegComments />}>Comments:</Typography>

              {
                comments.length > 0 && comments.map((e, i) => (
                  <>
                    <Stack direction={"row"} spacing={2}>
                      <Avatar variant='solid' {...stringAvatar(e.createdBy.firstName + " " + e.createdBy.lastName)}></Avatar>
                      <Stack direction={"column"} style={{ width: '100%' }} >
                      <Stack direction={"column"} style={{ width: '100%' }} >
                        <Typography level={"title-md"}>{e.createdBy.firstName + " " + e.createdBy.lastName}</Typography> 
                        
                        <Typography level={"body-sm"}> commented on {moment(e.createdDate).format("DD-MM-YYYY hh:mm A")} </Typography> 
                        
                        
                        </Stack>
                        <Card style={{ width: '95%' }}>
                          <div className="content" dangerouslySetInnerHTML={{ __html: e.message }}></div>
                        </Card>
                        {
                            (i === comments.length - 1) && e.createdBy.id === localStorage.getItem("userId") ? (
                              <Button size='sm' variant='soft' style={{width:'13%', marginTop:'3px', float:'right'}} onClick={() => deleteComment(e.id)} startDecorator={<DeleteOutline style={{fontSize:'18px'}} />}>
                                DELETE
                              </Button>
                            ) : ""
                          }
                      </Stack>
                    </Stack>
                  </>
                ))
              }
              <br></br>

              <Stack direction={"row"} spacing={2}>
                <Avatar variant='solid' {...stringAvatar(localStorage.getItem("firstname") + " " + localStorage.getItem("lastname"))}></Avatar>
                <Stack direction={"column"} spacing={2} justifyContent={'flex-start'} sx={{ width: '95%' }}>
                  <Card style={{ minHeight: '200px', width: '95%' }}>
                    <Editor
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor hideScroll"
                      toolbarClassName="toolbar-class"
                      toolbarStyle={{}}
                      editorStyle={{ height: 'auto' }}
                      placeholder='Enter your comments here...'
                      toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'link', 'emoji'],
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                      }}
                      editorState={editorCommentState}
                      onEditorStateChange={setEditorCommentState}
                      // mention={{
                      //   separator: ' ',
                      //   trigger: '@',
                      //   suggestions: [
                      //     { text: 'APPLE', value: 'apple', url: 'apple' },
                      //     { text: 'BANANA', value: 'banana', url: 'banana' },
                      //     { text: 'CHERRY', value: 'cherry', url: 'cherry' },
                      //     { text: 'DURIAN', value: 'durian', url: 'durian' },
                      //     { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
                      //     { text: 'FIG', value: 'fig', url: 'fig' },
                      //     { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
                      //     { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
                      //   ],
                      // }}
                      hashtag={{}}
                    />

                  </Card>
                  <Button variant='soft' size='sm' style={{ width: '20%' }} onClick={addCommentsToTask}>SUBMIT</Button>
                </Stack>
              </Stack>




            </div>



            <div style={{ marginTop: '50px' }}>
              <Divider style={{ border: '1px grey solid' }} />
              <br></br>
              <Typography ref={activityRef} level='title-md' style={{ color: '#262672', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} startDecorator={<RxActivityLog />}>Activity:</Typography>

              {
                activity && activity.length > 0 && activity.map((e, i) => (
                  <>
                    <Stack direction={"row"} spacing={1}>
                      <Avatar variant='solid' size='sm' {...stringAvatar(e.createdBy ? e.createdBy.firstName + " " + e.createdBy.lastName : "U U")}></Avatar>

                      <Stack direction={"column"} style={{ width: '100%' }} >
                        <Typography level={"title-md"}>{e.createdBy ? (e.createdBy.firstName + " " + e.createdBy.lastName) : ""}
                          <Typography level={"body-sm"} fontWeight={"400"}>{" " + e.message}</Typography>
                        </Typography>


                        <Stack direction={"row"}>
                          <Typography level={"body-sm"} fontWeight={"400"} >{moment(e.createdDate).format('llll')}</Typography>

                        </Stack>
                      </Stack>
                    </Stack>
                    <br></br>
                  </>
                ))
              }





            </div>



            <div style={{ marginTop: '50px' }}>
              <Divider style={{ border: '1px grey solid' }} />
              <br></br>
              <Typography ref={timelineRef} level='title-md' style={{ color: '#262672', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} startDecorator={<TimelineIcon />}>Timeline:</Typography>
              <Timeline position="right">
                {
                  timeline && timeline.length > 0 && timeline.map((e, i) => (
                    <>
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: 'auto 0' }}
                          align="right"
                          variant="overline"
                          color="text.primary"
                        >{e.changedStatus}

                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot color='success'>
                            {timelineDataArray[e.changedStatus].icon}
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <Card>
                            <Stack direction={"row"} spacing={1}>
                              <Avatar variant='soft' size='sm' {...stringAvatar(e.member ? e.member.firstName + " " + e.member.lastName : "U U")}></Avatar>

                              <Stack direction={"column"} style={{ width: '100%' }} >
                                <Typography level={"title-md"}>{e.member ? (e.member.firstName + " " + e.member.lastName) : ""}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Typography level='title-sm' fontStyle={"italic"}>{"\"" + e.message + "\""}</Typography>
                            <Typography level='body-sm'>
                              {moment(e.changedTime).format('llll')}
                            </Typography>

                          </Card>
                        </TimelineContent>
                      </TimelineItem>

                      {/* <Stack direction={"row"} spacing={1}>
                <Avatar variant='solid' size='sm' { ...stringAvatar(e.member ? e.member.firstName+" "+ e.member.lastName:"U U")}></Avatar>
                
                 <Stack direction={"column"} style={{width:'100%'}} >
                <Typography level={"title-md"}>{e.member ? (e.member.firstName+" "+ e.member.lastName):""}
                <Typography level={"body-sm"} fontWeight={"400"}>{" "+e.message}</Typography>
                </Typography>
                
                
                <Stack direction={"row"}>
                  <Typography level={"body-sm"} fontWeight={"400"} >{moment(e.changedTime).format('llll')}</Typography>
                  
                  </Stack>
                </Stack>
                </Stack>
                <br></br> */}
                    </>
                  ))
                }
              </Timeline>




            </div>

          </Grid>
          <Grid item md={3}>
            <Button variant={"soft"} style={{ marginBottom: '5px', justifyContent: "flex-start" }} startDecorator={<LuUsers />} fullWidth aria-describedby={idMember} onClick={handleMemberClick}>
              MEMBER
            </Button>
            {
              status === "QA" || status === "DONE"  ? (
                <Button variant={"soft"} style={{ marginBottom: '5px', justifyContent: "flex-start" }} startDecorator={<RiUserSearchLine />} fullWidth aria-describedby={idQCMember} onClick={handleQCMemberClick}>
                  ASSIGN QC
                </Button>
              ) : ""
            }
            <Button variant={"soft"} style={{ marginBottom: '5px', justifyContent: "flex-start" }} startDecorator={<PiCellSignalFullBold />} fullWidth aria-describedby={idComplexity} onClick={handleComplexityClick}>
              COMPLEXITY
            </Button>
            <Button variant={"soft"} style={{ marginBottom: '5px', justifyContent: "flex-start" }} startDecorator={<MdAccessAlarm />} fullWidth aria-describedby={idDueDate} onClick={handleDueDateClick}>
              DUE DATE
            </Button>
            <Button variant={"soft"} style={{ marginBottom: '5px', justifyContent: "flex-start" }} startDecorator={<LuUsers />} fullWidth aria-describedby={idPriority} onClick={handlePriorityClick}>
              PRIORITY
            </Button>
            <Button variant={"soft"} style={{ marginBottom: '5px', justifyContent: "flex-start" }} startDecorator={<IoPricetagsOutline />} fullWidth aria-describedby={idTags} onClick={handleTagsClick}>
              TAGS
            </Button>
            {
              status !== "DONE"  ? (
            <Button variant={"soft"} style={{ marginBottom: '5px', justifyContent: "flex-start" }} startDecorator={<GrAttachment />} fullWidth aria-describedby={idAttachment} onClick={handleAttachmentClick}>
              ATTACHMENTS
            </Button>
              ):""
            }
            <Button variant={"soft"} style={{ marginBottom: '5px', justifyContent: "flex-start" }} startDecorator={<FaRegComments />} fullWidth onClick={() => commentRef.current.scrollIntoView()}>
              COMMENTS
            </Button>
            <Button variant={"soft"} style={{ marginBottom: '5px', justifyContent: "flex-start" }} startDecorator={<RxActivityLog />} fullWidth onClick={() => activityRef.current.scrollIntoView()}>
              ACTIVITY
            </Button>
            <Button variant={"soft"} style={{ marginBottom: '5px', justifyContent: "flex-start" }} startDecorator={<MdOutlineViewTimeline />} fullWidth onClick={() => timelineRef.current.scrollIntoView()}>
              TIMELINE
            </Button>
            {
              status === "DONE" ? (
                <Button variant={"soft"} style={{ marginBottom: '5px', justifyContent: "flex-start" }} startDecorator={<VscFeedback />} fullWidth aria-describedby={idRatings} onClick={handleRatingsClick}>
                  RATINGS
                </Button>
              ) : ""
            }


            {/* <Button variant={"soft"}  style={{marginBottom:'5px',justifyContent: "flex-start"}} startDecorator={<LuUsers/>} fullWidth aria-describedby={id} onClick={handleClick}>
              STATUS
            </Button>
            <Button variant={"soft"}  style={{marginBottom:'5px',justifyContent: "flex-start"}} startDecorator={<LuUsers/>} fullWidth aria-describedby={id} onClick={handleClick}>
              MEMBERS
            </Button> */}
            <Popover
              id={idMember}
              open={openMember}
              anchorEl={anchorMemberEl}
              onClose={handleMemberClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                style: { width: '30%' },
              }}
            >

              <Stack direction={"column"} sx={{ padding: '30px' }}>
                <Autocomplete
                  id="tags-filled"
                  style={{ width: '100%' }}
                  PaperComponent={CustomPaper}
                  disabled={status==="DONE"}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      color: '#000', // Text color for disabled input
                      WebkitTextFillColor: '#000', // For Safari
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#000', // Border color for disabled input
                    },
                  }}
                  options={allMembers.map((option) => option.firstName + " " + option.lastName + " (" + option.id + ")")}
                  value={selectedTeamMembers}
                  onChange={(event, newValue) => {
                    setSelectedTeamMembers(
                      newValue);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chipd avatar={<Avatar size="lg" color='danger' {...stringAvatar(option)} />} variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      style={{ width: '100%' }}
                      fullWidth
                      InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                      InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                      size='small'
                      label="Add Team Members"
                      placeholder="Add Team Members"
                    />
                  )}
                /><br></br>

{
                    localStorage.getItem("role") !== "ROLE_USER" && status !=="DONE"  ? (
                      <>

                <Button variant='soft' size='sm' style={{ width: '50%' }} onClick={saveMember}>Save</Button>
                </>):""}
                <Divider />

                <Typography level={"title-md"} color='primary' style={{ marginTop: '5px' }}>Task Members</Typography>
                {
                  members ? (
                    <>
                      <ListItemDecorator>
                        <Avatar size="sm" {...stringAvatar(members.firstName + " " + members.lastName)}></Avatar>
                        <Typography fontWeight="md" class="font-bold" sx={{ color: '#262673', fontSize: '0.7rem' }}>

                          &nbsp;&nbsp; {members.firstName.toUpperCase() + " " + members.lastName.toUpperCase()}
                        </Typography>
                      </ListItemDecorator>



                    </>
                  ) : ""


                }

              </Stack>
            </Popover>

            <Popover
              id={idQCMember}
              open={openQCMember}
              anchorEl={anchorQCMemberEl}
              onClose={handleQCMemberClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              PaperProps={{
                style: { width: '30%' },
              }}
            >

              <Stack direction={"column"} sx={{ padding: '30px' }}>
                <Autocomplete
                  id="tags-filled"
                  style={{ width: '100%' }}
                  PaperComponent={CustomPaper}
                  options={allMembers.map((option) => option.firstName + " " + option.lastName + " (" + option.id + ")")}
                  value={selectedQCTeamMembers}
                  disabled={status==="DONE"}
                  onChange={(event, newValue) => {
                    setSelectedQCTeamMembers(
                      newValue);
                  }}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      color: '#000', // Text color for disabled input
                      WebkitTextFillColor: '#000', // For Safari
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#000', // Border color for disabled input
                    },
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chipd avatar={<Avatar size="lg" color='danger' {...stringAvatar(option)} />} variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size='small'
                      style={{ width: '100%' }}
                      fullWidth
                      disabled={status==="DONE"}
                      InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                      InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}

                      label="Add QC Members"
                      placeholder="Add QC Members"
                    />
                  )}
                /><br></br>

                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
                  <DesktopDateTimePicker
                    label="Planned complete date"
                    fullWidth
                    value={moment(qcdate).format("YYYY-MM-DD HH:mm:ss")}
                    disablePast="true"
                    size="small"
                    disabled={status==="DONE"}
                    onChange={handleQcDateChange}
                    renderInput={(params) => <TextField size="small" {...params}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        color: '#000', // Text color for disabled input
                        WebkitTextFillColor: '#000', // For Safari
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000', // Border color for disabled input
                      },
                    }}
                      InputProps={{ ...params.InputProps, size: "small", style: { fontSize: "0.8rem" } }}
                      InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                    />}
                  /></LocalizationProvider> &nbsp;&nbsp;&nbsp;

{
                    status !=="DONE"  ? (
                     
                <>
                  <br></br>

                  <TextField
                    id="outlined-multiline-flexible"
                    label="Reason"
                    multiline
                    minRows={3}
                    maxRows={5}
                    type="text"
                    value={qcReason}
                    inputProps={{
                      maxlength: 200,
                      style: {
                        fontSize: '0.8rem'
                      }
                    }}
                    helperText={`${qcReason.length}/${200}`}
                    onChange={(e) => setQcReason(e.target.value)}
                    fullWidth
                    inputRef={input => input && input.focus()}
                    variant="outlined"
                    size='small'
                  />

                  <br></br>

                  <Button variant='soft' size='sm' style={{ width: '50%' }} onClick={saveQCMember}>ASSIGN</Button>

                </>
                    ):""
                  }


                <Typography level={"title-md"} color='primary' style={{ marginTop: '5px' }}>QC Member</Typography>
                {
                  qcmembers ? (
                    <>
                      <ListItemDecorator>
                        <Avatar size="sm" {...stringAvatar(qcmembers.firstName + " " + qcmembers.lastName)}></Avatar>
                        <Typography fontWeight="md" class="font-bold" sx={{ color: '#262673', fontSize: '0.7rem' }}>

                          &nbsp;&nbsp; {qcmembers.firstName.toUpperCase() + " " + qcmembers.lastName.toUpperCase()}
                        </Typography>
                      </ListItemDecorator>



                    </>
                  ) : ""


                }
              </Stack>
            </Popover>

            <Popover
              id={idComplexity}
              open={openComplexity}
              anchorEl={anchorComplexityEl}
              onClose={handleComplexityClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                style: { width: '30%' },
              }}
            >

              <Stack direction={"column"} sx={{ padding: '30px' }}>
                <Box sx={{ width: 270 }}>
                  <Typography >Complexity:</Typography>
                  <Slider

                    aria-label="Small steps"
                    defaultValue={1}
                    // getAriaValueText={valuetext}
                    step={1}
                    marks
                    // value={complexity}
                    // onChange={(e)=> setComplexity(e.target.value)}
                    value={complexity}
                    onChange={status==="DONE" ? '' : handleComplexityChange}
                    min={1}
                    max={10}
                    valueLabelDisplay="auto"
                  />

                  {
                    localStorage.getItem("role") !== "ROLE_USER" && status !=="DONE"  ? (
                      <>
                        <br></br>

                        <TextField
                          id="outlined-multiline-flexible"
                          label="Reason"
                          multiline
                          minRows={3}
                          maxRows={5}
                          type="text"
                          value={complexityReason}
                          inputProps={{
                            maxlength: 200,
                            style: {
                              fontSize: '0.8rem'
                            }
                          }}
                          helperText={`${complexityReason.length}/${200}`}
                          onChange={(e) => setComplexityReason(e.target.value)}
                          fullWidth
                          inputRef={input => input && input.focus()}
                          variant="outlined"
                          size='small'
                        />
                        <Tooltip variant='soft' title="Save complexity" >
                          <Button variant='soft' onClick={saveComplexity}>SUBMIT</Button>
                        </Tooltip>

                      </>) : ""
                  }

                </Box>
              </Stack>
            </Popover>
            <Popover
              id={idDueDate}
              open={openDueDate}
              anchorEl={anchorDueDateEl}
              onClose={handleDueDateClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Stack direction={"column"} sx={{ padding: '30px' }}>
                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
                  <DesktopDateTimePicker
                    label="Start date"
                    fullWidth
                    value={moment(sdate).format("YYYY-MM-DD HH:mm:ss")}
                    disablePast="true"
                    disabled={status==="DONE"}
                    size="small"
                    onChange={handleSDateChange}
                    renderInput={(params) => <TextField size="small" {...params}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        color: '#000', // Text color for disabled input
                        WebkitTextFillColor: '#000', // For Safari
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000', // Border color for disabled input
                      },
                    }}
                      InputProps={{ ...params.InputProps, size: "small", style: { fontSize: "0.8rem" } }}
                      InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                    />}
                  /></LocalizationProvider> &nbsp;&nbsp;&nbsp;

                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
                  <DesktopDateTimePicker
                    label="End date"
                    fullWidth
                    value={moment(edate).format("YYYY-MM-DD HH:mm:ss")}
                    disablePast="true"
                    disabled={status==="DONE"}
                    size="small"
                    minDateTime={sdate}
                    onChange={handleEDateChange}
                    renderInput={(params) => <TextField size="small" {...params}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        color: '#000', // Text color for disabled input
                        WebkitTextFillColor: '#000', // For Safari
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#000', // Border color for disabled input
                      },
                    }}
                      InputProps={{ ...params.InputProps, size: "small", style: { fontSize: "0.8rem" } }}
                      InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                    />}
                  /></LocalizationProvider> &nbsp;&nbsp;&nbsp;
                {
                  localStorage.getItem("role") !== "ROLE_USER" && status !=="DONE" ? (
                    <>
                      <br></br>

                      <TextField
                        id="outlined-multiline-flexible"
                        label="Reason"
                        multiline
                        minRows={3}
                        maxRows={5}
                        type="text"
                        value={dueDateReason}
                        inputProps={{
                          maxlength: 200,
                          style: {
                            fontSize: '0.8rem'
                          }
                        }}
                        helperText={`${dueDateReason.length}/${200}`}
                        onChange={(e) => setDueDateReason(e.target.value)}
                        fullWidth
                        inputRef={input => input && input.focus()}
                        variant="outlined"
                        size='small'
                      />

                      <br></br>
                      <Button variant='soft' onClick={saveDueDate}>SUBMIT</Button>
                    </>) : ""}
              </Stack>


            </Popover>
            <Popover
              id={idTags}
              open={openTags}
              anchorEl={anchorTagsEl}
              onClose={handleTagsClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                style: { width: '30%' },
              }}
            >

              <Stack direction={"column"} sx={{ padding: '30px' }}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  style={{ width: '100%' }}
                  PaperComponent={CustomPaper}
                  size='small'
                  disabled={status==="DONE"}
                  options={allTags && allTags.length > 0 && allTags.map((option) => option.name)}
                  value={selectedTags}
                  onChange={(event, newValue) => {

                    settags(newValue);
                    setSelectedTags(newValue);
                  }}
                  // renderTags={(value, getTagProps) =>
                  //     value.map((option, index) => (
                  //         <Chipd avatar={<Avatar size="lg" color='danger' {...stringAvatar(option)} />} variant="outlined" label={option} {...getTagProps({ index })} />
                  //     ))
                  // }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      style={{ width: '100%' }}
                      fullWidth
                      InputProps={{ ...params.InputProps, style: { fontSize: "0.8rem" } }}
                      InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "0.8rem" } }}
                      size='small'
                      label="Add Tags"
                      placeholder="Add Tags"
                    />
                  )}
                /><br></br>

                {
                  status==="DONE"? 
                  (
                    <></>
                  ):<Button variant='soft' size='sm' style={{ width: '50%' }} onClick={saveTags}>Save</Button>

                }
                
                <Divider />

                <Typography level={"title-md"} color='primary' style={{ marginTop: '5px' }}>Tags</Typography>
                {
                  tags && tags.length > 0 ?
                    tags.map((each, ind) => (
                      <>
                        <ListItemDecorator>
                          <Typography fontWeight="md" class="font-bold" startDecorator={<IoPricetagsOutline />} sx={{ color: '#262673', fontSize: '0.7rem' }}>

                            {each.name}
                          </Typography>
                        </ListItemDecorator>



                      </>
                    )) : ""


                }

              </Stack>
            </Popover>
            <Popover
              id={idAttachment}
              open={openAttachment}
              anchorEl={anchorAttachmentEl}
              onClose={handleAttachmentClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Stack direction={"column"} sx={{ padding: '30px' }}>

                <div>
                  <input
                    type="file"
                    onChange={onFileChange}
                  />
                  <Button variant='soft' onClick={onFileUpload}>
                    Upload!
                  </Button>
                </div>
                {fileData()}
              </Stack>


            </Popover>

            <Popover
              id={idRatings}
              open={openRatings}
              anchorEl={anchorRatingsEl}
              onClose={handleRatingsClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              
            ><Box sx={{minHeight:'450px'}}>
              <Stack direction={"column"} sx={{ padding: '30px', width: '300px' }}>
                <Stack direction={"row"} justifyContent={"center"} style={{ padding: "10px" }}>

                  {
                    (localStorage.getItem("role") === "ROLE_SUPER_ADMIN" ||
                      (localStorage.getItem("role") === "ROLE_ADMIN" &&
                        localStorage.getItem("isTL") === "true")) ? (
                      <Button variant='solid' onClick={() => { setAddRatings(true); setShowRatings(false); setShowFeedback(false) }}>ADD RATINGS</Button>

                    ) : ""
                  }<Button variant='solid' onClick={() => { setAddRatings(false); setShowRatings(true); setShowFeedback(false) }}>VIEW RATINGS</Button>
                  <Button variant='solid' onClick={() => { setAddRatings(false); setShowRatings(false); setShowFeedback(true) }}>VIEW QC FEEDBACK</Button>

                </Stack>

                {
                  addRatings && localStorage.getItem("role") !== "ROLE_USER" ? (
                    <>
                      <FormControl fullWidth variant="outlined" size="small">
                        <FormLabel>Choose Member:</FormLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          variant="outlined"
                          value={rateTeamMember}
                          size="small"
                          onChange={(event) => {
                            setRateTeamMember(event.target.value)
                          }}
                        >
                          <MenuItem value={""} selected  >
                            Please choose
                          </MenuItem>
                          {taskMembers && taskMembers.length > 0 && taskMembers.map((item, ind) => (
                            allRatings.some(el => el.member.id === item.id) ?
                              (
                                <MenuItem value={item.id} disabled  >
                                  {(ind === 1 ? "[QC]" : "") + item.firstName + "- " + item.lastName + " (" + item.id + ")"}
                                </MenuItem>
                              ) : (
                                <MenuItem value={item.id}   >
                                  {(ind === 1 ? "[QC]" : "") + item.firstName + "- " + item.lastName + " (" + item.id + ")"}
                                </MenuItem>
                              )
                          ))
                          }
                        </Select>
                      </FormControl>



                      <Typography level='body-md'>{"Ratings & Feedback:"}</Typography>
                      <Rating
                        precision={0.5}
                        name="simple-controlled"
                        value={rating}
                        onChange={(event, newValue) => {
                          setRating(newValue);
                        }}
                      /><br></br>

                      <TextField
                        id="outlined-multiline-flexible"
                        label="Feedback"
                        multiline
                        minRows={3}
                        maxRows={5}
                        type="text"
                        value={feedback}
                        inputProps={{
                          maxlength: 200,
                          style: {
                            fontSize: '0.8rem'
                          }
                        }}
                        helperText={`${feedback.length}/${200}`}
                        onChange={(e) => setFeedback(e.target.value)}
                        fullWidth
                        inputRef={input => input && input.focus()}
                        variant="outlined"
                        size='small'
                      />
                      <br></br>
                      <Button variant='soft' onClick={submitRatings}>SUBMIT</Button>
                    </>
                  ) : ""
                }




                {
                  showRatings ? (
                    <>
                      {
                        allRatings.length > 0 ? allRatings.map((r, i) => (
                          <Card>
                            <ListItemDecorator>
                              <Avatar size="sm" variant='solid' {...stringAvatar(r.member.firstName + " " + r.member.lastName)}></Avatar>
                              <Typography fontWeight="md" class="font-bold" sx={{ color: '#262673', fontSize: '0.8rem' }}>

                                &nbsp; {r.member.firstName + " " + r.member.lastName}
                              </Typography>
                            </ListItemDecorator>
                            <Rating
                              precision={0.5}
                              name="simple-controlled"
                              value={r.rating}

                            />
                            <Typography level='title-sm' variant='soft'>{r.createdBy.firstName + " " + r.createdBy.lastName}:</Typography>
                            <Typography level='body-xs' sx={{ marginLeft: '10px' }}>{r.feedback}</Typography>
                          </Card>
                        )) : (
                          <Typography level='title-sm' variant='' color='warning' style={{ textAlign: 'center' }}>No ratings found</Typography>
                        )
                      }
                    </>
                  ) : ""
                }

                {
                  showFeedback ? (
                    <>
                      {
                        qcFeedback ? (
                          <Card>
                            <ListItemDecorator>
                              <Avatar size="sm" variant='solid' {...stringAvatar(qcmembers.firstName + " " + qcmembers.lastName)}></Avatar>
                              <Typography fontWeight="md" class="font-bold" sx={{ color: '#262673', fontSize: '0.8rem' }}>

                                &nbsp; {qcmembers.firstName + " " + qcmembers.lastName}
                              </Typography>
                            </ListItemDecorator>
                            <Typography level='title-sm' variant='' color='warning' style={{ textAlign: 'center' }}>{qcFeedback}</Typography>

                          </Card>
                        ) : ""
                      }
                    </>
                  ) : ""
                }



              </Stack>
              </Box>

            </Popover>

            <Popover
              id={idPriority}
              open={openPriority}
              anchorEl={anchorPriorityEl}
              onClose={handlePriorityClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Stack direction={"column"} sx={{ padding: '30px' }}>

                <FormControl>
                  <FormLabel>Priority</FormLabel>
                  <RadioGroup
                    overlay
                    name="member"
                    value={priority}
                    onChange={handlePriorityChange}
                    orientation="horizontal"
                    sx={{ gap: 2 }}
                  >
                    {priorityObj.map((num) => (
                      <Sheet
                        component="label"
                        key={num.value}
                        variant="outlined"
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          boxShadow: 'md',
                          borderRadius: 'md',
                        }}
                      >
                        <Radio
                          value={num.value}
                          checkedIcon={<CheckCircleRoundedIcon />}
                          
                    disabled={status==="DONE"}
                          variant="soft"
                          sx={{
                            mb: 2,
                          }}
                        />
                        <Typography level="body-sm" sx={{ mt: 1 }}>
                          {num.label}
                        </Typography>
                      </Sheet>
                    ))}
                  </RadioGroup>
                </FormControl>
                {
                  localStorage.getItem("role") !== "ROLE_USER" && status!=="DONE" ? (
                    <>
                      <br></br>

                      <TextField
                        id="outlined-multiline-flexible"
                        label="Reason"
                        multiline
                        minRows={3}
                        maxRows={5}
                        type="text"
                        value={priorityReason}
                        inputProps={{
                          maxlength: 200,
                          style: {
                            fontSize: '0.8rem'
                          }
                        }}
                        helperText={`${priorityReason.length}/${200}`}
                        onChange={(e) => setPriorityReason(e.target.value)}
                        fullWidth
                        inputRef={input => input && input.focus()}
                        variant="outlined"
                        size='small'
                      />
                      <Button variant='soft' onClick={savePriority}>SUBMIT</Button>
                    </>) : ""
                }
              </Stack>
            </Popover>


          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={viewTaskModal}>Close</Button>
      </DialogActions>



      <BootstrapCopyDialog
        onClose={toggleDeleteTaskModal}
        aria-labelledby="customized-dialog-title"
        open={deleteTaskShow}
      >
        {/*<BootstrapDialogTitle id="customized-dialog-title" className="toolHeader" style={{ textAlign: 'center', backgroundImage:'url(https://t4.ftcdn.net/jpg/02/76/08/07/360_F_276080724_hltnCyDjcqAyRtLzDYo3T2jXbBtCD7fl.jpg)', color: 'white' }}>
        ADD MEMBER
    </BootstrapDialogTitle>*/}
        <DialogContent>
          <Typography>Do you want to delete this task "{taskId}"</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='soft' onClick={deleteTaskById}>Yes delete it</Button>
          <Button variant='outlined' onClick={toggleDeleteTaskModal}>Close</Button>
        </DialogActions>
      </BootstrapCopyDialog>
    </>
  );
}

export default Popup;