import React from "react";



import Board from "../dnd/board/Board";
function Home() {

  
  return (
    <>
   
   
      <Board withScrollableColumns />
      
     
      </>
  );
}

export default Home;