import axios from 'axios';
import { toast } from 'react-toastify';
//const BACKEND_APP_URL = "http://localhost:8001/api/v1/";
//export const WEBSOCKET_URL = "http://localhost:8001/ws";
const BACKEND_APP_URL = "https://crewkeeper.cloud:8001/api/v1/";
export const WEBSOCKET_URL = "https://crewkeeper.cloud:8001/ws";


    export function logout(){
              toast("Session expired!");
              localStorage.removeItem("firstname");
              localStorage.removeItem("lastname");
              localStorage.removeItem("email");
              localStorage.removeItem("userId");
              localStorage.removeItem("token");
              localStorage.removeItem("role");
              localStorage.removeItem("isTL");
              window.location.replace("/")
    }
// axios.interceptors.response.use(response => {
//   console.log(response);
//   return response;
// }, error => {
//   console.log(error.response);
//  if (error.response.status === 401) {

//   alert("session exp");
//  }
//  return error;
// });

export function stringAvatar(name) {
  return {
    children: `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}`,
  };
}

export function SuperAdminUserList() {
  let array = ["wdm_ii_001"];
  return array;
}

export function timeAgo(date) {
  const now = new Date();
  const inputDate = new Date(date);
  const diffInSeconds = Math.floor((now - inputDate) / 1000);

  const intervals = [
    { label: 'yr', seconds: 31536000 },
    { label: 'mon', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hr', seconds: 3600 },
    { label: 'min', seconds: 60 },
    { label: 'sec', seconds: 1 }
  ];
  console.log(diffInSeconds);
  for (const interval of intervals) {
    const count = Math.floor(diffInSeconds / interval.seconds);
    if (count > 0) {
      return count === 1 ? `1 ${interval.label} ago` : `${count} ${interval.label}s ago`;
    }
  }

  return 'just now';
}

export function getDaysInMonth(monthInput) {
  const [year, month] = monthInput.split('-').map(Number);
  const date = new Date(year, month, 0);
  return date.getDate();
}

export function convertSecondsToHours(seconds) {
  const hours = Math.floor(seconds / 3600);
  
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  console.log(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`)
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export const loginUser = (username, password, osName, browserDetails, deviceId, latitude, lognitude) => {
  return axios.post(BACKEND_APP_URL + 'auth/login', {
    userName: username,
    password: password,
    osName: osName,
    browserDetails: browserDetails,
    deviceId: deviceId,
    latitude: latitude,
    lognitude: lognitude
  }, {
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const logoutUser = (osName, browserDetails, deviceId, latitude, lognitude) => {
  return axios.post(BACKEND_APP_URL + 'auth/logout', {
    osName: osName,
    browserDetails: browserDetails,
    deviceId: deviceId,
    latitude: latitude,
    lognitude: lognitude
  }, {
    headers: {'Content-Type': 'application/json',"Authorization": "Bearer " + localStorage.getItem("token")},
  })
}
export const forcelogoutUser = (osName, browserDetails, deviceId, latitude, lognitude, reason) => {
  return axios.post(BACKEND_APP_URL + 'auth/force-logout', {
    osName: osName,
    browserDetails: browserDetails,
    deviceId: deviceId,
    latitude: latitude,
    lognitude: lognitude,
    reason: reason
  }, {
    headers: {'Content-Type': 'application/json',"Authorization": "Bearer " + localStorage.getItem("token")},
  })
}

export const forgotPassEmail = (memberId) => {

  return axios.post(BACKEND_APP_URL + 'auth/forgot-password/'+ memberId, {
    
  }, {
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })

  
 
}

export const resetPassword = (memberId, password) => {
  return axios({
    url: BACKEND_APP_URL+"auth/reset-password",
    method: "POST",
    headers: {'Content-Type': 'application/json'},
    data: JSON.stringify({
      "memberId":memberId,
      "newPassword":password
      })
  });
}
export const changePassword = (memberId, password, newPassword) => {
  return axios({
    url: BACKEND_APP_URL+"auth/change-password/"+memberId,
    method: "PUT",
    headers: {'Content-Type': 'application/json'},
    data: JSON.stringify({
      "oldPassword":password,
      "newPassword":newPassword
      })
  });
}
export const verifyOtp = (memberId, otp) => {
  return axios({
    url: BACKEND_APP_URL+"auth/forgot-password/verify",
    method: "POST",
    headers: {'Content-Type': 'application/json'},
    data: JSON.stringify({
      "memberId":memberId,
      "otp":otp
      })
  });
}

export const markAsReadNotify = (id, status) => {
  return axios({
    url: BACKEND_APP_URL+"notification/"+id+"/"+status,
    method: "PUT",
    headers: {'Content-Type': 'application/json',"Authorization": "Bearer " + localStorage.getItem("token")},
    data: JSON.stringify({
      })
  });
}
export const getMemberById = (id) => {

  return fetch(BACKEND_APP_URL + "members/" + id, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getNotifications = () => {

  return fetch(BACKEND_APP_URL + "notification" , {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllAdmins = () => {

  return fetch(BACKEND_APP_URL + "members/admins", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllActiveMembers = () => {

  return fetch(BACKEND_APP_URL + "members", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllTeamMembers = () => {

  return fetch(BACKEND_APP_URL + "members/team-members/"+localStorage.getItem("userId"), {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllMembers = (text) => {

  return fetch(BACKEND_APP_URL + "members/search?searchTerm=" + text, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const addNewMember = (memberId, password, fname, lname, mobile, email, designation, role, address, experience, doj, dob, gender) => {

  return axios.post(BACKEND_APP_URL + 'members', {
    firstName: fname,
    lastName: lname,
    phoneNumber: mobile,
    password: password,
    emailId: email,
    designation: designation,
    address: address,
    yearsOfExperience: experience,
    dateOfJoining: doj,
    dob: dob,
    gender: gender,
    memberId: memberId,
    role: role,
    createdBy: localStorage.getItem("userId"),
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const editNewMember = (memberId, password, fname, lname, mobile, email, designation, role, address, experience, doj, dob, gender) => {

  return axios.put(BACKEND_APP_URL + 'members/' + memberId, {
    firstName: fname,
    lastName: lname,
    phoneNumber: mobile,
    password: password,
    emailId: email,
    designation: designation,
    address: address,
    yearsOfExperience: experience,
    dateOfJoining: doj,
    dob: dob,
    gender: gender,
    memberId: memberId,
    role: role,
    modifiedBy: localStorage.getItem("userId"),
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const deleteMemberById = (memberId) => {

  return axios.delete(BACKEND_APP_URL + 'admin/' + memberId,
    {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
      data: {

      }
    });
}



export const getTeamById = (id) => {

  return fetch(BACKEND_APP_URL + "teams/" + id, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllTeamsForAdmin = () => {
  return fetch(BACKEND_APP_URL + "teams/admin", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllTeams = (text) => {

  return fetch(BACKEND_APP_URL + "teams?search=" + text, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const addNewTeam = (teamName, teamLeadId, teamMembers) => {

  return axios.post(BACKEND_APP_URL + 'teams', {
    teamLeadId: teamLeadId,
    teamName: teamName,
    teamMembers: teamMembers,
    createdBy: localStorage.getItem("userId"),
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const editNewTeam = (teamId, teamName, teamLeadId, teamMembers) => {

  return axios.put(BACKEND_APP_URL + 'teams/' + teamId, {
    teamLeadId: teamLeadId,
    teamName: teamName,
    teamMembers: teamMembers,
    modifiedBy: localStorage.getItem("userId"),
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const deleteTeamById = (teamId) => {

  return axios.delete(BACKEND_APP_URL + 'teams/' + teamId,
    {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
      data: {

      }
    });
}


export const getTrainingsById = (id) => {

  return fetch(BACKEND_APP_URL + "training/"+id, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllTasks = (title, project, tag, memberName, startDate, endDate, completedDate, assignedDate, priority) => {
 

  let url = "tasks/search/first?";
  
  
  if(title){
    url = url+"title="+title+"&";
  }
  if(project){
    url = url+"projectName="+project+"&";
  }
  if(tag){
    url = url+"tag="+tag+"&";
  }
  if(memberName){
    url = url+"searchMemberId="+memberName+"&";
  }
  if(startDate){
    url = url+"startDate="+startDate+"&";
  }
  if(endDate){
    url = url+"endDate="+endDate+"&";
  }
  if(completedDate){
    url = url+"completedDate="+completedDate+"&";
  }
  if(assignedDate){
    url = url+"assignedDate="+assignedDate+"&";
  }
  if(priority){
    url = url+"taskPriority="+priority+"&";
  }
  
  return fetch(BACKEND_APP_URL + url, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getTasksByStatus = (status, pageNo, pageSize, title, project, tag, memberName, startDate, endDate, completedDate, assignedDate, priority) => {
  
  let url = "tasks/search/page?";
  
  if(status){
    url = url+"status="+status+"&";
  }

  if(pageNo){
    url = url+"pageNumber="+pageNo+"&";
  }

  if(pageSize){
    url = url+"pageSize="+pageSize+"&";
  }
  
  if(title){
    url = url+"title="+title+"&";
  }
  if(project){
    url = url+"projectName="+project+"&";
  }
  if(tag){
    url = url+"tag="+tag+"&";
  }
  if(memberName){
    url = url+"memberName="+memberName+"&";
  }
  if(startDate){
    url = url+"startDate="+startDate+"&";
  }
  if(endDate){
    url = url+"endDate="+endDate+"&";
  }
  if(completedDate){
    url = url+"completedDate="+completedDate+"&";
  }
  if(assignedDate){
    url = url+"assignedDate="+assignedDate+"&";
  }
  if(priority){
    url = url+"taskPriority="+priority+"&";
  }
  
  return fetch(BACKEND_APP_URL + url, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllAttendance = (startDate, endDate) => {
  let url = BACKEND_APP_URL + "attendance";
  if(startDate && endDate){
    url = BACKEND_APP_URL + "attendance?startDate="+startDate+"&endDate="+endDate;
  }
  return fetch(url, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}
export const getAllTasksForTableFull = (text) => {

  return fetch(BACKEND_APP_URL + "tasks/table/search", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}
export const getAllTasksForTableView = (pageNo, pageSize, text) => {


    let url = "tasks/search/table/first?";
    
  
    if(pageNo){
      url = url+"pageNumber="+pageNo+"&";
    }
  
    if(pageSize){
      url = url+"pageSize="+pageSize+"&";
    }
    
    if(text){
      url = url+"title="+text+"&";
    }
    
    
    return fetch(BACKEND_APP_URL + url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + localStorage.getItem("token")
      },
    })
      .catch(error => {
        console.log("Error while getAllTeams");
      })
}

// export const getAllTasksFilter = (title, project, tag, memberName, startDate, endDate, completedDate, assignedDate, priority) => {
//   let url = "tasks/search?";
//   if(title){
//     url = url+"title="+title+"&";
//   }
//   if(project){
//     url = url+"projectName="+project+"&";
//   }
//   if(tag){
//     url = url+"tag="+tag+"&";
//   }
//   if(memberName){
//     url = url+"memberName="+memberName+"&";
//   }
//   if(startDate){
//     url = url+"startDate="+startDate+"&";
//   }
//   if(endDate){
//     url = url+"endDate="+endDate+"&";
//   }
//   if(completedDate){
//     url = url+"completedDate="+completedDate+"&";
//   }
//   if(assignedDate){
//     url = url+"assignedDate="+assignedDate+"&";
//   }
//   if(priority){
//     url = url+"taskPriority="+priority+"&";
//   }
  
//   return fetch(BACKEND_APP_URL + url, {
//     method: "GET",
//     headers: {
//       'Content-Type': 'application/json',
//       "Authorization": "Bearer " + localStorage.getItem("token")
//     },
//   })
//     .catch(error => {
//       console.log("Error while getAllTeams");
//     })
// }

export const changeTaskStatus = (taskId, status, message, type) => {

  return axios.put(BACKEND_APP_URL + 'tasks/status/' + taskId, {
    status: status,
    type: type,
    message: message,
    memberId: localStorage.getItem("userId"),
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}
export const changeTaskStatusWithFeedback = (taskId, status, message) => {

  return axios.put(BACKEND_APP_URL + 'tasks/status/' + taskId, {
    status: status,
    qcFeedback: message,
    memberId: localStorage.getItem("userId"),
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const getProjectById = (projectId) => {
  return fetch(BACKEND_APP_URL + "project/" + projectId, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllProjectsUnderAdmin = () => {
  return fetch(BACKEND_APP_URL + "project/admin", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllProject = (text) => {

  return fetch(BACKEND_APP_URL + "project?search=" + text, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllTraining = (projectId, memberId, sdate, edate) => {

  let param = "?"
  if(sdate)
    param = param+"startDate="+sdate+"&";
  if(edate)
    param = param+"endDate="+edate+"&";
  if(memberId)
    param = param+"trainerId="+memberId+"&";
  if(projectId)
    param = param+"projectId="+projectId+"&";

  if (param.endsWith("&")) {
    param = param.slice(0, -1);
  }
    return fetch(BACKEND_APP_URL + "training/search"+param, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllTagByProjectId = (projectId) => {

  return fetch(BACKEND_APP_URL + "project/tag/" + projectId, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllMembersByProjectIdAndTeams = (projectId) => {

  return axios.get(BACKEND_APP_URL + 'project/members/'+projectId, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const updateProject = (projectId, projectName, description, teams, tags) => {

  return axios.put(BACKEND_APP_URL + 'project/' + projectId, {
    projectName: projectName,
    description: description,
    teams: teams,
    tags: tags,
    modifiedBy: localStorage.getItem("userId"),
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}
export const addNewProject = (projectName, description, teams, tags) => {

  return axios.post(BACKEND_APP_URL + 'project', {
    projectName: projectName,
    description: description,
    teams: teams,
    tags: tags,
    createdBy: localStorage.getItem("userId"),
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const addAttachmnts = (formData) => {
  return axios({
    url: BACKEND_APP_URL+"tasks/attachment",
    method: "POST",
    headers: {'Content-Type': 'multipart/form-data',"Authorization": "Bearer " + localStorage.getItem("token")},
    data: formData
  });
}

export const editNewProject = (projectId, teamName, teamLeadId, teamMembers) => {

  return axios.put(BACKEND_APP_URL + 'project/' + projectId, {
    teamLeadId: teamLeadId,
    teamName: teamName,
    teamMembers: teamMembers,
    modifiedBy: localStorage.getItem("userId"),
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const deleteProjectById = (projectId) => {

  return axios.delete(BACKEND_APP_URL + 'project/' + projectId,
    {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
      data: {

      }
    });
}
export const createTask = (title, shortDescription, description, priority, startDate, endDate, projectId, tags, assignedTo, complexity) => {

  return axios.post(BACKEND_APP_URL + 'tasks', {
    title: title,
    description: description,
    shortDescription: shortDescription,
    projectId: projectId,
    startDate: startDate,
    endDate: endDate,
    priority: priority,
    assignedTo: assignedTo,
    assignedBy: assignedTo.length > 0 ? localStorage.getItem("userId") : null,
    projectId: projectId,
    tags: tags,
    complexity: complexity,
    createdBy: localStorage.getItem("userId"),
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}   


export const deleteTask = (taskId) => {

  return axios.delete(BACKEND_APP_URL + 'tasks/'+taskId,
    {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
      data: {

      }
    });
}

export const copyTask = (taskId) => {

  return axios.post(BACKEND_APP_URL + 'tasks/duplicate/'+taskId, {
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}   

export const patchUpdateTask = (taskId, data) => {

  return axios.put(BACKEND_APP_URL + 'tasks/'+taskId, data
  // {
  //   title: title,
  //   description: description,
  //   shortDescription: shortDescription,
  //   projectId: projectId,
  //   startDate: startDate,
  //   endDate: endDate,
  //   priority: priority,
  //   assignedTo: assignedTo,
  //   assignedBy: assignedTo.length > 0 ? localStorage.getItem("userId") : null,
  //   projectId: projectId,
  //   assignedTeams: teams,
  //   modifiedBy: localStorage.getItem("userId"),
  // }
  
  , {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const getParticularTaskById = (taskId) => {
  return fetch(BACKEND_APP_URL + "tasks/" + taskId, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getPendingRatingTaskList = () => {
  return fetch(BACKEND_APP_URL + "tasks/pending-ratings", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const addComments = (taskId, message) => {

  return axios.post(BACKEND_APP_URL + 'tasks/comment',
  {
    taskId: taskId,
    message: message,
    createdBy: localStorage.getItem("userId"),
  }
  
  , {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const deleteAttachmentById = (attachId, taskId) => {

  return axios.delete(BACKEND_APP_URL + 'tasks/attachment/'+attachId,
    {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
      data: {

      }
    });
}

export const deleteCommentById = (commentId, taskId) => {

  return axios.delete(BACKEND_APP_URL + 'tasks/comment/'+commentId+"/"+taskId,
    {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
      data: {

      }
    });
}

export const addRating = (taskId, memberId, rating, feedback) => {

  return axios.post(BACKEND_APP_URL + 'tasks/ratings',
  {
    taskId: taskId,
    memberId: memberId,
    rating: rating,
    feedback: feedback,
    createdBy: localStorage.getItem("userId"),
  }
  
  , {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}
export const getIndividualPerformance = (teamId, memberId, startDate, endDate) => {
  

  let param = "?"
  if(teamId)
    param = param+"searchTeamId="+teamId+"&";
  if(memberId)
    param = param+"searchMemberId="+memberId+"&";
  if(startDate)
    param = param+"requestStartDate="+startDate+"&";
  if(endDate)
    param = param+"requestEndDate="+endDate+"&";

  if (param.endsWith("&")) {
    param = param.slice(0, -1);
  }
  let url = BACKEND_APP_URL + "report/members-task-time-taken"+param;
  
  console.log(url);
  return fetch(url, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}
export const getOverallTaskCount = () => {
  return fetch(BACKEND_APP_URL + "report/overall-task", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllMemberwiseData = () => {
  return fetch(BACKEND_APP_URL + "report/memberwise", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllMemberTaskTimeData = () => {
  return fetch(BACKEND_APP_URL + "report/task-time-taken", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getOverallAttendance = () => {
  return fetch(BACKEND_APP_URL + "attendance/range", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllTeamwiseData = () => {
  return fetch(BACKEND_APP_URL + "report/teamwise", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const deleteCompOff = (id) => {

  return axios.delete(BACKEND_APP_URL + 'leave/compensation/'+id,
    {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
      data: {

      }
    });
}

export const getAllCompOffLeave = (member, sdate, edate) => {
  let url = "";
  if(member){
    url = "leave/compensation?requestMember="+member+"&startDate="+sdate+"&endDate="+edate;
  } else {
    url = "leave/compensation?startDate="+sdate+"&endDate="+edate;
  }
  return fetch(BACKEND_APP_URL + url, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllCompOffs");
    })
}

export const getAllLeaveType = () => {
  return fetch(BACKEND_APP_URL + "leave/type", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const addNewHolidayByAdmin = (title, dates, leaveTypeId) => {

  return axios.post(BACKEND_APP_URL + 'holidaycalendar',
  {
    dates: dates,
    reason: title,
    leaveTypeId: leaveTypeId,
  }
  
  , {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const getAllLeaves = (yearMonth) => {
  let url = BACKEND_APP_URL + "leave/details";
  if(yearMonth){
    url =url + "?yearMonth="+yearMonth;
  }
  return fetch(url, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log(error);
      console.log("Error while getAllTeams");
    })
}

export const getAvailableLeaves = () => {
  let url = BACKEND_APP_URL + "leave/details/all/members";
  return fetch(url, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log(error);
      console.log("Error while getAllTeams");
    })
}

export const getAllHoliday = () => {
  return fetch(BACKEND_APP_URL + "holidaycalendar", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}
export const getHoliday = (id, type) => {
  let url = "";
  if(type==="HOLIDAY") {
    url = BACKEND_APP_URL + "holidaycalendar/"+id;
  } else {
    url = BACKEND_APP_URL + "leave/request/"+id;
  }
  return fetch(url, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getLeaveRequest = (id) => {
  return fetch(BACKEND_APP_URL + "leave/request/"+id, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const deleteHolidayByAdmin = (id) => {

  return axios.delete(BACKEND_APP_URL + 'holidaycalendar/'+id,
    {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
      data: {

      }
    });
}

export const submitCompOff = (leaveDayType, reason, leaveDates, members) => {
  
  return axios.post(BACKEND_APP_URL + 'leave/compensation', {
    leaveDayType:leaveDayType,
    leaveDates:leaveDates,
    reason:reason,
    listOfMembers: members
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const submitLeaveRequest = (leaveTypeId,leaveDayType, leaveHalfDayType,  reason, leaveDates, startTime, endTime) => {
  
  return axios.post(BACKEND_APP_URL + 'leave/request', {
    leaveTypeId: leaveTypeId,
    leaveDayType:leaveDayType,
    leaveHalfDayType:leaveHalfDayType,
    leaveDates:leaveDates,
    reason:reason,
    startTime:startTime,
    endTime: endTime
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const updateLeaveRequest = (requestId, leaveTypeId,leaveDayType, leaveHalfDayType,  reason, leaveDates, startTime, endTime) => {
  
  return axios.put(BACKEND_APP_URL + 'leave/request/'+requestId, {
    leaveTypeId: leaveTypeId,
    leaveDayType:leaveDayType,
    leaveHalfDayType:leaveHalfDayType,
    leaveDates:leaveDates,
    reason:reason,
    startTime:startTime,
    endTime: endTime
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const deleteLeaveRequestById = (requestId) => {
  
  return axios.delete(BACKEND_APP_URL + 'leave/request/'+requestId, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}


export const getMyLeaveRequestsWithSearch = (sdate, edate, selectedLeaveTypeId, reqStatus) => {
  let param = "?"
  if(sdate)
    param = param+"requestedStartDate="+sdate+"&";
  if(edate)
    param = param+"requestedEndDate="+edate+"&";
  if(selectedLeaveTypeId)
    param = param+"leaveTypeId="+selectedLeaveTypeId+"&";
  if(reqStatus)
    param = param+"status="+reqStatus+"&";

  if (param.endsWith("&")) {
    param = param.slice(0, -1);
  }

  return fetch(BACKEND_APP_URL + "leave/request/my-requests"+param, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getTeamLeaveRequestsWithSearch = (userId, sdate, edate, selectedLeaveTypeId) => {
  let param = "?"
  if(sdate)
    param = param+"requestedStartDate="+sdate+"&";
  if(edate)
    param = param+"requestedEndDate="+edate+"&";
  if(selectedLeaveTypeId)
    param = param+"leaveTypeId="+selectedLeaveTypeId+"&";
  if(userId)
    param = param+"searchMember="+userId+"&";

  if (param.endsWith("&")) {
    param = param.slice(0, -1);
  }

  return fetch(BACKEND_APP_URL + "leave/team/request"+param, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}


export const getMyLeaveApprovals = () => {
  return fetch(BACKEND_APP_URL + "leave/request/approvals", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getMyLeaveRequests = () => {
  return fetch(BACKEND_APP_URL + "leave/request/my-requests", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getTeamLeaveRequests = () => {
  return fetch(BACKEND_APP_URL + "leave/team/request", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}


export const getLeaveHistory = () => {
  return fetch(BACKEND_APP_URL + "leave/history", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}



export const getTeamRequestsWithSearch = (sdate, edate) => {
  let param = "?"
  if(sdate)
    param = param+"startDate="+sdate+"&";
  if(edate)
    param = param+"endDate="+edate+"&";

  if (param.endsWith("&")) {
    param = param.slice(0, -1);
  }
  return fetch(BACKEND_APP_URL + "leave/team/history"+param, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getTeamLeaveHistory = () => {
  return fetch(BACKEND_APP_URL + "leave/team/history", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAllMyLeaves = () => {
  return fetch(BACKEND_APP_URL + "leave/request/my-leaves", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const getAvailableLeaveHistory = () => {
  return fetch(BACKEND_APP_URL + "leave/calculation", {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer " + localStorage.getItem("token")
    },
  })
    .catch(error => {
      console.log("Error while getAllTeams");
    })
}

export const submitLeaveRequestAction = (id, status, reason) => {

  return axios.post(BACKEND_APP_URL + 'leave/request/approve', {
    reason: reason,
    status: status,
    leaveRequestApprovalId: id
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const addNewTraining = (topic, description, startDate, endDate, selectedProject, selectedOption, arr) => {

  return axios.post(BACKEND_APP_URL + 'training', {
    topic: topic,
    projectId: selectedProject,
    description: description,
    memberIds: arr,
    startDate: startDate,
    endDate: endDate,
    trainerId: selectedOption
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const editNewTraining = (id, topic, description,status, startDate, endDate, selectedProject, selectedOption, arr) => {

  return axios.put(BACKEND_APP_URL + 'training/'+id, {
    topic: topic,
    projectId: selectedProject,
    description: description,
    status: status,
    memberIds: arr,
    startDate: startDate,
    endDate: endDate,
    trainerId: selectedOption
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const editAttendance = (requestMemberId, date, logOutTime,reason) => {

  return axios.put(BACKEND_APP_URL + 'attendance/logout-time', {
    requestMemberId: requestMemberId,
    date: date,
    logOutTime: logOutTime,
    reason: reason,
  }, {
    headers: {
      'Accept': '*/*',
      "Authorization": "Bearer " + localStorage.getItem("token"),
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*"
    }
  })
}

export const deleteTrainingById = (id) => {

  return axios.delete(BACKEND_APP_URL + 'training/' + id,
    {
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
      data: {

      }
    });
}