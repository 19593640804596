import { Button, Card, Chip, CircularProgress, Divider } from '@mui/joy';
import { Dialog, DialogTitle, Grid, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import * as React from 'react';
import { MdDateRange } from 'react-icons/md';
import { getAllLeaveType, getLeaveHistory, logout } from '../../../service/service-call';
import LeaveRequestView from './LeaveRequestView';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default function LeaveHistory() {
  const [searchText, setSearchText] = React.useState("");
  const [allLeaveTypes, setAllLeaveTypes] = React.useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = React.useState("");
  const [leaveHistory, setLeaveHistory] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(-1);
  const [selectedId, setSelectedId] = React.useState("");
  const [reqStatus, setReqStatus] = React.useState("");
  const [isAddTeamOpen, setIsAddTeamOpen] = React.useState(false);
  const [isEditLeaveRequestOpen, setIsEditLeaveRequestOpen] = React.useState(false);
  const [sdate, setSDate] = React.useState(null);
  const [edate, setEDate] = React.useState(null);
  const handleExpandClick = (i, courseId) => {
    setExpanded(expanded === i ? -1 : i);
    // setSelectedCourseId(courseId);
    // getCourseRating(courseId);
  };
  React.useEffect(() => {
    getAllLeaveTypes();
    getAllLeaveRequests(searchText);
  }, []);


  function getAllLeaveTypes() {
    getAllLeaveType("").then(resp => {
      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        let arr = data.filter(function (item) {
          return item.name !== "GOVERNMENT_LEAVE"
        })
        setAllLeaveTypes(arr);

      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  function getAllLeaveRequests() {
    setIsLoading(true);
    getLeaveHistory().then(resp => {
      setIsLoading(false);
      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        setLeaveHistory(data);

      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }

  const handleEDateChange = (e) => {
    setEDate(moment(e).format("YYYY-MM-DD"))
  }

  const handleSDateChange = (e) => {
    setSDate(moment(e).format("YYYY-MM-DD"))
  }

  const handleReqStatusChange = (e) => {
    setReqStatus(e.target.value);
  }

  function toggleAddTeamModal() {
    setIsAddTeamOpen(!isAddTeamOpen);
    if (isAddTeamOpen === true) {
      getAllLeaveRequests(searchText);
    }
  }

  function toggleViewLeaveRequestModal() {
    setIsEditLeaveRequestOpen(!isEditLeaveRequestOpen);
    if (isEditLeaveRequestOpen === true) {
      getAllLeaveRequests(searchText);
    }
  }

  function addNewLeaveRequests() {
    toggleAddTeamModal();
  }

  function clickParticularLeaveRequest(id) {
    setSelectedId(id);
    toggleViewLeaveRequestModal();
  }


  const BootstrapViewDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      minWidth: '500px !important',
      height: 'auto'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    }
  }));
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      minWidth: '600px !important',
      height: 'auto'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{
        alignItems: "center",
        justifyContent: "center",
        alignContent: "space-between"
      }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
          >
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  return (
    <>
      {
        isLoading ? (
          <div
            style={{
              // do your styles depending on your needs.
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {
              <CircularProgress />
            }
          </div>
        ) : ""
      }
      <Grid container direction="row" rowSpacing={2} spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ marginTop: '5px' }}
        style={{
          minHeight: '440px', maxHeight: '440px',
          overflowY: 'auto'
        }}>

        {
          leaveHistory.length > 0 && leaveHistory.map((each, index) => (
            <Grid item md={12} key={index} margin={"20px"}>

              <Divider orientation='horizontal' sx={{ fontWeight: '700' }}>{each.yearMonth}</Divider>
              <br></br>
              {
                each.leaveHistories && each.leaveHistories.length === 0 ?
                  (
                    <p style={{ color: 'red', textAlign: 'center', padding: '20px' }}>No records found!</p>
                  ) : ""
              }
              {
                each.leaveHistories && each.leaveHistories.map((e, index) => (
                  <>
                    <Card sx={{ backgroundColor: 'white', marginBottom: '10px' }} >
                      <Stack direction={"row"} justifyContent={"space-between"}>
                        <div> 
                          {
                            e.leaveType &&  e.leaveType.name==="PERMISSION" ? 
                            (
<Chip
                          size="md"
                          variant="solid"
                          sx={{ fontSize: '0.8rem', borderRadius: '5px', backgroundColor: '#262673', color: 'white' }}
                          startDecorator={<MdDateRange />}>{moment(e.startDate).format("YYYY-MM-DD")}  {moment(e.startTime, "hh:mm:ss").format("hh:mm A")}-{moment(e.endTime, "hh:mm:ss").format("hh:mm A")}</Chip>
                            ): (
<Chip
                          size="md"
                          variant="solid"
                          sx={{ fontSize: '0.8rem', borderRadius: '5px', backgroundColor: '#262673', color: 'white' }}
                          startDecorator={<MdDateRange />}>{moment(e.startDate).format("YYYY-MM-DD")} - {moment(e.endDate).format("YYYY-MM-DD")}</Chip>
                            )
                          }
                          
                          &nbsp; <Chip variant='outlined' color='danger' size="md"
                            sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                          >{e.leaveType.name.toLowerCase().replace(/_/g, ' ')}</Chip>


                        {
                          e.leaveDayType ?
                            (
                              <>
                                &nbsp; <Chip variant='outlined' color='danger' size="md"
                                  sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                                >{e.leaveDayType.toLowerCase().replace(/_/g, ' ')}</Chip>
                              </>
                            ) : ""
                        }

                        {
                          e.leaveHalfDayType ?
                            (
                              <>
                                &nbsp; <Chip variant='outlined' color='danger' size="md"
                                  sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                                >{e.leaveHalfDayType.toLowerCase().replace(/_/g, ' ')}</Chip>
                              </>
                            ) : ""
                        }



                        </div>
                        <div>
                          <Chip variant='solid' color='danger' size="md"
                            sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                          >{e.status}</Chip>&nbsp;&nbsp;&nbsp;&nbsp;
                          <Button size="sm" sx={{ fontSize: '0.8rem' }} onClick={() => clickParticularLeaveRequest(e.id)}>VIEW DETAILS</Button>
                        </div>
                      </Stack>
                    </Card>
                  </>
                ))
              }

            </Grid>
          ))
        }
      </Grid>

      <BootstrapDialog
        onClose={toggleViewLeaveRequestModal}
        aria-labelledby="customized-dialog-title"
        open={isEditLeaveRequestOpen}
      >
        <LeaveRequestView toggleViewLeaveRequest={toggleViewLeaveRequestModal} id={selectedId} />

      </BootstrapDialog>

    </>
  )
}