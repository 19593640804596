import { Avatar, ListItemDecorator } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import { Chip, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import * as React from 'react';
import { HiUserGroup } from "react-icons/hi2";
import { toast } from 'react-toastify';
import { addNewTeam, addNewTraining, editNewTraining, getAllActiveMembers, getAllProject, getAllProjectsUnderAdmin, getTrainingsById, logout, stringAvatar } from '../../service/service-call';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
//import { addNewTeam } from '../util/apiCalls';

export default function EditTrainings({ editTrainingsModal, id }) {
    const [allMembers, setAllMembers] = React.useState([]);
    const [topic, setTopic] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [teamId, setTeamId] = React.useState('');
    const [status, setStatus] = React.useState("");
    const [allProjects, setAllProjects] = React.useState([]);
    const [selectedProject, setSelectedProject] = React.useState("");
    const [selectedOption, setSelectedOption] = React.useState("");
    const [selectedTeamMembers, setSelectedTeamMembers] = React.useState([]);
    const [selectedTeamMemberIds, setSelectedTeamMemberIds] = React.useState([]);
    const [sdate, setSDate] = React.useState(null);
    const [edate, setEDate] = React.useState(null);

    React.useEffect(() => {
        getTrainingById(id);
        getAllMemberForTeam();
        getAllProjects();
    }, []);

    function getTrainingById(id){
        getTrainingsById(id).then(resp => {
            
            if(resp.status === 401){
                logout();
            }
            resp.json().then(data => {
                
                //setAllProjects(data);
                setTopic(data.topic);
                setDescription(data.description);
                setSelectedProject(data.projectId.id);
                setSDate(moment(data.startDate).format("YYYY-MM-DD hh:mm A"));
                setEDate(moment(data.endDate).format("YYYY-MM-DD hh:mm A"));
                setSelectedTeamMemberIds(data.trainer.id);
                setSelectedOption(data.trainer.id);
                setStatus(data.status);
                let arr= [];
                data.members.map((option) => arr.push(option.firstName + " " + option.lastName + " (" + option.id + ")"));
                setSelectedTeamMembers(arr);
            });
        }).catch(error => {
            console.log("login user err " + error);
        });
    }

    function getAllProjects() {
        getAllProject("").then(resp => {
            
            if(resp.status === 401){
                logout();
            }
            resp.json().then(data => {
                
                setAllProjects(data);

            });
        }).catch(error => {
            console.log("login user err " + error);
        });
    }

    function getAllMemberForTeam() {
        getAllActiveMembers().then(resp => {
            
            if(resp.status === 401){
                logout();
            }
            resp.json().then(data => {
                
                setAllMembers(data);

            });
        }).catch(error => {
            console.log("login user err " + error);
        });
    }

    const handleTopicChange = (e) => {
        setTopic(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleEDateChange = (e) => {
        setEDate(moment(e).format("YYYY-MM-DD hh:mm A"))
      }
    
      const handleSDateChange = (e) => {
        setSDate(moment(e).format("YYYY-MM-DD hh:mm A"))
      }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    function addNewTrainingClick() {
        let arr = [selectedTeamMemberIds];
        selectedTeamMembers.map(e => {
            let idCode = e.split("(");
            let val = idCode[1].split(")");
            if (!arr.includes(val[0].trim()))
                arr.push(val[0].trim());
        })
        
        if(topic === "" || topic === undefined || 
            description==="" || description ===undefined ||
            sdate==="" || sdate ===undefined || sdate === null ||
            edate==="" || edate ===undefined || edate === null ||
            selectedProject==="" || selectedProject ===undefined || selectedProject === null ||
            arr==="" || arr ===undefined || arr.length ===0  || selectedTeamMembers.length === 0 ||
            selectedOption === "" || selectedOption === undefined){
            toast.error('Fields cannot be blank');
            return false;
        }

        if ((Date.parse(edate) <= Date.parse(sdate))) {
            toast.error("End time should be greater than start time");
            return false;
        } else {
            editNewTraining(id, topic, description, status, sdate, edate, selectedProject, selectedOption, arr).then(resp => {
                toast.success('Training updated successfully');
                editTrainingsModal();
            }).catch(error => {
                if(error && error.response && error.response.data && error.response.data.message){
                    toast.error(error.response.data.message);
                  } else if(error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0){
                    toast.error(error.response.data.errors[0]);
                  } else {
                    toast.error("Internal server error, contact support team");
                  }

            })
        }
    }

    return (
        <React.Fragment>
            <DialogContent>
                <Grid container >
                    <Box sx={{ flexGrow: 1, padding: '10px' }}>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                                textAlign: 'center',
                                height: 50,
                                pl: 2,
                                bgcolor: 'background.default',
                            }}
                        >
                            <div style={{ textAlign: 'center', display: 'inline-flex' }} ><HiUserGroup size={"30px"} color='#262673' /></div>
                            <Typography style={{ textAlign: 'center', color: '#262673' }} class="font-bold">{"EDIT TRAINING"}</Typography>
                        </Paper><br></br>
                        <Divider /><br></br>


                        <FormControl required={true} fullWidth variant="standard" style={{ textAlign: 'center' }}>
                            <TextField
                                id="standard-adornment-topic"
                                label="Topic"
                                size="small"
                                type={'text'}
                                value={topic}
                                onChange={handleTopicChange}
                            />
                        </FormControl>
                        <br></br><br></br>
                        <FormControl required={true} fullWidth variant="standard" style={{ textAlign: 'center' }}>
                            <TextField
                                multiline
                                rows={3}
                                id="standard-adornment-topic"
                                label="Description"
                                size="small"
                                type={'text'}
                                value={description}
                                onChange={handleDescriptionChange}
                            />
                        </FormControl>
                        <br></br><br></br>
                        <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel size="small" id="demo-simple-select-standard-label">Project</InputLabel>

                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                variant="outlined"
                                value={selectedProject}
                                size="small"
                                label="Projects"
                                onChange={(event) => {
                                    setSelectedProject(event.target.value);
                                }}
                            >
                                {allProjects.map((item) => (
                                    <MenuItem value={item.id}   >
                                       
                                        {item.projectName }</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <br></br><br></br>

                        <FormControl fullWidth>
    <InputLabel id="demo-simple-select-label">Status</InputLabel>    
            <Select
                variant="outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Month"
                size='small'
                onChange={(e) => setStatus(e.target.value)}
            >
                <MenuItem value={"SCHEDULED"}>SCHEDULED</MenuItem>
                <MenuItem value={"INPROGRESS"}>INPROGRESS</MenuItem>
                <MenuItem value={"COMPLETED"}>COMPLETED</MenuItem>
                <MenuItem value={"CANCELLED"}>CANCELLED</MenuItem>
                <MenuItem value={"HOLD"}>HOLD</MenuItem>
            </Select>
                    </FormControl>
                    <br></br><br></br>
                        <LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
                <DesktopDateTimePicker
                    
                  label="Start date"
                  fullWidth
                  value={moment(sdate).format("YYYY-MM-DD hh:mm A")}
                  disablePast="true"
                  onChange={handleSDateChange}
                  minutesStep={30}
                  slotProps={{ field: { clearable: true } }}
                  renderInput={(params) => <TextField {...params} 
                                
                  size='small'
                  //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                  />}
                /></LocalizationProvider>&nbsp;&nbsp;&nbsp;

<LocalizationProvider fullWidth dateAdapter={AdapterDateFns} style={{ maxWidth: '20% !important' }}>
                <DesktopDateTimePicker
                    
                  label="End date"
                  fullWidth
                  value={moment(edate).format("YYYY-MM-DD hh:mm A")}
                  disablePast="true"
                  onChange={handleEDateChange}
                  minDateTime = {sdate}
                  minutesStep={30}
                  slotProps={{ field: { clearable: true } }}
                  renderInput={(params) => <TextField {...params} 
                                
                  size='small'
                  //helperText={"If it is fullday leave, then you can choose the time 9:00AM to 6:30PM"}
                  />}
                /></LocalizationProvider>
                <br></br><br></br>
                        <FormControl fullWidth variant="outlined" size='small'>
                            <InputLabel id="demo-simple-select-standard-label">Trainer</InputLabel>

                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                variant="outlined"
                                value={selectedOption}
                                size="small"
                                label="Trainer"
                                onChange={(event) => {
                                    setSelectedTeamMemberIds(event.target.value)
                                    setSelectedOption(event.target.value)
                                }}
                            >
                                {allMembers.map((item) => (
                                    <MenuItem value={item.id}   >
                                        <ListItemDecorator>
                                            <Avatar color='neutral' size="sm" {...stringAvatar(item.firstName + " " + item.lastName)} />
                                        </ListItemDecorator>&nbsp;
                                        {item.firstName + " " + item.lastName +" ("+item.id+")"}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <br></br><br></br>

                        <Autocomplete
                            multiple
                            id="tags-filled"
                            fullWidth
                            size='small'
                            options={allMembers.map((option) => option.firstName + " " + option.lastName + " (" + option.id + ")")}
                            value={selectedTeamMembers}
                            onChange={(event, newValue) => {
                                setSelectedTeamMembers(
                                    newValue);
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip avatar={<Avatar size="lg" color='danger' {...stringAvatar(option)} />} variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant="outlined"
                                    label="Add Participants"
                                    placeholder="Add Participant"
                                />
                            )}
                        />


                    </Box>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={addNewTrainingClick}>&nbsp;SUBMIT</Button>
            </DialogActions>
        </React.Fragment>
    );
}