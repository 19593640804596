import React from 'react'
import Home from '../Components/Dashboard/dashboard'

export default function Dashboard() {
  return (
    <>
    
       <Home/>
      
      </>
  )
}
