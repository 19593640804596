import { Avatar, Button, Card, Chip, Grid, Stack, Typography } from '@mui/joy';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { DialogActions, DialogContent } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { FaUserClock, FaUserTie } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';
import { IoIosCreate } from 'react-icons/io';
import { MdDateRange } from 'react-icons/md';
import { getLeaveRequest, logout, stringAvatar } from '../../../service/service-call';


export default function LeaveRequestView({ toggleViewLeaveRequest, id }) {
  const [data, setData] = React.useState("");
  React.useEffect(() => {
    getLeaveRequest(id).then(resp => {
      if (resp.status === 401) {
        logout();
      }
      resp.json().then(data => {
        setData(data);

      });
    }).catch(error => {
      console.log("login user err " + error);
    });
  }, []);
  let timelineDataArray = {

    REQUESTED: {
      icon: <IoIosCreate size={"20px"} />,
    },
    REJECTED: {
      icon: <ImCancelCircle size={"20px"} />,
    },
    APPROVED: {
      icon: <BsCheckCircleFill size={"20px"} />,
    },
    PENDING_ADMIN: {
      icon: <FaUserClock size={"20px"} />,
    },
    PENDING_SUPER_ADMIN: {
      icon: <FaUserTie size={"20px"} />,
    }
  };

  return (
    <>

      <DialogContent>
        {data ? (
          <>
            <Grid container>
              <Grid item md={6}>
                <Chip
                  size="md"
                  variant="solid"
                  sx={{ fontSize: '0.8rem', borderRadius: '5px', backgroundColor: '#262673', color: 'white' }}
                  startDecorator={<MdDateRange />}>{moment(data.startDate).format("YYYY-MM-DD")} - {moment(data.endDate).format("YYYY-MM-DD")}</Chip>
                &nbsp; <Chip variant='outlined' color='danger' size="md"
                  sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                >{data.leaveType.name.toLowerCase().replace(/_/g, ' ')}</Chip>

{
                          data.leaveDayType ?
                            (
                              <>
                                &nbsp; <Chip variant='outlined' color='danger' size="md"
                                  sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                                >{data.leaveDayType.toLowerCase().replace(/_/g, ' ')}</Chip>
                              </>
                            ) : ""
                        }

                        {
                          data.leaveHalfDayType ?
                            (
                              <>
                                &nbsp; <Chip variant='outlined' color='danger' size="md"
                                  sx={{ fontSize: '0.8rem', borderRadius: '5px' }}
                                >{data.leaveHalfDayType.toLowerCase().replace(/_/g, ' ')}</Chip>
                              </>
                            ) : ""
                        }



                <br></br>
                <Typography variant="plain" level='title-sm' >
                  REASON: {data.reason}

                </Typography>

              </Grid>
              <Grid item md={2}></Grid>
              <Grid item md={2} textAlign={"center"} alignSelf={"center"}>
                <div style={{ display: 'flex', flexWrap: 'wrap', float: "left" }}>
                  <Chip variant='solid' color='danger'>
                    {data.status}
                  </Chip>
                </div>
              </Grid>
            </Grid>

            <Timeline position="right">
              {
                data.leaveRequestApprovals && data.leaveRequestApprovals.length > 0 && data.leaveRequestApprovals.map((e, i) => (
                  <>
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="overline"
                        color="text.primary"
                      >{e.changedStatus}

                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot color='success'>
                          {timelineDataArray[e.changedStatus].icon}
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Card>
                          <Stack direction={"row"} spacing={1}>
                            <Avatar variant='solid' size='sm' {...stringAvatar(e.member ? (e.member.firstName + " " + e.member.lastName) : "U U")}></Avatar>

                            <Stack direction={"column"} style={{ width: '100%', marginTop: '3px' }} >
                              <Typography level={"title-md"}>{e.member ? (e.member.firstName + " " + e.member.lastName) : ""}
                              </Typography>
                            </Stack>
                          </Stack>
                          {
                            e.reason && e.reason !== "-" ?
                              <Typography level='title-sm'  sx={{ fontSize: '0.8rem' }} fontStyle={"italic"}>{"\"" + e.reason + "\""}</Typography> : ""
                          }
                          {
                            e.changedTime ?
                              <Typography level='body-sm'  sx={{ fontSize: '0.8rem' }}>
                                {moment(e.changedTime).format('llll')}
                              </Typography>
                              : ""
                          }
                        </Card>
                      </TimelineContent>
                    </TimelineItem>
                  </>
                ))
              }
            </Timeline>
          </>
        ) : ""
        }

      </DialogContent>
      <DialogActions>
        <Button variant='soft' onClick={toggleViewLeaveRequest}>&nbsp;CLOSE</Button>
      </DialogActions>
    </>
  );
}